import { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Flex,
  FormLabel,
  FormControl,
  Heading,
  Image,
  Input,
  InputRightElement,
  InputGroup,
  Stack,
  Text,
  HStack,
  Center,
  // Divider,
  Select,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  Textarea,
  Spinner,
  Progress,
  PinInput,
  PinInputField,
  useToast,
  VStack,
  Stepper,
  Step,
  StepIndicator,
  StepSeparator,
  StepNumber,
  StepIcon,
  StepStatus,
  // useSteps,
} from '@chakra-ui/react';
import { BsLock, BsFillShieldFill } from 'react-icons/bs';
import moment from 'moment';
import { CheckIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { getAuth, updateEmail } from 'firebase/auth';
import { updateDoc, doc } from 'firebase/firestore';
// import Iframe from 'react-iframe';
import { useTranslation } from 'react-i18next';

import {
  db,
  TWILIO_START_VERIFY,
  TWILIO_VERIFY_OTP,
  isDev,
  // BANKING_IFRAME,
  allProvinces,
  eligibleWarrantyProvinces,
} from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import CreditQuestionnaire from '../components/CreditQuestionnaire';
import checkCell from '../services/checkCell';
import generateLicenseSelfieCheckURL from '../services/getLicenseVerifyURL';
import getRandomUserPhoto from '../services/getRandomUserPhoto';
import sendEmailPasscode from '../services/sendEmailPasscode';
import sendEmailToAdmin from '../services/sendEmailToAdmin';
import stageTwoVerifyUser from '../services/stageTwoVerifyUser';
import checkMark from '../assets/animat-checkmark.gif';

function Verify() {
  const { t } = useTranslation('global');
  const dataContext = useContext(DataContext);
  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  // const params = useSearchParams();
  let [searchParams] = useSearchParams();
  const tab1Ref = useRef(null);
  const tab2Ref = useRef(null);
  const tab3Ref = useRef(null);
  const userRef = doc(db, 'users', auth.currentUser.uid);

  // const [loadingUserData, setLoadingUserData] = useState(true);
  const [tabIndex, setTabIndex] = useState();
  const steps = [
    { title: 'First', description: 'Contact Info' },
    { title: 'Second', description: 'Date & Time' },
    { title: 'Third', description: 'Select Rooms' },
  ];
  // const { activeStep } = useSteps({
  //   index: tabIndex,
  //   count: steps.length,
  // });

  // const [userData, setUserData] = useState({});
  const { userData } = dataContext;
  // const [defaultTab, setDefaultTab] = useState();
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [smsPINSent, setSmsPINSent] = useState(false);
  const [sendSmsPINText, setSendSmsPINText] = useState('');
  const [fetchingSmsPIN, setFetchingSmsPIN] = useState(false);
  // const [sendVoicePINText, setSendVoicePINText] = useState('');
  // const [fetchingVoicePIN, setFetchingVoicePIN] = useState(false);
  const [fetchingLicenseURL, setFetchingLicenseURL] = useState(false);
  // const [fetchingBankingIframe, setFetchingBankingIframe] = useState(false);
  // const [fetchingKBA, setFetchingKBA] = useState(false);
  // const [KBA, setKBA] = useState('');
  // const [showKBA, setShowKBA] = useState(false);
  // const [KBAError, setKBAError] = useState('');
  // const [KBAAnswers, setKBAAnswers] = useState('');

  const {
    firstName,
    lastName,
    email,
    cell,
    address,
    emailPasscode,
    emailIsVerified,
    emailIsVerifiedAt,
    phoneIsSmsVerified,
    phoneIsSmsVerifiedAt,
    // phoneIsVoiceVerified,
    // phoneIsVoiceVerifiedAt,
    licenseSelfieVerifiedURL,
    licenseSelfieVerified,
    licenseSelfieVerifiedAt,
    licenseSelfieError,
    licenseSelfieErrorComments,
    addressConfirmed,
    // bankAccountIframe,
    // bankAccountAdded,
    // bankAccountAddedAt,
    // bankAccountError,
    // bankAccountErrorComments,
    // bankSkipped,
    kbaPassed,
    kbaPassedAt,
    // kbaError,
    kbaErrorComments,
    stageOneVerified,
    // stageTwoVerified,
    stageTwoWarnings,
    stageTwoWarningsComments,
    uid,
  } = userData;

  const [formData, setFormData] = useState({
    cell,
    licenseSelfieErrorComments: licenseSelfieErrorComments || '',
    kbaErrorComments: kbaErrorComments || '',
    // bankAccountErrorComments: bankAccountErrorComments || '',
  });
  // console.log('user', dataContext.userData)

  useEffect(() => {
    const tab = Number(searchParams.get('tab'));
    if (Number(tab)) {
      // setDefaultTab(Number(tab));
      setTabIndex(Number(tab));
    }
  }, [searchParams]);

  //   useEffect(() => {
  //     // console.log('dataContext.userData', dataContext.userData)
  //     // subscribe to some real-time db changes to re-render page
  //     // should be the same as datacontext
  //     const unsub = onSnapshot(doc(db, 'users', auth.currentUser.uid), doc => {
  //       const userObj = doc.data();
  //       setUserData(userObj);
  //       // console.log('listener obj', userObj)
  //       setLoadingUserData(false);
  //     });
  //     return () => {
  //       // console.log('UNMOUNT profile page');
  //       unsub();
  //     };
  //   }, [auth.currentUser.uid]);
  // // console.log(auth.currentUser.uid)
  // // console.log(BANKING_IFRAME)

  // status of each tab
  let tab1Complete = false;
  let tab2Complete = false;
  let tab3Complete = false;
  // let allTabsComplete = false;

  if (emailIsVerified && phoneIsSmsVerified) {
    tab1Complete = true;
  }

  if (licenseSelfieVerified && addressConfirmed) {
    tab2Complete = true;
  }

  if (kbaPassed) {
    tab3Complete = true;
  }

  // if (tab1Complete && tab2Complete && tab3Complete) {
  //   allTabsComplete = true;
  // }

  // const onClickFinalSubmit = async () => {
  //   await updateDoc(userRef, {
  //     stageOneVerified: true,
  //   });
  //   // have server perform final stage2 verifications
  //   stageTwoVerifyUser(uid, userData?.address?.postal);
  //   navigate('/verify-final');
  // };

  // const generateKBA = async () => {
  //   setFetchingKBA(true);
  //   // call server for questions, save to state

  //   setKBA({});

  //   setFetchingKBA(false);
  // };

  // const submitKBA = async () => {
  //   // send answers

  //   // if not passed, add to kbaError
  //   // where user can resubmit one time
  //   // await updateDoc(userRef, {
  //   //   kbaError: ['did not pass checks'],
  //   //   kbaPassed: false,
  //   //   kbaPassedAt: '',
  //   // });

  //   // if passed, update sb
  //   await updateDoc(userRef, {
  //     kbaError: '',
  //     kbaPassed: true,
  //     kbaPassedAt: new Date().toString(),
  //     stageOneVerified: true,
  //     stageOneVerifiedAt: new Date().toString(),
  //   });
  //   // have server perform final stage2 verifications
  //   stageTwoVerifyUser(uid, userData?.address?.postal);
  // };

  const finalContinue = async () => {
    // after all checks done, verify stage one and submit stage two
    await updateDoc(userRef, {
      stageOneVerified: true,
      stageOneVerifiedAt: new Date().toString(),
    });
    // have server perform final stage2 verifications
    stageTwoVerifyUser(uid, userData?.address?.postal);
  };

  const generateLicenseSelfieCheck = async () => {
    setFetchingLicenseURL(true);
    const response = await generateLicenseSelfieCheckURL(cell);
    // console.log('license response', response);
    if (!response.success) {
      toast({
        title: t('couldNotGenerateVerification'),
        description: t('pleaseTryAgainLater'),
        status: 'error',
        isClosable: true,
      });
      setFetchingLicenseURL(false);
      return;
    }

    await updateDoc(userRef, {
      licenseSelfieVerifiedURL: response.data.url,
    });
    setFetchingLicenseURL(false);
  };

  const onClickChangeEmail = async () => {
    const newEmail = await window.prompt(t('enterANewEmail'));
    // console.log(newEmail);

    if (!newEmail) return;
    if (!window.confirm(t('changeEmailAreYouSure', { newEmail }))) {
      return;
    }

    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        // Email updated!
        // setFormData(prevState => ({
        //   ...prevState,
        //   email: newEmail,
        // }));
        updateDoc(userRef, {
          email: newEmail,
        });
        toast({
          title: t('emailUpdated'),
          status: 'success',
        });
      })
      .catch(error => {
        // An error occurred
        console.log(error?.code);
        toast({
          title: t('couldNotUpdateEmail'),
          // description: JSON.stringify(error),
          description: error.code,
          status: 'error',
          isClosable: true,
        });
      });
  };

  const onClickChangeCell = async () => {
    const newCell = await window.prompt(t('enterNewCellDoNotAddPlusOne'));
    // console.log(newEmail);

    if (!newCell) return;

    if (!window.confirm(t('changeCellAreYouSure', { newCell }))) {
      return;
    }

    const check = await checkCell(newCell);
    // console.log(check);

    if (check.reject) {
      toast({
        title: t('cannotUpdateCell') + check?.rejectionReason,
        status: 'warning',
      });
      return;
    }

    updateDoc(userRef, {
      cell: newCell,
    });
    toast({
      title: t('cellUpdated'),
      status: 'success',
    });
  };

  const onClickManualApproveEmail = async () => {
    // skip sending email verification in dev
    if (!isDev) return;

    await updateDoc(userRef, {
      emailIsVerified: true,
      emailIsVerifiedAt: new Date().toString(),
    });
    toast({
      description: t('emailVerificationComplete'),
      status: 'success',
      isClosable: true,
    });
  };

  const onClickClearAll = () => {
    onClickClearContactApproval();
    onClickClearLicenseApproval();
    onClickClearKBAApproval();
  };
  const onClickClearContactApproval = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      emailIsVerified: '',
      emailIsVerifiedAt: '',
      phoneIsSmsVerified: '',
      phoneIsSmsVerifiedAt: '',
      phoneIsVoiceVerified: '',
      phoneIsVoiceVerifiedAt: '',
    });
  };

  const onClickManualApproveSMS = async () => {
    // skip sending email verification in dev
    if (!isDev) return;

    await updateDoc(userRef, {
      phoneIsSmsVerified: true,
      phoneIsSmsVerifiedAt: new Date().toString(),
    });
    toast({
      description: t('smsVerificationComplete'),
      status: 'success',
      isClosable: true,
    });
  };

  const onClickManualApproveLicenseSelfie = async () => {
    if (!isDev) return;

    const userPhoto = getRandomUserPhoto();

    await updateDoc(userRef, {
      addressConfirmed: false,
      address: {
        civic: '123',
        street: 'Main Street',
        city: 'Toronto',
        province: 'on',
        postal: 'M5W 1E6',
        country: 'ca',
      },
      photoURL: userPhoto,
      licenseSelfieVerified: true,
      licenseSelfieVerifiedAt: new Date().toString(),
      licenseSelfieVerifiedURL: '',
      licenseSelfieError: '',
      // data format from persona
      firstName,
      lastName,
      licenseSelfieData: {
        selfiePhotoUrl: userPhoto,
        firstName,
        lastName,
        bday: '2000-01-01',
        addressStreet1: '123 Main Street',
        addressStreet2: '',
        addressCity: 'Toronto',
        addressProvince: 'Ontario',
        postal: 'M5W 1E6',
        country: 'CA',
        licenseId: '',
        licenseId2: 'D6101-12345-32111',
        licenseExpiration: '',
        licensePhotoFront:
          'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2F1%20-%20license.png?alt=media&token=08066a29-0e43-45fb-9b28-ef765bf06f2b',
        licensePhotoBack: '',
        licensePicCropped:
          'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Flicense-cropped.png?alt=media&token=0bf29721-a6ab-4b3d-b85b-60c685be2742',
        device: {},
      },
      // docupass data
      // licenseSelfieData: {
      //   success: true,
      //   faceimage: [
      //     {
      //       type: 'jpg',
      //       url: userPhoto,
      //     },
      //   ],
      //   data: {
      //     issuerOrg_region_full: 'Quebec',
      //     nationality_full: 'Canada',
      //     firstName: firstName,
      //     lastName: lastName,
      //     address1: '123 Main Street',
      //     address2: 'Montreal',
      //     postcode: 'H3N 1N6',
      //   },
      // },
    });
  };

  const onClickClearLicenseApproval = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      addressConfirmed: '',
      address: '',
      licenseSelfieVerified: '',
      licenseSelfieVerifiedAt: '',
      licenseSelfieVerifiedURL: '',
      licenseSelfieData: '',
      licenseSelfieError: '',
    });
  };

  const onClickManualApproveKBA = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      kbaPassed: true,
      kbaPassedAt: new Date().toString(),
      kbaError: '',
      stageOneVerified: true,
      stageOneVerifiedAt: new Date().toString(),
    });
    // toast({
    //   description: 'KBA complete',
    //   status: 'success',
    // });
  };

  const onClickClearKBAApproval = async () => {
    if (!isDev) return;

    await updateDoc(userRef, {
      kbaPassed: '',
      kbaPassedAt: '',
      kbaError: '',
      kba: '',
      kbaFullResponse: '', // no
      kbaDecisionResponse: '',
      kbaQuestionAnswers: '',
      stageOneVerified: false,
      stageOneVerifiedAt: ''
    });
  };

  const onClickSendEmailPasscode = async () => {
    setEmailVerificationSent(true);
    const response = await sendEmailPasscode(auth.currentUser.uid);

    if (!response.success) {
      setEmailVerificationSent(false);
      toast({
        title: t('couldNotGenerateEmailPasscode'),
        description: t('pleaseTryAgainLater') + response.data?.ErrorMessage,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    toast({
      description: t('emailVerificationSent'),
      isClosable: true,
    });
  };

  const onChangeEmailPasscode = async e => {
    if (e.length === 4) {
      // check e against emailPasscode
      if (Number(e) === Number(emailPasscode)) {
        // if ok, change email verified
        await updateDoc(userRef, {
          emailIsVerified: true,
          emailIsVerifiedAt: new Date().toString(),
        });
        toast({
          description: t('passcodeAcceptedEmailVerified'),
          status: 'success',
        });
      } else {
        // if not ok, show toast
        toast({
          title: t('incorrectPasscode'),
          description: t('pleaseVerifyYourEmail'),
          status: 'error',
          isClosable: true,
        });
      }
    }
  };

  const onChangeAddressComponent = e => {
    updateDoc(userRef, {
      address: {
        ...address,
        [e.target.id]: e.target.value,
      },
    });
  };

  const onClickConfirmAddress = () => {
    updateDoc(userRef, {
      addressConfirmed: true,
    });
  };
  const handleToTab1Click = () => {
    tab1Ref.current.click();
  };
  const handleToTab2Click = () => {
    tab2Ref.current.click();
  };
  const handleToTab3Click = () => {
    tab3Ref.current.click();
  };

  const sendSMSVerification = async () => {
    try {
      setSendSmsPINText(t('sending'));
      const response = await fetch(TWILIO_START_VERIFY, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: '+1' + cell,
          channel: 'sms', // or call
          locale: 'en',
        }),
      });

      const json = await response.json();

      if (response.status === 429) {
        setSendSmsPINText('');
        toast({
          description: t('youHaveAttemptedToVerifyTooManyTimes'),
          status: 'error',
          isClosable: true,
        });
      } else if (response.status >= 400) {
        setSendSmsPINText(json.error);
      } else {
        // modal.style.display = "block";
        if (json.success) {
          setSendSmsPINText(t('verificationCodeSentEnterBelow'));
          setSmsPINSent(true);
        } else {
          setSendSmsPINText(json.error);
        }
      }
    } catch (error) {
      // console.error(error);
      setSendSmsPINText('');
      toast({
        description: t('somethingWentWrongWhileSendingTheCode'),
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onChangeSmsPIN = async e => {
    // console.log(e);
    if (e.length === 4) {
      setFetchingSmsPIN(true);
      setSendSmsPINText(t('checkingCode'));

      // api call
      const data = {
        phoneNumber: '+1' + cell,
        otp: e,
      };

      try {
        // sends the value of both the phone number and the user-submitted OTP to the app’s backend endpoint, /verify-otp
        const response = await fetch(TWILIO_VERIFY_OTP, {
          method: 'POST',
          // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          setFetchingSmsPIN(false);
          setSendSmsPINText('');
          toast({
            description: 'Verification error: ' + response.statusText,
            status: 'error',
            isClosable: true,
          });
          return;
        }

        // receive a response from the POST request
        const check = await response.json();

        // const text = response.ok ? check.status : response.statusText;
        // console.log('text', text); // pending or approved

        if (check.status === 'approved') {
          // if success, show message, change status in db
          // setFormData(prevState => ({
          //   ...prevState,
          //   phoneIsSmsVerified: true,
          //   phoneIsSmsVerifiedAt: new Date().toString(),
          // }));

          await updateDoc(userRef, {
            phoneIsSmsVerified: true,
            phoneIsSmsVerifiedAt: new Date().toString(),
          });
          setFetchingSmsPIN(false);
          setSendSmsPINText('');
          toast({
            description: t('smsVerificationComplete'),
            status: 'success',
            isClosable: true,
          });
        } else {
          // if fail, show reset button to remove text and fetching false
          setFetchingSmsPIN(false);
          setSendSmsPINText(t('incorrectPasscode'));
        }
      } catch (e) {
        setFetchingSmsPIN(false);
        setSendSmsPINText('');
        toast({
          description: t('verificationError') + JSON.stringify(e),
          status: 'error',
          isClosable: true,
        });
        console.log(JSON.stringify(e));
      }
    }
  };

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const sendErrorComments = e => {
    // pull id from button
    const comments = formData[e.target.id];
    updateDoc(userRef, {
      [e.target.id]: comments,
    });

    // send notice to admin
    sendEmailToAdmin(
      'verificationError',
      `${firstName} ${lastName} - ${dataContext.userData?.uid}`
    );
  };

  const renderAddress = () => {
    let canConfirm = false;
    if (
      address.civic &&
      address.street &&
      address.city &&
      address.province &&
      address.province.length === 2 &&
      address.postal
    ) {
      canConfirm = true;
    }

    let warning;

    if (
      address?.province &&
      !eligibleWarrantyProvinces.includes(address?.province.toLowerCase())
    ) {
      // neither eligible province
      warning = (
        <>
          <Alert
            status="warning"
            variant="left-accent"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            mt={4}
          >
            <AlertIcon mr={0} />
            <AlertTitle mt={1} mb={1} fontSize="lg">
              {t('pleaseNote')}
            </AlertTitle>
            <AlertDescription maxWidth="lg">
              {t('onlyAvailableOntarioAndQuebec')}
            </AlertDescription>
          </Alert>
        </>
      );
    }

    return (
      <div>
        <Stack direction="row" w="100%" mt={2}>
          <Box flex="1" color={!address?.civic && 'red'}>
            {t('civic')}
          </Box>
          <Box flex="2" color={!address?.street && 'red'}>
            {t('street')}
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.civic || ''}
                isReadOnly={addressConfirmed}
                id="civic"
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="2">
            <InputGroup>
              <Input
                type="text"
                value={address?.street || ''}
                isReadOnly={addressConfirmed}
                id="street"
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>
        {!addressConfirmed && (
          <>
            <Stack direction="row" w="100%">
              <Box flex="1" color="gray">
                {t('aptSuite')}
              </Box>
              <Box flex="2"></Box>
            </Stack>
            <Stack direction="row" w="100%">
              <Box flex="1">
                <Input
                  type="text"
                  value={address?.apt || ''}
                  id="apt"
                  onChange={onChangeAddressComponent}
                />
              </Box>
              <Box flex="2"></Box>
            </Stack>
          </>
        )}

        <Box flex="1" color={!address?.city && 'red'} mt={2}>
          {t('city')}
        </Box>

        <Box flex="1">
          <InputGroup>
            <Input
              type="text"
              value={address?.city || ''}
              id="city"
              _focus={
                addressConfirmed && {
                  borderColor: 'inherit',
                }
              }
              isReadOnly={addressConfirmed}
              onChange={onChangeAddressComponent}
            />
            {addressConfirmed && (
              <InputRightElement children={<BsLock color="lightgrey" />} />
            )}
          </InputGroup>
        </Box>

        <Stack direction="row" w="100%" mt={2}>
          <Box
            flex="1"
            color={
              (!address?.province || address?.province.length !== 2) && 'red'
            }
          >
            {t('province')}
          </Box>
          <Box flex="1" color={!address?.postal && 'red'}>
            {t('postal')}
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              {address?.province.length === 2 ? (
                <Input
                  type="text"
                  value={address?.province.toUpperCase() || ''}
                  isReadOnly={true}
                  id="province"
                  _focus={{
                    borderColor: 'inherit',
                  }}
                />
              ) : (
                <Select
                  id="province"
                  value={address?.province || ''}
                  onChange={onChangeAddressComponent}
                  disabled={addressConfirmed}
                  _focus={
                    addressConfirmed && {
                      borderColor: 'inherit',
                    }
                  }
                >
                  <option value="">Select</option>
                  {allProvinces.map((prov, i) => (
                    <option key={i} value={Object.values(prov)}>
                      {Object.values(prov).map(value => value.toUpperCase())} -{' '}
                      {Object.keys(prov)}
                    </option>
                  ))}
                </Select>
              )}
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.postal || ''}
                isReadOnly={addressConfirmed}
                _focus={
                  addressConfirmed && {
                    borderColor: 'inherit',
                  }
                }
                id="postal"
                onChange={onChangeAddressComponent}
              />
              {addressConfirmed && (
                <InputRightElement children={<BsLock color="lightgrey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>

        {warning}

        {!addressConfirmed && (
          <Button
            mt={10}
            w="full"
            onClick={onClickConfirmAddress}
            disabled={!canConfirm}
            colorScheme="primary"
            // variant="outline"
          >
            {t('confirmAddress')}
          </Button>
        )}
      </div>
    );
  };
  const handleTabsChange = index => {
    setTabIndex(index);
  };

  const renderTab1 = () => {
    return (
      <Flex justify={'center'}>
        <Stack p={2} spacing={2} w="100%">
          {/* <Text color="primary.500" mb={5}>
            1/3 {t('letsValidateYourEmailAndMobilePhone')}
          </Text> */}

          {/* {!emailIsVerified && (
            <>
              <HStack justify="space-between">
                <Heading as="h3" size="lg" mb="0">
                  {t('emailVerification')}
                </Heading>
              </HStack>
            </>
          )} */}
          {!emailIsVerified ? (
            <>
              {!emailVerificationSent ? (
                <div>
                  <Text textAlign="center">
                    {t('clickToEmailAVerificationPasscodeTo')} {email}
                  </Text>
                  <br />
                  <Button
                    onClick={onClickSendEmailPasscode}
                    colorScheme="primary"
                    mb={10}
                    w="full"
                    // variant="outline"
                  >
                    {t('sendCapital')}
                  </Button>

                  <br />
                  <Center>
                    <Button
                      fontWeight="normal"
                      variant="link"
                      // as='u'
                      onClick={onClickChangeEmail}
                      size="sm"
                    >
                      {t('changeLoginEmailAddress')}
                    </Button>
                  </Center>
                </div>
              ) : (
                <div>
                  <Text textAlign="center">
                    {t('emailSentTo')} {email},{' '}
                    <span style={{ textDecoration: 'none', color: 'gray' }}>
                      {t('alsoCheckYourSpamFolders')}
                    </span>
                  </Text>
                  <Center my={5}>
                    <Text>{t('enterPasscode')}</Text>
                  </Center>
                  <FormControl mb={5}>
                    <Center>
                      <HStack>
                        <PinInput
                          otp
                          size="lg"
                          onChange={e => onChangeEmailPasscode(e)}
                        >
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </HStack>
                    </Center>
                  </FormControl>
                  <Center>
                    <Button
                      onClick={onClickSendEmailPasscode}
                      // colorScheme="primary"
                      variant="link"
                      fontWeight="normal"
                      my={5}
                      size="sm"
                    >
                      {t('resendEmail')}
                    </Button>
                  </Center>

                  <Center>
                    <Button
                      my={5}
                      fontWeight="normal"
                      variant="link"
                      onClick={onClickChangeEmail}
                      size="sm"
                    >
                      {t('changeLoginEmailAddress')}
                    </Button>
                  </Center>
                </div>
              )}
            </>
          ) : (
            <VStack justify="space-between">
              <Text>
                {t('emailVerified')}
                <CheckIcon color="blue.500" mx="3" />
              </Text>
              <Text color="gray">
                {moment(
                  emailIsVerifiedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY - h:mma')}
              </Text>
            </VStack>
          )}

          <br />

          {/* <Divider borderColor="primary.500" /> */}

          {phoneIsSmsVerified ? (
            <>
              <br />
              <VStack justify="space-between">
                <Text>
                  {t('smsVerified')}
                  <CheckIcon color="blue.500" mx="3" />
                </Text>
                <Text color="gray">
                  {moment(
                    phoneIsSmsVerifiedAt,
                    'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                  ).format('MMM D, YYYY - h:mma')}
                </Text>
              </VStack>
            </>
          ) : (
            <div>
              <Text textAlign="center" my={5}>
                {t('clickToReceiveAOneTimePasscodeTo')} {cell}
              </Text>
              {sendSmsPINText ? (
                <Text textAlign="center">{sendSmsPINText}</Text>
              ) : (
                <Button
                  colorScheme="primary"
                  w="full"
                  // variant="outline"
                  onClick={sendSMSVerification}
                  mb={15}
                >
                  {t('sendCapital')}
                </Button>
              )}
              <br />
              {fetchingSmsPIN ? (
                <FormControl mb={5}>
                  <Center>
                    <HStack>
                      <Spinner />
                      <Spinner />
                      <Spinner />
                      <Spinner />
                    </HStack>
                  </Center>
                </FormControl>
              ) : (
                <div>
                  {smsPINSent && (
                    <>
                      <FormControl mb={5}>
                        <Center>
                          <HStack>
                            <PinInput
                              otp
                              size="lg"
                              onChange={e => onChangeSmsPIN(e)}
                            >
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                        </Center>
                      </FormControl>

                      <br />
                      <Center my={5}>
                        <Button
                          variant="link"
                          fontWeight="normal"
                          onClick={sendSMSVerification}
                          size="sm"
                        >
                          {t('resendSMS')}
                        </Button>
                      </Center>
                    </>
                  )}
                </div>
              )}
              <br />
              <Center>
                <Button
                  variant="link"
                  fontWeight="normal"
                  onClick={onClickChangeCell}
                  size="sm"
                >
                  {t('changeCell')}
                </Button>
              </Center>
            </div>
          )}

          {tab1Complete && (
            <>
              {/* <Divider /> */}
              <br />

              {/* <Text mb={5} color="primary.500" textAlign="center">
                {t('yourContactInfoHasBeenVerified')}
              </Text> */}

              <br />
              <br />
              <Center>
                <Button
                  // onClick={
                  //   !tab2Complete ? handleToTab2Click : handleToTab3Click
                  // }
                  onClick={handleToTab2Click}
                  width="full"
                  colorScheme="primary"
                >
                  {t('next')}
                </Button>
              </Center>
            </>
          )}

          {isDev && (
            <>
              {!tab1Complete ? (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Box p="2" color="lightgrey">
                    <Text>*DEV*</Text>
                    {!emailIsVerified && (
                      <Button
                        m="1"
                        onClick={onClickManualApproveEmail}
                        variant="link"
                        color="lightgrey"
                      >
                        approve-email
                      </Button>
                    )}
                    {!phoneIsSmsVerified && (
                      <Button
                        m="1"
                        onClick={onClickManualApproveSMS}
                        variant="link"
                        color="lightgrey"
                      >
                        approve-sms
                      </Button>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Button
                    onClick={onClickClearContactApproval}
                    color="lightgray"
                    variant="link"
                  >
                    *DEV* clear
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </Flex>
    );
  };

  const renderTab2 = () => {
    return (
      <Flex justify={'center'}>
        <Stack p={2} spacing={2} w="100%">
          {/* {!licenseSelfieVerified && (
            <> */}
          {/* <HStack justify="space-between">
                <Heading as="h3" size="lg">
                  2/3 {t('driversLicenseVsSelfie')}
                </Heading>
              </HStack> */}
          {userData.licenseSelfieWarning && (
            <Alert
              status="warning"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon />
              <AlertDescription maxWidth="sm">
                {userData.licenseSelfieWarning}
              </AlertDescription>
            </Alert>
          )}

          {!licenseSelfieVerified ? (
            <>
              <Text textAlign="center">
                {t('letsCaptureYourDriversLicenseAndSelfie')}
              </Text>
              <br />

              {!licenseSelfieVerifiedURL ? (
                <>
                  {!fetchingLicenseURL ? (
                    <>
                      {/* <Text>
                        {t('theSelfieTakenWillServeAsProfilePicture')}
                      </Text>
                      <br /> */}
                      <Button
                        onClick={generateLicenseSelfieCheck}
                        colorScheme="primary"
                      >
                        {t('continueCapital')}
                      </Button>
                    </>
                  ) : (
                    <>
                      <br />
                      <Center>
                        <br />
                        <Spinner />
                      </Center>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!licenseSelfieError ? (
                    <>
                      <Text textAlign="center">
                        {t(
                          'clickContinueToBeginTheVerificationProcessMustBeOnMobile'
                        )}
                      </Text>
                      <br />
                      <Button
                        w="full"
                        colorScheme="primary"
                        variant="outline"
                        as="a"
                        href={licenseSelfieVerifiedURL}
                        target="_blank"
                      >
                        {t('continue')}
                        <ExternalLinkIcon mx="2px" />
                      </Button>

                      <br />
                      <Progress
                        size="sm"
                        isIndeterminate
                        colorScheme="primary"
                      />
                      <br />
                      <Text textAlign="center">
                        {t('onceCompletedPleaseAllowAFewMoments')}
                      </Text>
                      <br />
                      <br />
                      <br />
                      <br />
                    </>
                  ) : (
                    <>
                      <Alert
                        status="error"
                        variant="subtle"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        height="200px"
                      >
                        <AlertIcon
                          boxSize="40px"
                          // mr={0}
                        />
                        <AlertTitle mt={4} mb={1} fontSize="lg">
                          {t('couldNotProcess')}
                        </AlertTitle>
                        <AlertDescription maxWidth="sm">
                          {licenseSelfieError}
                        </AlertDescription>
                      </Alert>
                      <FormControl mt="2%">
                        <FormLabel m={0}>
                          {t('sendUsYourCommentsAndWellContactYou')}
                        </FormLabel>
                        <Textarea
                          placeholder={t('addComments')}
                          rows={3}
                          id="licenseSelfieErrorComments"
                          value={formData.licenseSelfieErrorComments}
                          onChange={onChange}
                          disabled={licenseSelfieErrorComments}
                        />
                      </FormControl>
                      {!licenseSelfieErrorComments ? (
                        <Button
                          mt={10}
                          w="full"
                          colorScheme="primary"
                          id="licenseSelfieErrorComments"
                          onClick={sendErrorComments}
                        >
                          {t('sendComments')}
                        </Button>
                      ) : (
                        <Center mt={5}>
                          <Text as="i" color="red">
                            {t('thankYouCommentsReceivedWeAreInvestigating')}
                          </Text>
                        </Center>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <VStack justify="space-between">
                <Text>
                  {t('licenseVerified')}
                  {'! '}
                  <CheckIcon color="blue.500" mx="3" />
                </Text>
                <Text color="gray">
                  {moment(
                    licenseSelfieVerifiedAt,
                    'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                  ).format('MMM D, YYYY - h:mma')}
                </Text>
              </VStack>

              {!addressConfirmed ? (
                <>
                  <Heading size="md">{t('pleaseConfirmYourAddress')}</Heading>

                  {renderAddress()}
                </>
              ) : (
                <>
                  {/* <Divider /> */}
                  <br />
                  <VStack>
                    <Text>
                      {t('informationConfirmed')}

                      <CheckIcon color="blue.500" mx="3" />
                    </Text>
                  </VStack>
                </>
              )}
            </>
          )}
          {tab2Complete && (
            <>
              {/* <Divider /> */}
              {/* <br />
              <Text color="primary.500" mb={5}>
                {t('licenseSectionCompleteNextBanking')}
              </Text> */}
              <br />
              <br />
              <Center>
                <Button
                  // onClick={
                  //   !tab3Complete ? handleToTab3Click : handleToTab1Click
                  // }
                  onClick={handleToTab3Click}
                  width="full"
                  colorScheme="primary"
                >
                  {t('next')}
                </Button>
              </Center>
            </>
          )}

          {isDev && (
            <>
              {!tab2Complete ? (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Button
                    m="1"
                    color="lightgray"
                    variant="link"
                    // color="red"
                    onClick={onClickManualApproveLicenseSelfie}
                  >
                    DEV approve-license
                  </Button>
                </>
              ) : (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Button
                    onClick={onClickClearLicenseApproval}
                    color="lightgray"
                    variant="link"
                  >
                    *DEV* clear
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </Flex>
    );
  };

  const renderTab3 = () => {
    return (
      <>
        <Stack
        // p={2} spacing={2} w="100%"
        >
          {!tab3Complete ? (
            <>
  <CreditQuestionnaire userRef={userRef} userData={userData}/>

              {/* {!showKBA ? (
                <>
                  <Center>
                    <Text textAlign="center">
                      EEZE has partnered with Equifax to verify your identity
                      using questions unique to your credit history. This will
                      not impact your credit score
                    </Text>
                  </Center>

                  <br />
                  <Button
                    onClick={() => setShowKBA(true)}
                    colorScheme="primary"
                    // variant="outline"
                  >
                    Generate Questions
                  </Button>
                </>
              ) : (
                <>
                  <CreditQuestionnaire />
                </>
              )} */}
            </>
          ) : (
            <>
              {/* user should be redirected to final continue */}
              <br />
              <VStack>
                <Text>
                  Identity Verified!
                  <CheckIcon color="blue.500" mx="3" />
                </Text>
                <Text color="gray">
                  {moment(kbaPassedAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
                    'MMM D, YYYY - h:mma'
                  )}
                </Text>
              </VStack>
              <br />
              <br />
              <br />
              <Center>
                <Button
                  onClick={finalContinue}
                  width="full"
                  colorScheme="primary"
                >
                  Done
                </Button>
              </Center>
            </>
          )}

          {isDev && (
            <>
              <br />
              <br />
              <br />
              {!tab3Complete ? (
                <Button
                  onClick={onClickManualApproveKBA}
                  color="lightgrey"
                  variant="link"
                >
                  DEV approve kba
                </Button>
              ) : (
                <Button
                  onClick={onClickClearKBAApproval}
                  color="lightgray"
                  variant="link"
                >
                  DEV clear KBA
                </Button>
              )}
            </>
          )}
        </Stack>
      </>
    );
  };

  if (stageTwoWarnings) {
    return (
      <>
        <Header />
        <Box p={2} minW={'400px'}>
          <Heading size="md" mb={3}>
            {t('verification')}
          </Heading>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              {t('verificationErrors')}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {Array.isArray(stageTwoWarnings) ? (
                <>
                  {stageTwoWarnings?.map((warning, i) => (
                    <Text key={i}>{warning}</Text>
                  ))}
                </>
              ) : (
                <Text>{stageTwoWarnings}</Text>
              )}
            </AlertDescription>
          </Alert>
          <FormControl mt="2%">
            <FormLabel m={0}>
              {t('sendUsYourCommentsAndWellContactYouToResolve')}
            </FormLabel>
            <Textarea
              placeholder={t('addComments')}
              rows={3}
              id="stageTwoWarningsComments"
              value={formData.stageTwoWarningsComments}
              onChange={onChange}
              disabled={stageTwoWarningsComments}
            />
          </FormControl>
          {!stageTwoWarningsComments ? (
            <Button
              mt={10}
              w="full"
              colorScheme="primary"
              id="stageTwoWarningsComments"
              onClick={sendErrorComments}
            >
              {t('sendComments')}
            </Button>
          ) : (
            <Center mt={5}>
              <Text as="i" color="red">
                {t('thankYouCommentsReceivedWeAreInvestigating')}
              </Text>
            </Center>
          )}
        </Box>
      </>
    );
  }
  // console.log('!bankSkipped', !bankSkipped)
  // console.log('!bankAccountAdded', !bankAccountAdded)
  // console.log('bankAccountIframe', !!bankAccountIframe)
  // console.log('!bankAccountError', !bankAccountError)
  // console.log('tabIndex === 2', tabIndex === 2)

  if (stageOneVerified) {
    return (
      <>
        <Header />
        <AppBody>
          {!stageTwoWarnings && (
            <>
              <Box>
                <Center>
                  <Image
                    src={checkMark}
                    alt="Check"
                    boxSize="25%"
                    maxWidth="200px"
                    objectFit="cover"
                  />
                </Center>
              </Box>
              <Alert
                status="success"
                bgColor="white"
                borderWidth="1"
                pt={0}
                borderColor="primary.500"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="300px"
              >
                <AlertTitle mb={1} fontSize="lg">
                  {t('congratulations')}
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  <Text>{t('youvePassedThePreliminaryEEZEChecks')}</Text>
                  <Text my={5}>{t('EEZEWillPerformAFinalVerification')}</Text>
                  <Text>{t('inTheMeantimeContinueToUseEEZE')}</Text>
                </AlertDescription>
              </Alert>
              <Button
                onClick={() => navigate('/user/' + userData.uid)}
                colorScheme="primary"
                // bgColor="primary.500"
                // color="white"
                // size="lg"
                w="full"
                // m="5"
                leftIcon={<BsFillShieldFill />}
              >
                View EEZE Verified Badge
              </Button>
            </>
          )}

          {stageTwoWarnings && (
            <>
              <Alert
                status="error"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
              >
                <AlertIcon boxSize="40px" />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  {t('verificationErrors')}
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  {stageTwoWarnings?.map((warning, i) => (
                    <Text key={i}>{warning}</Text>
                  ))}
                </AlertDescription>
              </Alert>
              <FormControl mt="2%">
                <FormLabel m={0}>
                  {t('sendUsYourCommentsAndWellContactYouToResolve')}
                </FormLabel>
                <Textarea
                  placeholder={t('addComments')}
                  rows={3}
                  id="stageTwoWarningsComments"
                  value={formData.stageTwoWarningsComments}
                  onChange={onChange}
                  disabled={stageTwoWarningsComments}
                />
              </FormControl>
              {!stageTwoWarningsComments ? (
                <Button
                  bgColor="black"
                  color="white"
                  id="stageTwoWarningsComments"
                  onClick={sendErrorComments}
                >
                  {t('sendComments')}
                </Button>
              ) : (
                <Center>
                  <Text as="i" color="red">
                    {t('thankYouCommentsReceivedWeAreInvestigating')}
                  </Text>
                </Center>
              )}
            </>
          )}

          {isDev && (
            <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Button
                onClick={onClickClearAll}
                color="lightgray"
                variant="link"
              >
                *DEV* clear all
              </Button>
            </>
          )}
        </AppBody>
      </>
    );
  }
  // console.log(activeStep)
  return (
    <>
      <Header />
      <AppBody>
        <Heading size="md" mb={3} textAlign="center">
          {t('verification')} {(tabIndex || 0) + 1}/3
        </Heading>

        <Stepper size="lg" index={tabIndex} mx={5}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                {/* <StepTitle>{step.title}</StepTitle> */}
                {/* <StepDescription>{step.description}</StepDescription> */}
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>

        <Tabs
          isFitted
          variant="unstyled"
          // variant="enclosed-colored"
          // colorScheme="primary"
          // defaultIndex={defaultTab}
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab
              ref={tab1Ref}
              // isDisabled={tab1Complete}
            >
              {/* 1/3 */}{' '}
              {/* {t('contact')}
              {tab1Complete ? (
                <CheckIcon color="blue.500" ml="1" />
              ) : (
                <NotAllowedIcon color="secondary.500" ml="1" />
              )} */}
            </Tab>
            <Tab
              ref={tab2Ref}
              // isDisabled={tab2Complete || !tab1Complete}
            >
              {/* 2/3 */}{' '}
              {/* {t('license')}
              {tab2Complete ? (
                <CheckIcon color="blue.500" ml="1" />
              ) : (
                <NotAllowedIcon color="secondary.500" ml="1" />
              )} */}
            </Tab>
            <Tab
              ref={tab3Ref}
              // isDisabled={tab3Complete || !tab2Complete}
            >
              {/* 3/3 */}{' '}
              {/* {t('bank')}
              {bankAccountAdded ? (
                <CheckIcon color="blue.500" ml="1" />
              ) : (
                <NotAllowedIcon color="secondary.500" ml="1" />
              )} */}
            </Tab>
          </TabList>
          <TabPanels p={0} m={0}>
            <TabPanel p={0} m={0}>
              {renderTab1()}
            </TabPanel>
            <TabPanel p={0} m={0}>
              {renderTab2()}
            </TabPanel>
            <TabPanel p={0} m={0}>
              {renderTab3()}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </AppBody>
    </>
  );
}

export default Verify;
