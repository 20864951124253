import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import moment from 'moment';
import {
  Box,
  Card,
  Center,
  Divider,
  Text,
  IconButton,
  Heading,
  Button,
  ButtonGroup,
  Image,
  Badge,
  Stack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
  TagLabel,
  Tag,
} from '@chakra-ui/react';
import { ChatIcon, EditIcon } from '@chakra-ui/icons';
import { BsChevronRight, BsEye } from 'react-icons/bs';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { FaRegShareSquare, FaRegPlusSquare } from 'react-icons/fa';
import { BsTools } from 'react-icons/bs';
import { FaEllipsisV } from 'react-icons/fa';

import currencyFormat from '../services/currencyFormat';
import { LISTING, db } from '../constants';
import ShareModal from '../components/ShareModal';
import ListingCardLoading from '../components/ListingCardLoading';
import { ReactComponent as Main } from '../assets/svg/main.svg';

function ListingCardWarranty({ listingUid, onEdit, onDelete }) {
  const [listing, setListing] = useState(null);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    // get and listen to this document
    // console.log('listingUid', listingUid)
    let unsubscribe;
    if (listingUid) {
      unsubscribe = onSnapshot(doc(db, 'listings', listingUid), doc =>
        setListing(doc.data())
      );
    }
    if (listing?.status === LISTING.STATUS.POSTED) {
      // only realtime listener when could update with new buyer
      return unsubscribe;
    }
  }, [listingUid, listing]);

  if (!listing) {
    return <ListingCardLoading />;
  }
  let isPosted = true;
  if (listing.status === LISTING.STATUS.DRAFT) {
    isPosted = false;
  }

  const viewBuyers = () => {
    // if (!isPosted) return;
    navigate('/buyers/' + listing.uid);
  };
  const toTransaction = () => {
    if (!isPosted) return;
    navigate('/transaction/' + listing.uid);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ShareModal url={listing.shortUrl} />
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Card
        p={0}
        my={2}
        mx={1}
        mt={0}
        shadow="lg"
        bgColor={
          listing.status === LISTING.STATUS.COMPLETED ? '#E2E8F0' : 'white'
        }
        borderWidth={1}
        borderColor="darkgrey"
      >
        {listing.status === LISTING.STATUS.PRIVATE ? (
          <Heading size="md" px={3} py={'2%'}>
            {listing.title || ' - NEW -'}
          </Heading>
        ) : (
          <Flex justify="space-between" m={0} align="center">
            <Text
              // pl={'2%'}
              pl={3}
              rows={1}
              fontSize="lg"
              fontWeight="800"
              // textDecoration={listing.status !== LISTING.STATUS.COMPLETED ? 'underline' : ''}
              color={
                listing.status !== LISTING.STATUS.COMPLETED
                  ? 'primary.600'
                  : 'black'
              }
              onClick={() => {
                if (listing.status !== LISTING.STATUS.COMPLETED) {
                  navigate('/listing/' + listing.uid);
                }
              }}
            >
              {listing.title || ' UNTITLED'}
            </Text>
            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisV />}
                  variant="ghost"
                  _hover={{ bgColor: 'inherit' }}
                  _active={{ bgColor: 'inherit' }}
                />
                <MenuList>
                  {listing?.eezeID && (
                    <Center>
                      <Text fontSize="sm">
                        EEZE ID: {listing.eezeID.toUpperCase()}
                      </Text>
                    </Center>
                  )}
                  <Center>
                    <Text as="i" color="gray" fontSize="sm">
                      {listing?.postedAt ? (
                        <>
                          Posted:{' '}
                          {moment(
                            listing.postedAt,
                            'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                          ).format('MMM D, YYYY')}
                        </>
                      ) : (
                        <>
                          Created:{' '}
                          {moment(
                            listing.createdAt,
                            'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                          ).format('MMM D, YYYY')}
                        </>
                      )}
                    </Text>
                  </Center>

                  <MenuDivider />

                  {listing.buyerUid && (
                    <MenuItem
                      icon={<AiOutlineDollarCircle />}
                      onClick={toTransaction}
                    >
                      To Transaction
                    </MenuItem>
                  )}
                  {listing.status !== LISTING.STATUS.DRAFT && (
                    <MenuItem
                      icon={<FaRegPlusSquare />}
                      onClick={() =>
                        navigate(
                          '/' +
                            listing.type.toLowerCase() +
                            '-addons/' +
                            listing.uid
                        )
                      }
                    >
                      View Add-ons
                    </MenuItem>
                  )}
                  {listing.status !== LISTING.STATUS.COMPLETED && (
                    <MenuItem icon={<FaRegShareSquare />} onClick={onOpen}>
                      Share Listing
                    </MenuItem>
                  )}
                  <MenuItem
                    icon={<BsTools />}
                    onClick={() => navigate('/toolkit/' + listing.uid)}
                  >
                    Sales Toolkit
                  </MenuItem>
                  <MenuItem
                    icon={<BsEye />}
                    onClick={() => navigate('/listing/' + listing.uid)}
                  >
                    View Listing Page
                  </MenuItem>
                  <MenuItem icon={<ChatIcon />} onClick={viewBuyers}>
                    View Buyers
                  </MenuItem>

                  <MenuDivider />
                  <MenuItem icon={<EditIcon />} onClick={() => onEdit(listing)}>
                    Edit Listing...
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        )}
        <Stack direction="row">
          <Stack w="50%" pl={2} spacing={0} justify="center" align="center">
            <Box direction="row" align="center">
              {listing.status !== LISTING.STATUS.PRIVATE && (
                <>
                  <Badge
                    // fontSize="0.8em"
                    variant="outline"
                    bgColor="white"
                    colorScheme={!isPosted ? '' : 'gray'}
                  >
                    {listing.status}
                  </Badge>
                  {listing.status !== LISTING.STATUS.COMPLETED &&
                    !listing.verifiedBy && <Badge ml={1}>UNVERIFIED</Badge>}
                  {/* {listing.status === LISTING.STATUS.ACCEPTED &&
                    !listing.verifiedBy && <Badge ml={1}>UNVERIFIED</Badge>} */}
                </>
              )}
            </Box>

            {listing.status !== LISTING.STATUS.COMPLETED && (
              <>
                {!listing.warrantyActive ? (
                  <>
                    {listing.warrantyPending ? (
                      <Text color="primary.500" textAlign="center">
                        Warranty Pending
                      </Text>
                    ) : (
                      <>
                        {listing.warrantyName && (
                          <Text color="primary.500" textAlign="center">
                            Warranty Pending
                          </Text>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Text color="primary.500" textAlign="center">
                      Warranty Active
                    </Text>
                    {listing.warrantyEndDate && (
                      <Text color="primary.500" textAlign="center">
                        to {listing.warrantyEndDate}
                      </Text>
                    )}
                  </>
                )}
              </>
            )}
            <Text fontSize="lg">
              {listing.finalPrice
                ? currencyFormat(listing.finalPrice, 0)
                : listing.askingPrice
                ? currencyFormat(listing.askingPrice, 0)
                : ''}
            </Text>
            {listing.eezeID && listing.status !== LISTING.STATUS.PRIVATE && (
              <>
                {listing.status !== LISTING.STATUS.COMPLETED && (
                  <Text fontSize="md">ID: {listing.eezeID.toUpperCase()}</Text>
                )}
              </>
            )}
          </Stack>

          <Box w="50%" align="center">
            {listing.listingProfilePhoto &&
            typeof listing.listingProfilePhoto === 'string' ? (
              <Image
                maxH={'90px'}
                objectFit="contain"
                alt="listingProfilePhoto"
                src={listing.listingProfilePhoto}
              />
            ) : (
              <Main width="60px" height="100%" />
            )}
          </Box>
        </Stack>
        <Divider mt={2} />
        {/* footer draft listing */}
        {listing.status === LISTING.STATUS.DRAFT && (
          <>
            <Button
              colorScheme="primary"
              // flex={1}
              // w='full'
              m={2}
              bg="white"
              onClick={() => onEdit(listing)}
              leftIcon={<EditIcon />}
              variant="ghost"
            >
              Edit
            </Button>
          </>
        )}

        {/* footer posted not completed */}
        {listing.status !== LISTING.STATUS.PRIVATE &&
          listing.status !== LISTING.STATUS.DRAFT &&
          listing.status !== LISTING.STATUS.COMPLETED && (
            <ButtonGroup w="100%">
              <Button
                colorScheme="primary"
                // flex={1}
                m={2}
                bg="white"
                onClick={() => onEdit(listing)}
                leftIcon={<EditIcon />}
                w="full"
                variant="ghost"
              >
                Edit
              </Button>
              {!listing.potentialBuyers ? (
                <Button
                  // w="50%"
                  w="full"
                  m={2}
                  rightIcon={<FaRegShareSquare />}
                  // variant="outline"
                  onClick={onOpen}
                  colorScheme="primary"
                >
                  Share
                </Button>
              ) : (
                <>
                  {!listing.buyerUid ? (
                    <Button
                      disabled={!isPosted}
                      w="full"
                      // w="50%"
                      m={2}
                      // ml={1}
                      onClick={viewBuyers}
                      // leftIcon={<ChatIcon />}
                      // variant="outline"
                      // bgColor="white"
                      colorScheme="primary"
                    >
                      View Buyers
                      <Tag
                        ml={1}
                        size={'md'}
                        borderRadius="full"
                        // variant={'outline'}
                        colorScheme={'primary'}
                      >
                        <TagLabel>
                          {Object.keys(listing.potentialBuyers).length}
                        </TagLabel>
                      </Tag>
                    </Button>
                  ) : (
                    <Button
                      // disabled={!isPosted}
                      // variant="outline"
                      // colorScheme="primary"
                      color="white"
                      bgColor="black"
                      w="full"
                      // m={2}
                      m={2}
                      // mr={1}
                      onClick={toTransaction}
                      rightIcon={<BsChevronRight />}
                    >
                      TRANSACT
                    </Button>
                  )}
                </>
              )}
            </ButtonGroup>
          )}

        {/* footer listing completed */}
        {listing.status === LISTING.STATUS.COMPLETED && (
          <>
            <Center my={2}>
              <Text fontSize="xs">
                Sold:{' '}
                {moment(
                  listing.completedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY')}
              </Text>
            </Center>
          </>
        )}

        {/* footer private status warranty only */}
        {listing.status === LISTING.STATUS.PRIVATE && (
          <>
            {listing.warrantyAddonCode && !listing.warrantyActive ? (
              <Button
                bgColor="black"
                color="white"
                // w="50%"
                m={2}
                onClick={() => navigate(`/vehicle-addons/${listing.uid}`)}
                >
                Pay for Warranty
              </Button>
            ) : (
              <Button
              colorScheme="primary"
              // flex={1}
              m={2}
              bg="white"
              onClick={() => navigate(`/vehicle-addons/${listing.uid}`)}
                variant="ghost"
              >
                Warranty Details
              </Button>
            )}
          </>
        )}

      </Card>
    </>
  );
}

export default ListingCardWarranty;
