import {
    getAuth,
} from 'firebase/auth';
import {
    SUBMIT_KBA,
} from '../constants';

const equifaxSubmitKba = async () => {
    const auth = getAuth();
    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            SUBMIT_KBA, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('data from equifaxSubmitKba', data);

        return data;
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default equifaxSubmitKba;
