import { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useToast,
  Text,
  Image,
  Button,
  Center,
  HStack,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Modal,
  useDisclosure,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { BsChevronRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import { db, PRICING_OPTIONS, LINK } from '../constants';
import SpinnerPage from './SpinnerPage';
import BuyAddonModal from './BuyAddonModal';
import DataContext from '../context/DataContext';
import currencyFormat from '../services/currencyFormat';
import currencyFormatFr from '../services/currencyFormatFr';
import numberFormat from '../services/numberFormat';
import numberFormatFr from '../services/numberFormatFr';
import logoImage from '../assets/carfaxcanada.png';
import OrDivider from '../components/OrDivider';
import CreditLogos from '../components/CreditLogos';

function CarfaxPage() {
  const { i18n, t } = useTranslation('global');
  let french = i18n.language === 'fr' ? true : false;

  const [listing, setListing] = useState(false);
  const [addonCode, setAddonCode] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const dataContext = useContext(DataContext);
  const { userData } = dataContext;
  const province = listing?.province;
  // Redirect if listing is not user's or if not vehicle
  useEffect(() => {
    // only seller or buyer can access
    if (listing && listing.sellerUid !== auth.currentUser.uid) {
      if (listing?.buyerUid !== auth.currentUser.uid) {
        toast({
          title: t('cannotEditListing'),
          status: 'error',
          isClosable: true,
        });
        navigate('/');
      }
    }
  }, [auth, listing, navigate, toast, t]);

  useEffect(() => {
    // get and listen to this document
    const unsubscribe = onSnapshot(
      doc(db, 'listings', params.listingUid),
      doc => {
        if (!doc.exists()) {
          navigate('/');
          toast({
            title: t('listingDoesNotExist'),
            status: 'error',
            isClosable: true,
          });
          return;
        }
        // set to state
        setListing(doc.data());
      }
    );
    return unsubscribe;
  }, [navigate, toast, params, t]);

  if (!listing) {
    return <SpinnerPage />;
  }
  
  const onClickBuyCarfax = () => {
    // setAddon(PRICING_OPTIONS.carfaxVhr);
    setAddonCode('carfax');
    onOpen();
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <BuyAddonModal
            addon={PRICING_OPTIONS.addons[addonCode]}
            listingUid={listing.uid}
            province={province}
          />
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={() => {
                onClose();
                // setAddonCode();
              }}
            >
              {t('closeCapital')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Center mb={1}>
        <Image
          src={logoImage}
          alt="Logo"
          width="50%"
          maxW="200"
          objectFit="cover"
        />
      </Center>
      <Text fontSize="lg" align="center" fontWeight="bold" mt={5}>
        {french
          ? PRICING_OPTIONS.addons.carfax.nameFr
          : PRICING_OPTIONS.addons.carfax.name}
      </Text>

      {!listing.vehicleCarfaxVHR ? (
        <>
          {listing.vehicleCarfaxVHRPending ? (
            <Text textAlign="center" color="blue.500" mt={5}>
              {t('reportBeingGeneratedStandbyWeWillNotify')}
            </Text>
          ) : (
            <>
              <Center>
                {!french && <Text fontSize={'2xl'}>$</Text>}
                <Text fontSize={'4xl'} fontWeight={600} mx={2}>
                  {french
                    ? numberFormatFr(
                        PRICING_OPTIONS.addons.carfax[province]?.priceBeforeTax
                      )
                    : numberFormat(
                        PRICING_OPTIONS.addons.carfax[province]?.priceBeforeTax
                      )}
                </Text>
                {french && <Text fontSize={'2xl'}>$</Text>}
              </Center>

              <Text align="center" fontSize="lg">
                {`+ ${!french ? currencyFormat(
                  PRICING_OPTIONS.addons.carfax[province]?.taxes?.gst
                ) : currencyFormatFr(
                  PRICING_OPTIONS.addons.carfax[province]?.taxes?.gst
                )} ${t('gst')}`}
                {PRICING_OPTIONS.addons.carfax[province]?.taxes?.qst !== 0 &&
                  ` + ${!french ? currencyFormat(
                    PRICING_OPTIONS.addons.carfax[province]?.taxes?.qst
                  ) : currencyFormatFr(
                    PRICING_OPTIONS.addons.carfax[province]?.taxes?.qst
                  )} ${t('qst')}`}

              </Text>
              <HStack align={'center'} justify={'center'}>
                {!french && <Text fontSize={'2xl'}>$</Text>}
                <Text
                  fontSize={'4xl'}
                  // fontWeight={600}
                >
                  {french
                    ? numberFormatFr(
                        PRICING_OPTIONS.addons.carfax[province]
                          ?.priceIncludingTax
                      )
                    : numberFormat(
                        PRICING_OPTIONS.addons.carfax[province]
                          ?.priceIncludingTax
                      )}
                </Text>
                {french && <Text fontSize={'2xl'}>$</Text>}
              </HStack>
              <Text textAlign="center">{t('includingTax')}</Text>
              <Text align="center" mt={4}>
                {t('purchaseAnOfficialCarfaxReport')}
              </Text>
              <Center my={3}>
                <a
                  // style={{ color: 'blue' }}
                  href={LINK.sampleCarfaxUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('viewSampleReport')}
                  <ExternalLinkIcon ml="5px" />
                </a>
              </Center>
              <Button
                w="full"
                isDisabled={!userData.bankAccountAdded}
                colorScheme="primary"
                mt={4}
                onClick={onClickBuyCarfax}
              >
                {t('buyFromBankAccount')}
              </Button>
              {!userData.bankAccountAdded && (
                <>
                  <Center mt={2}>
                    <Text>{t('cannotUseBankAccountNotConnected')}</Text>
                  </Center>
                  <Center mb={4}>
                    <Button
                      colorScheme="primary"
                      variant="link"
                      mx={2}
                      // my={5}
                      onClick={() => navigate('/wallet')}
                      rightIcon={<BsChevronRight />}
                    >
                      {t('visitTheWalletPagetoEnable')}
                    </Button>
                  </Center>
                </>
              )}
              <OrDivider my={2} />
              <Button
                w="full"
                colorScheme="primary"
                onClick={() =>
                  window.open(
                    `${
                      PRICING_OPTIONS.addons.carfax[province].checkoutUrl
                    }?prefilled_email=${userData.email.replace(
                      /\+/g,
                      '%2B'
                    )}&client_reference_id=${userData.uid}CARFAX${listing.uid}`,
                    '_blank'
                  )
                }
                mb={2}
              >
                {t('payWithCredit')}
                <ExternalLinkIcon ml="5px" />
              </Button>
              <CreditLogos />
            </>
          )}
        </>
      ) : (
        <>
          <Center my={5}>
            <Button
              colorScheme="primary"
              rightIcon={<ExternalLinkIcon />}
              onClick={() => window.open(listing.vehicleCarfaxVHR, '_blank')}
            >
              {t('clickHereToViewReport')}
            </Button>
          </Center>
        </>
      )}
    </>
  );
}

export default CarfaxPage;
