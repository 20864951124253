import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { FcGoogle } from 'react-icons/fc';
// import { FaFacebook } from 'react-icons/fa';
import { Button, Center, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import OrDivider from '../components/OrDivider';
import getIPDetails from '../services/getIPDetails';
import checkCell from '../services/checkCell';
import { db, isDev, LISTING } from '../constants';

function OAuth(props) {
  const { i18n, t } = useTranslation('global');
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParameters] = useSearchParams();
  const toast = useToast();

  let ref;
  if (queryParameters.get('ref')) {
    ref = queryParameters.get('ref');
    // ex /?ref=garage1
  }
  let aref;
  if (queryParameters.get('aref')) {
    aref = queryParameters.get('aref');
    // ex /?aref=affiliate1
  }
  if (queryParameters.get('a')) {
    aref = queryParameters.get('a');
    // ex /?a=affiliate1
  }
  let iref;
  if (queryParameters.get('iref')) {
    iref = queryParameters.get('iref');
    // ex /?iref=influencer1
  }
  if (queryParameters.get('i')) {
    iref = queryParameters.get('i');
    // ex /?i=influencer1
  }

  const onClick = async service => {
    try {
      // first check cell if on signup page

      let check;
      if (location.pathname !== '/login' && props.formData?.cell) {
        check = await checkCell(props?.formData?.cell);
        // console.log(check);

        if (check?.reject) {
          // setIsSubmitting(false);
          toast({
            title:
              t('thereIsAnErrorWithTheCellProvided') + check?.rejectionReason,
            status: 'warning',
            isClosable: true,
          });
          if (!isDev) {
            // end in prod if cell phone does not pass
            return;
          } else {
            window.alert(
              'Cell phone error but can continue in dev only. ' +
                check?.rejectionReason
            );
          }
        }
      }
      const auth = getAuth();

      let user;
      if (service === 'Facebook') {
        const provider = new FacebookAuthProvider();
        const result = await signInWithPopup(auth, provider);
        user = result.user;
      } else {
        // service === 'Google'
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        // console.log('result', result)
        user = result.user;
      }

      // Check for user
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);

      // pass all query params to redirect
      const existingParams = location?.search || '';

      if (!docSnap.exists()) {
        // If user doesn't exist, create user

        // Google login provides displayName
        // Facebook login provides name
        const nameFromProvider = user.name || user.displayName;
        // const nameArray = nameFromProvider.split(' ');
        // const firstName = nameArray[0];
        // const lastName = nameArray[nameArray.length - 1];

        const ipLocation = await getIPDetails();

        let favListings = [];
        if (queryParameters.get('uid')) {
          const uid = queryParameters.get('uid');
          favListings.push(uid);
        }

        const userDataToSave = {};
        userDataToSave.firstName = props?.formData?.firstName;
        userDataToSave.firstNameOnSignup = props?.formData?.firstName;
        userDataToSave.lastName = props?.formData?.lastName;
        userDataToSave.lastNameOnSignup = props?.formData?.lastName;
        userDataToSave.nameFromProvider = nameFromProvider;
        userDataToSave.email = user?.email;
        userDataToSave.cell = props?.formData?.cell;
        userDataToSave.cellData = check;
        userDataToSave.address = props?.formData?.address || '';
        userDataToSave.emailFromProvider = user?.email;
        userDataToSave.photoURL = user?.photoURL || '';
        userDataToSave.photoURLfromProvider = user?.photoURL || '';
        userDataToSave.language =
        i18n?.resolvedLanguage && i18n.resolvedLanguage.toUpperCase();

        userDataToSave.createdAt = new Date().toString();
        userDataToSave.lastLogin = new Date().toString();
        userDataToSave.loginWith = service; // Facebook or Google

        userDataToSave.signupPathname = location.pathname + location?.search;
        userDataToSave.signupIP = {
          ...ipLocation,
          date: new Date().toString(),
        };
        userDataToSave.stageOneVerified = false;
        userDataToSave.stageTwoVerified = false;
        userDataToSave.stageTwoWarnings = '';
        userDataToSave.favoriteListings = favListings;

        userDataToSave.emailIsVerified = false;
        userDataToSave.emailIsVerifiedAt = '';
        userDataToSave.phoneIsSmsVerified = false;
        userDataToSave.phoneIsSmsVerifiedAt = '';
        userDataToSave.phoneIsVoiceVerified = false;
        userDataToSave.phoneIsVoiceVerifiedAt = '';
        userDataToSave.licenseSelfieVerifiedURL = '';
        userDataToSave.licenseSelfieVerified = false;
        userDataToSave.licenseSelfieVerifiedAt = '';
        userDataToSave.addressConfirmed = false;
        userDataToSave.kbaPassed = '';
        userDataToSave.kbaPassedAt = '';
        userDataToSave.kbaError = '';
        userDataToSave.kbaErrorComments = '';
        userDataToSave.bankAccountIframe = '';
        userDataToSave.bankAccountAdded = '';
        userDataToSave.bankAccountAddedAt = '';
        userDataToSave.bankAccountError = '';
        userDataToSave.walletBalance = 0;
        userDataToSave.uid = user.uid;

        if (props?.selectedPartner) {
          if (props?.selectedPartner?.type === 'garage') {
            userDataToSave.garageRef = props?.selectedPartner?.refSlug;
          } else {
            userDataToSave.affiliateRef = props?.selectedPartner?.refSlug;
          }
        }

        if (props.warrantyRedirect || props.safetyPromotionRedirect) {
          // this flag renders homepage elements differenly
          userDataToSave.warrantySignupOnly = true;
          // on warranty or promotion rediect page
          // the signup flow does not redirect to hompage
          // like /signup or /login or /oauth
          // so handle ref save logic here
          // if there's a ref in the params, add it user
          // if also a uid in params, add refs to that listing (unlikely so skip)
          if (ref) {
            userDataToSave.garageRef = ref;
          }
  
          if (aref) {
            userDataToSave.affiliateRef = aref;
          }
  
          if (iref) {
            userDataToSave.influencerRef = iref;
          }
  
          // await setDoc(doc(db, 'users', user.uid), userDataToSave);
  
          // // now create private vehicle listing and redirect to edit
          // const docRef = await addDoc(collection(db, 'listings'), {
          //   createdAt: new Date().toString(),
          //   affiliateRef: aref ? aref : '',
          //   garageRef: ref ? ref : props.selectedPartner ? props.selectedPartner.refSlug : '',
          //   influencerRef: iref ? iref : '',
          //   sellerUid: user.uid,
          //   language: i18n?.resolvedLanguage && i18n.resolvedLanguage.toUpperCase(),
          //   sellerName: props.formData?.firstName + ' ' + props.formData?.lastName.substring(0, 1) + '.',
          //   sellerFullName: props.formData?.firstName + ' ' + props.formData?.lastName,
          //   sellerEmail: user?.email, // not props.formData?.email
          //   sellerCell: props.formData?.cell,
          //   // sellerLocation: '',
          //   // sellerPhotoURL: userData?.photoURL,
          //   status: LISTING.STATUS.PRIVATE,
          //   type: LISTING.TYPE.VEHICLE,
          //   province: props?.formData?.address?.province || '',
          //   //   ? ''
          //   //   : userData?.address?.province.toLowerCase(),
          //   warrantyName: '',
          //   // props.safetyPromotionRedirect
          //   //   ? 'Powertrain 90-Days'
          //   //   : null,
          //   warrantySafetyPromo: '',
          //   // props.safetyPromotionRedirect ? true : null,
          //   year: '',
          //   make: '',
          //   model: '',
          //   style: '',
          //   vin: '',
          //   odometer: '',
          //   odometerType: 'km',
          //   title: '',
          //   currency: 'CAD',
          // });
  
          // navigate('/edit/' + docRef.id);
        } else {
          // save and send to homepage normal
        }
        await setDoc(doc(db, 'users', user.uid), userDataToSave);
        navigate('/' + existingParams);
        // await setDoc(doc(db, 'users', thisUser.uid), userDataToSave);
        toast({
          title: t('accountCreated'),
          description: t('welcomeToEEZE'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // navigate('/' + existingParams);
      } else {
        // user exists, login
        updateDoc(doc(db, 'users', user.uid), {
          lastLogin: new Date().toString(),
          // emailIsVerified: user.emailVerified,
          // emailIsVerifiedAt: new Date().toString(),
        });

        toast({
          title: t('loginSuccess'),
          description: t('welcomeBack'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/' + existingParams);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: service + ' ' + t('loginError'),
        description: 'Please try Again. ' + JSON.stringify(error),
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <>
    <OrDivider my='14px'/>
    {/* <br/> */}
      <Button
        w={'full'}
        //   maxW={'md'}
        // variant={'outline'}
        colorScheme='primary'
        mb="34px"
        disabled={!props?.oauthEnabled}
        // mt="24px"
        // size='lg'
        borderColor='blackAlpha.700'
        // borderRadius="10px"
        leftIcon={<FcGoogle />}
        onClick={() => onClick('Google')}
      >
        <Center>
          <Text>
            {location.pathname === '/login' ? t('loginWith') : t('signupWith')}{' '}
            Google
          </Text>
        </Center>
      </Button>

      {/* <Button
        w={'full'}
        //   maxW={'md'}
        mb="14px"
        colorScheme={'facebook'}
        // borderRadius="10px"
        disabled={missingInfo}
        leftIcon={<FaFacebook />}
        onClick={() => onClick('Facebook')}
      >
        <Center>
          <Text>
            {location.pathname === '/signup' ? t('signupWith') : t('loginWith')}{' '}
            Facebook
          </Text>
        </Center>
      </Button> */}
      {/* {missingCell && (
        <Center>
          <Text as="i">Mobile Phone Required</Text>
        </Center>
      )} */}
    </>
  );
}

export default OAuth;
