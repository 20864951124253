import {
    getAuth,
} from 'firebase/auth';
import {
    GET_KBA,
} from '../constants';

const equifaxGetKba = async () => {
    const auth = getAuth();
    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            GET_KBA, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('data from equifaxGetKba', data);

        return data;

        // SAMPLE RESPONSE
        // const dataSAMPLE = {
        //     "kountTransactionId": "11bc7c5e-10a9-44f2-938d-05574b125bb9",
        //     "inquiryId": "e7896834-62d4-4784-a98b-8114f5f5c930",
        //     "strategyAlias": "CA_IDV",
        //     "entityId": "entityId",
        //     "decision": "Partial",
        //     "knowledgeBasedVerificationQuestions": [
        //         {
        //             "authenticationId": "ee3dbb4e-52a9-4fa7-8df2-d15d5cbd6143",
        //             "questionnaire": {
        //                 "questionnaireId": 1,
        //                 "questionGroups": [
        //                     {
        //                         "questionGroup": "AUTO_LOAN",
        //                         "appliesToQuestionId": [
        //                             1,
        //                             2,
        //                         ],
        //                     },
        //                     {
        //                         "questionGroup": "GAS_CARD",
        //                         "appliesToQuestionId": [
        //                             3,
        //                         ],
        //                     },
        //                 ],
        //                 "questions": [
        //                     {
        //                         "questionId": 1,
        //                         "questionText": "Your credit file indicates you may have an auto loan/lease which was opened approximately March 2015. Please choose the credit provider for this account from the following options",
        //                         "choiceType": {
        //                             "type": "SINGLE_CHOICE",
        //                         },
        //                         "choices": [
        //                             {
        //                                 "choiceId": 1,
        //                                 "choiceText": "OMNI AUTO FINANCE INC.",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 2,
        //                                 "choiceText": "TRICOR LEASE AND FINANCE CORP",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 3,
        //                                 "choiceText": "GMAC",
        //                                 "isCorrect": true,
        //                             },
        //                             {
        //                                 "choiceId": 4,
        //                                 "choiceText": "CAN ASSOC JAPAN AUTO DEALERS",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 5,
        //                                 "choiceText": "None of the Above",
        //                                 "isCorrect": false,
        //                             },
        //                         ],
        //                     },
        //                     {
        //                         "questionId": 2,
        //                         "questionText": "Your credit file indicates you may have an auto loan/lease which was opened approximately March 2015.Please choose the range within which your (term, ie, monthly) payment falls for the previously referenced account.",
        //                         "choiceType": {
        //                             "type": "SINGLE_CHOICE",
        //                         },
        //                         "choices": [
        //                             {
        //                                 "choiceId": 1,
        //                                 "choiceText": "200 to 209",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 2,
        //                                 "choiceText": "210 to 219",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 3,
        //                                 "choiceText": "220 to 229",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 4,
        //                                 "choiceText": "230 to 239",
        //                                 "isCorrect": true,
        //                             },
        //                             {
        //                                 "choiceId": 5,
        //                                 "choiceText": "None of the Above",
        //                                 "isCorrect": false,
        //                             },
        //                         ],
        //                     },
        //                     {
        //                         "questionId": 3,
        //                         "questionText": "Your credit file indicates you may have a gas card or account which was opened approximately September 2016. Please choose the credit provider for this account from the following options. ",
        //                         "choiceType": {
        //                             "type": "SINGLE_CHOICE",
        //                         },
        //                         "choices": [
        //                             {
        //                                 "choiceId": 1,
        //                                 "choiceText": "GOVERNMENT OF CANADA",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 2,
        //                                 "choiceText": "SHELL",
        //                                 "isCorrect": true,
        //                             },
        //                             {
        //                                 "choiceId": 3,
        //                                 "choiceText": "GE CAPITAL FAMILY",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 4,
        //                                 "choiceText": "IMPERIAL OIL",
        //                                 "isCorrect": false,
        //                             },
        //                             {
        //                                 "choiceId": 5,
        //                                 "choiceText": "None of the Above",
        //                                 "isCorrect": false,
        //                             },
        //                         ],
        //                     },
        //                 ],
        //             },
        //         },
        //         {
        //             "key": "creditFileAssessment",
        //             "value": "Accept",
        //             "errors": [],
        //             "characteristics": [
        //                 {
        //                     "key": "creditFileAgeGT3Y",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Age of Credit file is greater than or equal to three years.",
        //                     "name": "CFI_CFA_04",
        //                 },
        //                 {
        //                     "key": "mismatchSIN",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Social Insurance Number - Mismatch.",
        //                     "name": "CFI_SIN_MM",
        //                 },
        //                 {
        //                     "key": "adequateMatch",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Credit File located - Adequate match.",
        //                     "name": "CFI_CFL_02",
        //                 },
        //                 {
        //                     "key": "probableRepetitionFlag",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Same consumer has more than two applications within the last 72 hours",
        //                     "name": "CFI_PRF_01",
        //                 },
        //                 {
        //                     "key": "phoneNumberMatch",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Phone number entered matches phone number on credit file.",
        //                     "name": "CFI_PNM_01",
        //                 },
        //                 {
        //                     "key": "noPhoneRecord",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "No Phone record located or poor match score.",
        //                     "name": "CFI_NPR_00",
        //                 },
        //                 {
        //                     "key": "ageGT21",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Calculated Age on the Credit File is 21 or greater.",
        //                     "name": "CFI_DOB_21",
        //                 },
        //                 {
        //                     "key": "ageGT18",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Calculated Age on the Credit File is 18 or greater.",
        //                     "name": "CFI_DOB_18",
        //                 },
        //                 {
        //                     "key": "ageGT19",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Calculated Age on the Credit File is 19 or greater.",
        //                     "name": "CFI_DOB_19",
        //                 },
        //                 {
        //                     "key": "creditFileHistoryGT6M",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Credit file history is equal to or greater than six months.",
        //                     "name": "CFI_CFH_02",
        //                 },
        //                 {
        //                     "key": "creditFileAgeGT6M",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Age of Credit File is greater than or equal six months.",
        //                     "name": "CFI_CFA_02",
        //                 },
        //                 {
        //                     "key": "ageMatch",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Input Age Matches the Calculated Age on the Credit File.",
        //                     "name": "CFI_DOB_01",
        //                 },
        //             ],
        //             "additionalOutputs": [],
        //         },
        //         {
        //             "key": "nameAssessment",
        //             "value": "Accept",
        //             "errors": [],
        //             "characteristics": [
        //                 {
        //                     "key": "nameAffiliation",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides the overall match result of the Name Affiliation Service. Service will return a 'match' if the input Name is found to be affiliated with another PII element.",
        //                     "name": "NA_OV999",
        //                 },
        //                 {
        //                     "key": "fullNameDOB",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Date of Birth is affiliated with the input Full Name.",
        //                     "name": "NA_FN999",
        //                 },
        //                 {
        //                     "key": "fullNameGovernmentId",
        //                     "value": "0",
        //                     "text": "notConfirmed",
        //                     "description": "Provides assessment as to whether the input Government ID is affiliated with the input Full Name.",
        //                     "name": "NA_GV999",
        //                 },
        //                 {
        //                     "key": "fullNameAddress",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Address is affiliated with the input Full Name.",
        //                     "name": "NA_AF999",
        //                 },
        //                 {
        //                     "key": "familyNameDOB",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Date of Birth is affiliated with the input Family Name.",
        //                     "name": "NA_LN999",
        //                 },
        //             ],
        //             "additionalOutputs": [],
        //         },
        //         {
        //             "key": "fraudInsightsAssessment",
        //             "value": "Accept",
        //             "errors": [],
        //             "characteristics": [
        //                 {
        //                     "key": "overallFraudRisk",
        //                     "value": "L",
        //                     "text": "Low",
        //                     "description": "Provides the overall risk level that the consumer is a victim of fraud.",
        //                     "name": "FVI_OV_S001",
        //                 },
        //                 {
        //                     "key": "fraudIdentityVictim",
        //                     "value": "N",
        //                     "text": "No",
        //                     "description": "Fraud victim warning is present and information is blocked due to identity theft.",
        //                     "name": "FVI_FV_S001",
        //                 },
        //                 {
        //                     "key": "fraudAlert",
        //                     "value": "N",
        //                     "text": "No",
        //                     "description": "Fraud victim alert is present in the database.",
        //                     "name": "FVI_FV_S005",
        //                 },
        //             ],
        //             "additionalOutputs": [],
        //         },
        //         {
        //             "key": "addressAssessment",
        //             "value": "Accept",
        //             "errors": [],
        //             "characteristics": [
        //                 {
        //                     "key": "dob",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Date of Birth is affiliated with the input Address.",
        //                     "name": "AA_BD999",
        //                 },
        //                 {
        //                     "key": "addressAffiliation",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Provides the overall match result of the Address Affiliation Service. Service will return a 'match' if the Address is found to be affiliated with another element of PII.",
        //                     "name": "AA_OV999",
        //                 },
        //                 {
        //                     "key": "familyName",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Family Name is affiliated with the input Address.",
        //                     "name": "AA_LN999",
        //                 },
        //                 {
        //                     "key": "fullName",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Full Name is affiliated with the input Address.",
        //                     "name": "AA_FN999",
        //                 },
        //                 {
        //                     "key": "governmentId",
        //                     "value": "N",
        //                     "text": "notConfirmed",
        //                     "description": "Provides assessment as to whether the input SIN is affiliated with the input Address.",
        //                     "name": "AA_GI999",
        //                 },
        //                 {
        //                     "key": "addressValid",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Address verification confirmation.",
        //                     "name": "AV_AV_S001",
        //                 },
        //                 {
        //                     "key": "addressValid",
        //                     "value": "Y",
        //                     "text": "Yes",
        //                     "description": "Indicates whether the provided address is valid.",
        //                     "name": "AI_OT_S001",
        //                 },
        //                 {
        //                     "key": "knownFraudFlag",
        //                     "value": "N",
        //                     "text": "No",
        //                     "description": "Flag that indicates whether the address is associated with known fraud records.",
        //                     "name": "AI_OV_S007",
        //                 },
        //                 {
        //                     "key": "trustConfirmation",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Indicates whether the input address is trusted.",
        //                     "name": "AT_ATRC001",
        //                 },
        //                 {
        //                     "key": "affiliationScore",
        //                     "value": "100",
        //                     "text": "Low Risk",
        //                     "description": "Risk score regarding address affiliation.",
        //                     "name": "AT_AA_S001",
        //                 },
        //                 {
        //                     "key": "affiliationConfirmation",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Indicates whether the address is affiliated with the set of input PII.",
        //                     "name": "AT_AA_S002",
        //                 },
        //             ],
        //             "additionalOutputs": [],
        //         },
        //         {
        //             "key": "dobAssessment",
        //             "value": "Accept",
        //             "errors": [],
        //             "characteristics": [
        //                 {
        //                     "key": "dobAffiliation",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides the overall match result of the Date of Birth Affiliation Service. Service will return a 'match' if the input Date of Birth is found to be affiliated with another PII element.",
        //                     "name": "DOBA_OV999",
        //                 },
        //                 {
        //                     "key": "address",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Address is affiliated with the input Date of Birth.",
        //                     "name": "DOBA_AA999",
        //                 },
        //                 {
        //                     "key": "fullName",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides assessment as to whether the input Name is affiliated with the input Date of Birth.",
        //                     "name": "DOBA_FN999",
        //                 },
        //                 {
        //                     "key": "governmentId",
        //                     "value": "0",
        //                     "text": "notConfirmed",
        //                     "description": "Provides assessment as to whether the input Social Security Number is affiliated with the input Date of Birth.",
        //                     "name": "DOBA_GI999",
        //                 },
        //             ],
        //             "additionalOutputs": [],
        //         },
        //         {
        //             "key": "deceasedAssessment",
        //             "value": "Accept",
        //             "errors": [],
        //             "characteristics": [
        //                 {
        //                     "key": "deceased",
        //                     "value": "N",
        //                     "text": "No",
        //                     "description": "Provides indication as to whether an individual is deceased.",
        //                     "name": "DE_IM_S001",
        //                 },
        //             ],
        //             "additionalOutputs": [],
        //         },
        //         {
        //             "key": "decisionAssistance",
        //             "value": "",
        //             "errors": [],
        //             "characteristics": [
        //                 {
        //                     "key": "addressAffiliation",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Provides the overall match result of the Address Affiliation Service. Service will return a 'match' if the Address is found to be affiliated with another element of PII.",
        //                     "name": "AA_OV999",
        //                 },
        //                 {
        //                     "key": "knownFraudFlag",
        //                     "value": "N",
        //                     "text": "No",
        //                     "description": "Flag that indicates whether the address is associated with known fraud records.",
        //                     "name": "AI_OV_S007",
        //                 },
        //                 {
        //                     "key": "dobAffiliation",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides the overall match result of the Date of Birth Affiliation Service. Service will return a 'match' if the input Date of Birth is found to be affiliated with another PII element.",
        //                     "name": "DOBA_OV999",
        //                 },
        //                 {
        //                     "key": "deceased",
        //                     "value": "N",
        //                     "text": "No",
        //                     "description": "Provides indication as to whether an individual is deceased.",
        //                     "name": "DE_IM_S001",
        //                 },
        //                 {
        //                     "key": "creditFileAgeGT3Y",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Age of Credit file is greater than or equal to three years.",
        //                     "name": "CFI_CFA_04",
        //                 },
        //                 {
        //                     "key": "mismatchSIN",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Social Insurance Number - Mismatch.",
        //                     "name": "CFI_SIN_MM",
        //                 },
        //                 {
        //                     "key": "adequateMatch",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Credit File located - Adequate match.",
        //                     "name": "CFI_CFL_02",
        //                 },
        //                 {
        //                     "key": "noPhoneRecord",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "No Phone record located or poor match score.",
        //                     "name": "CFI_NPR_00",
        //                 },
        //                 {
        //                     "key": "ageGT21",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Calculated Age on the Credit File is 21 or greater.",
        //                     "name": "CFI_DOB_21",
        //                 },
        //                 {
        //                     "key": "ageGT18",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Calculated Age on the Credit File is 18 or greater.",
        //                     "name": "CFI_DOB_18",
        //                 },
        //                 {
        //                     "key": "ageGT19",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Calculated Age on the Credit File is 19 or greater.",
        //                     "name": "CFI_DOB_19",
        //                 },
        //                 {
        //                     "key": "creditFileHistoryGT6M",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Credit file history is equal to or greater than six months.",
        //                     "name": "CFI_CFH_02",
        //                 },
        //                 {
        //                     "key": "creditFileAgeGT6M",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Age of Credit File is greater than or equal six months.",
        //                     "name": "CFI_CFA_02",
        //                 },
        //                 {
        //                     "key": "ageMatch",
        //                     "value": "Y",
        //                     "text": "confirmed",
        //                     "description": "Input Age Matches the Calculated Age on the Credit File.",
        //                     "name": "CFI_DOB_01",
        //                 },
        //                 {
        //                     "key": "nameAffiliation",
        //                     "value": "1",
        //                     "text": "confirmed",
        //                     "description": "Provides the overall match result of the Name Affiliation Service. Service will return a 'match' if the input Name is found to be affiliated with another PII element.",
        //                     "name": "NA_OV999",
        //                 },
        //                 {
        //                     "key": "overallFraudRisk",
        //                     "value": "L",
        //                     "text": "Low",
        //                     "description": "Provides the overall risk level that the consumer is a victim of fraud.",
        //                     "name": "FVI_OV_S001",
        //                 },
        //                 {
        //                     "key": "fraudIdentityVictim",
        //                     "value": "N",
        //                     "text": "No",
        //                     "description": "Fraud victim warning is present and information is blocked due to identity theft.",
        //                     "name": "FVI_FV_S001",
        //                 },
        //             ],
        //             "additionalOutputs": [],
        //         },
        //     ],
        // };

        // const questions = data?.knowledgeBasedVerificationQuestions[0]?.questionnaire?.questions
        // return {
        //     success: true,
        //     questions
        // }
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default equifaxGetKba;
