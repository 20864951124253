import { Center, Text, VStack, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import { ReactComponent as PlansImage } from '../assets/svg/illustration-select.svg';
import CreditLogos from './CreditLogos';

import { PRICING_OPTIONS, LINK } from '../constants';

function WarrantySplash({ minimal }) {
  const { i18n, t } = useTranslation('global');
  let french = i18n.language === 'fr' ? true : false;

  // in homepage modal and warranty landing page
  return (
    <VStack>
      {/* <Text
        color="primary.500"
        fontWeight="bold"
        fontSize="xs"
        mt={5}
        align="center"
      >
        {t('compareOurPlansCapital')}
      </Text> */}
      {!minimal && (
        <Text fontWeight="bold" fontSize="xl" align="center" my={5}>
          {t('EEZEWarrantyPlansCapital')}
        </Text>
      )}

      <Center>
        <PlansImage width={'80%'} />
      </Center>
      {!minimal && (
        <>
    
        <HStack w="100%">
          <VStack fontSize="xs" fontWeight="bold">
            {french ? (
              <>
                <Text as="u">{PRICING_OPTIONS.addons.B1.nameFr}</Text>
                <Text>
                  {PRICING_OPTIONS.addons.B1.termFr}{' '}
                  {PRICING_OPTIONS.addons.B1.price1Fr}
                </Text>
                <Text>
                  {PRICING_OPTIONS.addons.B2.termFr}{' '}
                  {PRICING_OPTIONS.addons.B2.price1Fr}
                </Text>
                <Text>
                  {PRICING_OPTIONS.addons.B3.termFr}{' '}
                  {PRICING_OPTIONS.addons.B3.price1Fr}
                </Text>
              </>
            ) : (
              <>
                <Text as="u">{PRICING_OPTIONS.addons.B1.name}</Text>
                <Text>
                  {PRICING_OPTIONS.addons.B1.term}{' '}
                  {PRICING_OPTIONS.addons.B1.price1}
                </Text>
                <Text>
                  {PRICING_OPTIONS.addons.B2.term}{' '}
                  {PRICING_OPTIONS.addons.B2.price1}
                </Text>
                <Text>
                  {PRICING_OPTIONS.addons.B3.term}{' '}
                  {PRICING_OPTIONS.addons.B3.price1}
                </Text>
              </>
            )}
          </VStack>

          <VStack fontSize="xs" fontWeight="bold" flex="1">
            <Text color="white">{t('features')}</Text>

            {french ? (
              <>
                <Text align="center">
                  {PRICING_OPTIONS.addons.B1.feature1Fr}
                </Text>
                <Text align="center">
                  {PRICING_OPTIONS.addons.B1.feature2Fr}
                </Text>
                <Text align="center">
                  {PRICING_OPTIONS.addons.B1.feature3Fr}
                </Text>
              </>
            ) : (
              <>
                <Text align="center">{PRICING_OPTIONS.addons.B1.feature1}</Text>
                <Text align="center">{PRICING_OPTIONS.addons.B1.feature2}</Text>
                <Text align="center">{PRICING_OPTIONS.addons.B1.feature3}</Text>
              </>
            )}
          </VStack>
        </HStack>
      <br />
      <Text textAlign="center">
        <a
          href={LINK.warrantyPlansUrl}
          target="_blank"
          rel="noopener noreferrer"
          >
          {t('moreInfo')}
          <ExternalLinkIcon ml="5px" />
        </a>
      </Text>
          </>
        )}
      <br />
      <Text>{t('payInUpTo24MonthlyInstallments')}</Text>

      <CreditLogos />
    </VStack>
  );
}

export default WarrantySplash;
