import {
    createContext,
    useState,
    useRef,
    useEffect
} from 'react';
// import LogRocket from 'logrocket';
import {
    getAuth, onAuthStateChanged
} from 'firebase/auth';
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from '../constants';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const isMounted = useRef(true);

    const auth = getAuth();

    useEffect(() => {

        if (!auth.currentUser) return;
        const unsubscribe = onSnapshot(doc(db, 'users', auth.currentUser.uid), doc => {
            // LogRocket.identify(auth.currentUser.uid, {
            //     // name: fullUserData.firstName + ' ' + fullUserData.lastName,
            //     email: auth.currentUser.email,
            //   });
            setUserData(doc.data());
        });
        return unsubscribe;
    }, [auth.currentUser]);

    useEffect(() => {
        if (isMounted) {
        
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const docRef = doc(db, "users", auth.currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUserData(docSnap.data())
                    setLoggedIn(true);
                } else {
                    // console.log("first login")
                    setLoggedIn(true);
                }
            } else {
                // console.log('there is no user');
                setUserData(null);
                setLoggedIn(false);
            }
            //   setIsLoading(false);
            setCheckingStatus(false);
        });
        return unsubscribe;
    }
    return () => {
        isMounted.current = false
      }
    }, [auth, isMounted]);

    return (
        <DataContext.Provider value={{
            loggedIn, checkingStatus, userData
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;