import { useState, useEffect, useContext } from 'react';
import {
  updateDoc,
  doc,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  getDoc,
  addDoc,
} from 'firebase/firestore';
import Select from 'react-select';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AlertDescription,
  AlertTitle,
  AlertIcon,
  Alert,
  Divider,
  // Badge,
  Box,
  // Card,
  Center,
  Button,
  // Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  SimpleGrid,
  Stack,
  Spinner,
  Heading,
  Image,
  Text,
  useToast,
  useDisclosure,
  ModalBody,
} from '@chakra-ui/react';
import {
  ChevronRightIcon,
  // CloseIcon,
  AddIcon,
  MinusIcon,
} from '@chakra-ui/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { db, LISTING, isDev, eligibleWarrantyProvinces } from '../constants';
import DataContext from '../context/DataContext';
import ListingCard from '../components/ListingCard';
import ListingCardWarranty from '../components/ListingCardWarranty';
import ListingCardSeller from '../components/ListingCardSeller';
import ListingCardBuyer from '../components/ListingCardBuyer';
import CreateVehicle from '../components/CreateVehicle';
import deleteListing from '../services/deleteListing';
import SpinnerPage from '../components/SpinnerPage';
import WarrantySplash from '../components/WarrantySplash';
import InviteSellerModal from '../components/InviteSellerModal';
import Header from '../components/Header';
import AppBody from '../components/AppBody';
import IDCard from '../components/IDCard';
import logoImage from '../assets/logo.png';
// import { ReactComponent as PlansImage } from '../assets/svg/illustration-select.svg';
import { ReactComponent as Main } from '../assets/svg/illustration-verified.svg';
// import OrDivider from '../components/OrDivider';

function Homepage() {
  const { t } = useTranslation('global');
  const { userData } = useContext(DataContext);
  const [queryParameters] = useSearchParams();
  const toast = useToast();
  const [buttonsIsLoading, setButtonsIsLoading] = useState(false);
  const [loadingListings, setLoadingListings] = useState(true);
  const [listings, setListings] = useState(null);
  const [sellListings, setSellListings] = useState(null);
  const [buyListings, setBuyListings] = useState(null);
  const [favoriteListings, setFavoriteListings] = useState(null);
  const [warrantyListings, setWarrantyListings] = useState(null);
  const [completeListings, setCompleteListings] = useState(null);
  const [EEZEIDOptions, setEEZEIDOptions] = useState(null);
  const [selectedEEZEID, setSelectedEEZEID] = useState(null);
  const [selectedEEZEListing, setSelectedEEZEListing] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();
  const {
    isOpen: isOpenWarranty,
    onOpen: onOpenWarranty,
    onClose: onCloseWarranty,
  } = useDisclosure();
  const {
    isOpen: isOpenCreateVehicle,
    onOpen: onOpenCreateVehicle,
    onClose: onCloseCreateVehicle,
  } = useDisclosure();

  const navigate = useNavigate();

  const sortArray = array => {
    // descending order of postedAt
    const sortedArray = array.sort((a, b) => {
      return (
        new Date(b.postedAt || b.createdAt) -
        new Date(a.postedAt || a.createdAt)
      );
    });
    return sortedArray;
  };

  useEffect(() => {
    const fetchUserListings = async () => {
      // first: if uid in params, add it to favListings
      if (!userData) return;

      // array of favorites uid strings
      const myFavorites = userData.favoriteListings || [];

      let uid;
      if (queryParameters.get('uid')) {
        uid = queryParameters.get('uid');
      }
      if (uid) {
        const arrayContainsUid = myFavorites.indexOf(uid) > -1;
        if (!arrayContainsUid) {
          // update db
          myFavorites.push(uid);
          updateDoc(doc(db, 'users', userData.uid), {
            favoriteListings: myFavorites,
          });
        }
      }

      let ref;
      if (queryParameters.get('ref')) {
        ref = queryParameters.get('ref');
        // ex /?ref=garage1
      }
      let aref;
      if (queryParameters.get('aref')) {
        aref = queryParameters.get('aref');
        // ex /?aref=affiliate1
      }
      if (queryParameters.get('a')) {
        aref = queryParameters.get('a');
        // ex /?a=affiliate1
      }
      let iref;
      if (queryParameters.get('iref')) {
        iref = queryParameters.get('iref');
        // ex /?iref=influencer1
      }
      if (queryParameters.get('i')) {
        iref = queryParameters.get('i');
        // ex /?i=influencer1
      }
      // add ref, aref or iref to user
      if (ref) {
        updateDoc(doc(db, 'users', userData.uid), {
          garageRef: ref,
        });
      }
      if (aref) {
        updateDoc(doc(db, 'users', userData.uid), {
          affiliateRef: aref,
        });
      }
      if (iref) {
        updateDoc(doc(db, 'users', userData.uid), {
          influencerRef: iref,
        });
      }

      // if uid as well as ref, also add ref, aref or iref to listing
      if (uid) {
        if (ref) {
          updateDoc(doc(db, 'listings', uid), {
            garageRef: ref,
          });
        }
        if (aref) {
          updateDoc(doc(db, 'listings', uid), {
            affiliateRef: aref,
          });
        }
        if (iref) {
          updateDoc(doc(db, 'listings', uid), {
            influencerRef: iref,
          });
        }
      }

      // array of objects of my Favorite listings
      let myFavoriteListings = [];

      // array of objects of my SELL listings
      let mySellListings = [];

      // array of objects of my BUY listings
      // which should be removed from the favorites
      let myBuyListings = [];

      // buy or sell listings that are status completed
      let myCompletedListings = [];

      // vehicles bought, status completed or vehicle listings only
      let myWarrantyListings = [];

      // 1) get listings that this user has in his favorites
      if (myFavorites.length !== 0) {
        // retrieve each one, push to array
        myFavorites.forEach(async uid => {
          const docRef = doc(db, 'listings', uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            // exclude those where user is the buyer, we have those below
            if (docSnap.data().buyerUid !== userData.uid) {
              return myFavoriteListings.push(docSnap.data());
            }
          }
        });
      }

      // 2) get users listings
      if (userData.uid) {
        // uid is not present on first loan
        const listingsRef = collection(db, 'listings');
        const q = query(
          listingsRef,
          where('sellerUid', '==', userData.uid),
          orderBy('createdAt', 'desc')
        );
        const querySnap = await getDocs(q);
        querySnap.forEach(doc => {
          return mySellListings.push(doc.data());
        });

        // 3) get listings where user is the buyer
        const q2 = query(
          listingsRef,
          where('buyerUid', '==', userData.uid),
          orderBy('createdAt', 'desc')
        );
        const querySnap2 = await getDocs(q2);
        querySnap2.forEach(doc => {
          // if it was in favorites, remove it
          const index = myFavorites.indexOf(doc.id);
          if (index !== -1) {
            myFavorites.splice(index, 1);
          }
          // and push to my buy listings
          return myBuyListings.push(doc.data());
        });

        // Remove objects with status "COMPLETED" from mySellListings and move to myCompletedListings
        for (let i = mySellListings.length - 1; i >= 0; i--) {
          if (mySellListings[i].status === 'COMPLETED') {
            myCompletedListings.push(mySellListings.splice(i, 1)[0]);
          }
        }

        // Remove objects with status "COMPLETED" from myBuyListings and move to myWarrantyListings
        for (let i = myBuyListings.length - 1; i >= 0; i--) {
          if (myBuyListings[i].status === 'COMPLETED') {
            myWarrantyListings.push(myBuyListings.splice(i, 1)[0]);
          }
        }

        // remove status "PRIVATE", type "VEHICLE" from mySellListings, to my Warranty
        for (let i = mySellListings.length - 1; i >= 0; i--) {
          if (
            mySellListings[i].status === 'PRIVATE' &&
            mySellListings[i].type === 'VEHICLE'
          ) {
            myWarrantyListings.push(mySellListings.splice(i, 1)[0]);
          }
        }
      }
      setSellListings(sortArray(mySellListings));
      setBuyListings(sortArray(myBuyListings));
      setWarrantyListings(sortArray(myWarrantyListings));
      setFavoriteListings(sortArray(myFavoriteListings));
      setCompleteListings(sortArray(myCompletedListings));
      // update favorites that may have changed
      if (userData?.uid) {
        updateDoc(doc(db, 'users', userData?.uid), {
          favoriteListings: myFavorites,
        }).catch(e => console.log(e));
      }

      setLoadingListings(false);
    };

    fetchUserListings();
  }, [userData, queryParameters]);

  const onClickSearchByEEZEID = async () => {
    // get eeze ids from db for buyer button add
    const docRef = doc(db, 'eezeid', 'match');
    const docSnap = await getDoc(docRef);
    const allIDs = docSnap.data();
    const convertedArray = Object.entries(allIDs).map(([label, value]) => ({
      value,
      label: label.toUpperCase(),
    }));
    // console.log(convertedArray);
    // converts object to this format array for react-select
    // let eezeIDs = [
    //   { value: 'edV5f4Tt0dLDICyI8cgU', label: '0a09fnk' },
    //   { value: '65lXu13CJrAUledarWqt', label: 'ouxs1xu' },
    //   { value: 'AJ4JlscAXiTIvOR8UG5W', label: 'qxnrwfh' },
    // ];

    await setEEZEIDOptions(convertedArray);
    // now that values are in state, open modal
    onOpen();
  };

  const handleEEZEIDInputChange = async newValue => {
    if (!newValue) return;
    // get uid from eeze id
    const uid = newValue.value;
    // fetch selected listing
    const docRef = doc(db, 'listings', uid);
    const docSnap = await getDoc(docRef);
    // console.log(docSnap.data())
    const foundListing = docSnap.data();
    setSelectedEEZEID(newValue);
    if (foundListing) {
      setSelectedEEZEListing(foundListing);
    } else {
      setSelectedEEZEListing('Listing not found');
    }
  };

  const onCreateVehicleListing = async ({ warrantyOnly }) => {
    setButtonsIsLoading(true);

    try {
      const docRef = await addDoc(collection(db, 'listings'), {
        createdAt: new Date().toString(),
        affiliateRef: userData?.affiliateRef || '',
        garageRef: userData?.garageRef || '',
        influencerRef: userData?.influencerRef || '',
        sellerUid: userData.uid,
        language: 'EN',
        sellerName:
          userData.firstName + ' ' + userData.lastName.substring(0, 1) + '.',
        sellerFullName: userData?.firstName + ' ' + userData?.lastName,
        sellerEmail: userData?.email,
        sellerCell: userData?.cell,
        sellerLocation: !userData.address
          ? ''
          : userData?.address?.city +
            ', ' +
            userData?.address?.province.toUpperCase(),
        sellerPhotoURL: userData?.photoURL,
        status: warrantyOnly ? LISTING.STATUS.PRIVATE : LISTING.STATUS.DRAFT,
        type: LISTING.TYPE.VEHICLE,
        province: !userData.address
          ? ''
          : userData?.address?.province.toLowerCase(),

        year: '',
        make: '',
        model: '',
        style: '',
        vin: '',
        odometer: '',
        // odometerType: userData?.address?.country === 'ca' ? 'km' : 'km',
        odometerType: 'km',
        condition: '',
        title: '',
        subtitle: '',
        // currency: userData?.address?.country === 'ca' ? 'CAD' : 'CAD',
        currency: 'CAD',
        askingPrice: '',
        description: '',
        notes: '',
        otherMarketplaceURL: '',
        potentialBuyers: '',
      });

      navigate('/edit/' + docRef.id);
    } catch (error) {
      setButtonsIsLoading(false);
      // console.error(error);
      toast({
        title: 'Could not create listing',
        description: isDev ? JSON.stringify(error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };

  // const onCreateProductListing = () => {
  //   window.alert('Coming Soon');
  //   // setButtonsIsLoading(true);
  // };

  const onDelete = async listing => {
    setLoadingListings(true);
    const response = await deleteListing(listing);
    // console.log(('delete listing', response));
    if (response.success) {
      const updatedListings = listings.filter(
        remainingListing => remainingListing.uid !== response.deletedListing.uid
      );

      setListings(updatedListings);
      setLoadingListings(false);
      // deleteShortUrl();
      toast({
        title: t('listingDeleted'),
        isClosable: true,
      });
    } else {
      setLoadingListings(false);
    }
  };

  const onEdit = (listing, page) =>
    navigate(`/edit/${listing.uid}?t=${page || 0}`);

  const renderListings = listingArray => {
    return listingArray.map((listing, i) => {
      if (listing.sellerUid === userData.uid) {
        return (
          <ListingCardSeller
            listing={listing}
            listingUid={listing.uid}
            key={i}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        );
      } else {
        return (
          <ListingCardBuyer
            listing={listing}
            key={i}
            // onEdit={onEdit}
            // onDelete={onDelete}
          />
        );
      }
    });
  };
  const renderWarrantyListings = listingArray => {
    return listingArray.map((listing, i) => {
      return (
        <ListingCardWarranty
          listing={listing}
          listingUid={listing.uid}
          key={i}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      );
    });
  };

  const renderSelectedEEZEListing = () => {
    // if listing was not found, invalid EEZE id
    if (selectedEEZEListing === 'Listing not found') {
      return (
        <Heading textAlign="center" borderWidth={2} py={5}>
          {t('listingNotFound')}
        </Heading>
      );
    }
    // if your own listing
    if (selectedEEZEListing.sellerUid === userData.uid) {
      return (
        <Heading textAlign="center" borderWidth={2} py={5}>
          {t('yourOwnListings')}
        </Heading>
      );
    }
    // cannot select a listing that is still a draft
    if (selectedEEZEListing.status === LISTING.STATUS.DRAFT) {
      return (
        <Heading textAlign="center" borderWidth={2} py={5}>
          {t('privateListings')}
        </Heading>
      );
    }
    // cannot select a listing that is only a vehicle
    if (selectedEEZEListing.status === LISTING.STATUS.PRIVATE) {
      return (
        <Heading textAlign="center" borderWidth={2} py={5}>
          {t('privateListings')}
        </Heading>
      );
    }
    // cannot select a listing that has a buyerUID
    if (selectedEEZEListing.buyerUid) {
      return (
        <Heading textAlign="center" borderWidth={2} py={5}>
          {t('listingNotAcceptingNewBuyers')}
        </Heading>
      );
    }
    return (
      <ListingCard
        noTitleLink={true}
        listing={selectedEEZEListing}
        id={selectedEEZEListing.uid}
        key={selectedEEZEListing.uid}
      />
    );
  };

  if (!userData) {
    return <SpinnerPage />;
  }
  if (loadingListings) {
    return <SpinnerPage />;
  }
  let eligibleProvince = true;
  if (userData?.address?.province) {
    if (
      !eligibleWarrantyProvinces.includes(
        userData?.address?.province.toLowerCase()
      )
    ) {
      eligibleProvince = false;
    }
  }

  return (
    <>
      <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody pt={10}>
            <ModalCloseButton />
            <Text textAlign="center" mb={5}>
              {t('enterEEZEId')}
            </Text>
            <Text color="primary.800" mb={2} textAlign="center">
              {t('ifSellerHasGivenYouAnEEZEId')}
            </Text>
            {!EEZEIDOptions ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <Select
                value={selectedEEZEID}
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    borderColor: 'inherit',
                    borderRadius: '10px',
                    height: '3rem',
                  }),
                }}
                placeholder=""
                onChange={handleEEZEIDInputChange}
                options={EEZEIDOptions}
                isClearable={true}
                isSearchable={true}
              />
            )}
            <br />
            {selectedEEZEListing && renderSelectedEEZEListing()}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              // size="sm"
              w="full"
              // colorScheme={'primary'}
              mb={5}
              variant="link"
            >
              {t('closeCapital')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal onClose={onCloseShare} size={'xl'} isOpen={isOpenShare}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <InviteSellerModal url={'listing.shortUrl'} />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onCloseShare}
              w="full"
              variant="link"
              // size="sm"
              // colorScheme={'primary'}
              mb={5}
            >
              {t('closeCapital')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal onClose={onCloseWarranty} size={'xl'} isOpen={isOpenWarranty}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalCloseButton />
            <br />
            <WarrantySplash />

            <Button
              my={10}
              onClick={() =>
                onCreateVehicleListing({
                  warrantyOnly: true,
                })
              }
              w="full"
              colorScheme={'primary'}
            >
              {t('continueCapital')}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCreateVehicle} onClose={onCloseCreateVehicle}>
        <ModalOverlay />
        <ModalContent p={5}>
          <CreateVehicle user={userData} />
          <ModalFooter>
            <Button
              mt={10}
              w="full"
              variant="link"
              // size="sm"
              onClick={onCloseCreateVehicle}
            >
              {t('closeCapital')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Header />

      <AppBody>
        <Stack p={0} spacing={2}>
          {userData.stageOneVerified && (
            <>
              {userData?.stageTwoVerified ? (
                <Center>
                  <IDCard userData={userData} flat={true} showWallet={true} />
                </Center>
              ) : (
                <Center>
                  <IDCard userData={userData} flat={true} showWallet={false} />
                </Center>
              )}
            </>
          )}

          {/* <Button onClick={() => navigate('/user/' + userData.uid)}>
            to user page
          </Button> */}

          {!userData?.stageOneVerified ? (
            <>
              <Text
                pt={2}
                fontSize="2xl"
                textAlign="center"
                lineHeight={1.2}
                fontWeight="bold"
                bgGradient="linear(to-l, primary.800,primary.300)"
                bgClip="text"
              >
                {t('welcomeTo')}
              </Text>
              <Center mb={5}>
                <Image
                  src={logoImage}
                  alt="Logo"
                  width="40%"
                  maxW={'500px'}
                  objectFit="cover"
                />
              </Center>
              {!userData?.warrantySignupOnly && (
                <>
                  <Text textAlign="center" mb={5}>
                    {t('aFreePlatformToProtectYourselfFromFraud')}
                  </Text>
                  <Center my={2}>
                    <Main width="50%" />
                  </Center>
                  <Center>
                    <Text textAlign="center" flex={1}>
                      {t('toBuyOrSellYouMustCreateProfile')}
                    </Text>
                  </Center>

                  <Button
                    onClick={() => navigate('/verify')}
                    bgColor="primary.500"
                    color="white"
                    size="lg"
                    w="100%"
                    my={2}
                  >
                    {userData.emailIsVerified || userData.phoneIsSmsVerified ? (
                      <>
                        {t('continueVerification')} <ChevronRightIcon />
                      </>
                    ) : (
                      <>
                        {t('getVerified')} <ChevronRightIcon />
                      </>
                    )}
                  </Button>
                </>
              )}

              {userData?.warrantySignupOnly && (
                <>
                  <Center>
                    <Text textAlign="center" flex={1}>
                      {t('protectYourVehicleWithAnEEZEPlan')}
                    </Text>
                  </Center>

                  {warrantyListings && warrantyListings.length === 0 && (
                    <>
                      <WarrantySplash minimal={true} />

                      <Center>
                        <Text textAlign="center">
                          {t('addYourVehicleToGetStarted')}
                        </Text>
                      </Center>
                    </>
                  )}

                  {/* <Button
                    onClick={onOpenCreateVehicle}
                    colorScheme="primary"
                    my={5}
                  >
                    {t('addVehicle')}
                  </Button> */}
                </>
              )}

              {warrantyListings && warrantyListings.length > 0 && (
                <>
                  <Text size="sm" as="i" color="grey">
                    {t('myVehicles')}
                  </Text>
                  <SimpleGrid columns={[1, 1, 2]}>
                    {renderWarrantyListings(warrantyListings)}
                  </SimpleGrid>
                </>
              )}

              {/* dislay button after vehicles in warranty only mode */}
              {userData?.warrantySignupOnly && (
                <Button
                  onClick={onOpenCreateVehicle}
                  colorScheme="primary"
                  my={5}
                >
                  {t('addVehicle')}
                </Button>
              )}
            </>
          ) : (
            <>
              {/* user has completed stageOneVerify */}
              {sellListings.length === 0 &&
                buyListings.length === 0 &&
                completeListings.length === 0 &&
                favoriteListings.length === 0 && (
                  <Box textAlign="center" py={2}>
                    <Text
                      fontSize="2xl"
                      lineHeight={1.2}
                      fontWeight="bold"
                      bgGradient="linear(to-l, primary.800,primary.300)"
                      bgClip="text"
                    >
                      {t('selectAnOption')}
                    </Text>
                  </Box>
                )}

              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton pl={1}>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="lg"
                          fontWeight="bold"
                        >
                          {t('sellWithEEZE')}
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Center>
                          <Text textAlign="center" mx={3}>
                            {t('validateYourVehicleForSaleJUnlockingOptions')}
                          </Text>
                        </Center>
                        <Center>
                          <Button
                            my={5}
                            w="100%"
                            maxW="400px"
                            colorScheme={'primary'}
                            isLoading={buttonsIsLoading}
                            onClick={onCreateVehicleListing}
                            // leftIcon={<AddIcon />}
                          >
                            {t('addVehicleListingToSell')}
                          </Button>
                        </Center>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>

              {sellListings && sellListings.length > 0 ? (
                <>
                  {/* <Center mt={3}> */}
                  <Text size="sm" as="i" color="grey">
                    {t('myListings')}
                  </Text>
                  {/* </Center> */}
                  <SimpleGrid columns={[1, 1, 2]}>
                    {renderListings(sellListings)}
                  </SimpleGrid>
                </>
              ) : (
                <>
                  {/* <Center>
                    <Text textAlign="center" mx={3}>
                      {t('validateYourVehicleForSaleJUnlockingOptions')}
                    </Text>
                  </Center>
                  <br /> */}
                </>
              )}

              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton pl={1}>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="lg"
                          fontWeight="bold"
                        >
                          {t('buyWithEEZE')}
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Center>
                          <Text textAlign="center" mx={3}>
                            {t('addAVehicleYouWantToBuyOrInviteASeller')}{' '}
                            {t('buyWithA90DayWarranty')}
                          </Text>
                        </Center>

                        <Center>
                          <Button
                            w="100%"
                            my={5}
                            maxW="400px"
                            colorScheme={'primary'}
                            onClick={onOpenShare}
                          >
                            {t('inviteASellerToEEZE')}
                          </Button>
                        </Center>
                        {/* <Divider borderWidth={1} my={2} /> */}
                        <Center>
                          <Button
                            colorScheme={'primary'}
                            w="100%"
                            mb={5}
                            maxW="400px"
                            onClick={onClickSearchByEEZEID}
                          >
                            {t('findByEEZEId')}
                          </Button>
                        </Center>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>

              {buyListings && buyListings.length > 0 ? (
                <>
                  {/* <Center mt={3}> */}
                  <Text size="sm" as="i" color="grey">
                    {t('buyListings')}
                  </Text>
                  {/* </Center> */}
                  <SimpleGrid columns={[1, 1, 2]}>
                    {renderListings(buyListings)}
                  </SimpleGrid>
                </>
              ) : (
                <>
                  {/* <Center>
                    <Text textAlign="center" mx={3}>
                      {t('addAVehicleYouWantToBuyOrInviteASeller')}{' '}
                      {t('buyWithA90DayWarranty')}
                    </Text>
                  </Center>
                  <br /> */}
                </>
              )}

              {favoriteListings && favoriteListings.length > 0 && (
                <>
                  {/* <Center mt={3}> */}
                  <Text size="sm" as="i" color="grey">
                    {t('favorites')}
                  </Text>
                  {/* </Center> */}
                  <SimpleGrid columns={[1, 1, 2]}>
                    {renderListings(favoriteListings)}
                  </SimpleGrid>
                </>
              )}

              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <Divider mb={2} />
                      <AccordionButton pl={1}>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontSize="lg"
                          fontWeight="bold"
                        >
                          {t('getAWarrantyWithEEZE')}
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        {eligibleProvince ? (
                          <>
                            <Center>
                              <Text mb={5} align="center">
                                {t('addYourVehicleAndPurchaseWarranty')}
                              </Text>
                            </Center>
                            <Center>
                              <Button
                                colorScheme={'primary'}
                                w="100%"
                                maxW="400px"
                                onClick={onOpenCreateVehicle}
                                //  leftIcon={<AddIcon />}
                              >
                                {t('addVehicle')}
                              </Button>
                            </Center>
                          </>
                        ) : (
                          <Alert
                            status="warning"
                            variant="left-accent"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                          >
                            <AlertIcon mr={0} />
                            <AlertTitle mt={1} mb={1} fontSize="lg">
                              {t('wereSorry')}
                            </AlertTitle>
                            <AlertDescription maxWidth="lg">
                              {t('onlyAvailableOntarioAndQuebec')}
                            </AlertDescription>
                          </Alert>
                        )}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>

              {warrantyListings && warrantyListings.length > 0 ? (
                <>
                  <Text size="sm" as="i" color="grey">
                    {t('myVehicles')}
                  </Text>
                  <SimpleGrid columns={[1, 1, 2]}>
                    {renderWarrantyListings(warrantyListings)}
                  </SimpleGrid>
                </>
              ) : (
                <>
                  {!buyListings && !sellListings && (
                    <Center>
                      <Text textAlign="center" mx={3}>
                        {t('buyAnExtendedWarrantyWithouLeavingDriveway')}
                      </Text>
                    </Center>
                  )}
                  <br />
                </>
              )}

              {completeListings && completeListings.length > 0 && (
                <>
                  <br />
                  <Text size="sm" as="i" color="grey">
                    {t('completedTransactions')}
                  </Text>
                  <SimpleGrid columns={[1, 1, 2]}>
                    {renderListings(completeListings)}
                  </SimpleGrid>
                </>
              )}
            </>
          )}
        </Stack>
      </AppBody>
    </>
  );
}

export default Homepage;
