import {
  // Box,
  // Card,
  Center,
  Button,
  // Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlansImage } from '../assets/svg/illustration-select.svg';
import Header from '../components/Header';
import AppBody from '../components/AppBody';

function PaymentSuccess() {
  const { t } = useTranslation('global');

  const navigate = useNavigate();

  // console.log(useSearchParams);
  return (
    <>
      <Header />

      <AppBody>
        <div>
          <Center mt={20}>
            <PlansImage width={'60%'} />
          </Center>

          <Heading textAlign="center">Thank you for your payment</Heading>
          <Text textAlign="center" fontSize="lg">
            We are processing your EEZE Warranty
          </Text>

          <Center>
            <Button
              colorScheme="primary"
              // w="full"
              mt={20}
              size="lg"
              onClick={() => navigate('/')}
            >
              Go to Dashboard
            </Button>
          </Center>
        </div>
      </AppBody>
    </>
  );
}

export default PaymentSuccess;
