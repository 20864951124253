import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Box,
  Container,
  Center,
  Select,
  Spinner,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  db,
  isDev,
  allProvinces,
  eligibleWarrantyProvinces,
} from '../constants';
import vinlocation from '../assets/vinlocation.png';
import CARS from '../services/carInfo';
import decodeVIN from '../services/decodeVIN';

function CreateVehicle(props) {
  const { i18n, t } = useTranslation('global');
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [decoding, setDecoding] = useState(false);
  const [viewEnterManual, setViewEnterManual] = useState(false);
  const [listingUid, setListingUid] = useState();
  const { user } = props;
  const [formData, setFormData] = useState({
    vin: '',
    odometerType: 'km',
    currency: 'CAD',
    province: user.address ? user.address?.province.toLowerCase() : '',
  });

  const createListing = async () => {
    // create vehicle with data in forms
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, 'listings'), {
        ...formData,
        createdAt: new Date().toString(),
        affiliateRef: user?.affiliateRef || '',
        garageRef: user?.garageRef || '',
        influencerRef: user?.influencerRef || '',
        sellerUid: user.uid,
        language: i18n?.resolvedLanguage && i18n.resolvedLanguage.toUpperCase(),
        sellerName: user.firstName + ' ' + user.lastName.substring(0, 1) + '.',
        sellerFullName: user.firstName + ' ' + user.lastName,
        sellerEmail: user?.email,
        sellerCell: user?.cell,
        sellerLocation: user.address
          ? user?.address?.city + ', ' + user.address?.province.toUpperCase()
          : '',
        sellerPhotoURL: user?.photoURL || '',
        status: 'PRIVATE', // vehicleOnly ? LISTING.STATUS.PRIVATE : LISTING.STATUS.DRAFT,
        type: 'VEHICLE', // LISTING.TYPE.VEHICLE,
      });
      setListingUid(docRef.id);
      navigate('/vehicle-addons/' + docRef.id);
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Could not create vehicle, please try again',
        description: isDev ? JSON.stringify(error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onChange = e => {
    let boolean = null;
    let value = e.target.value;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }

    setFormData(prevState => ({
      ...prevState,
      title: formData.year + ' ' + formData.make + ' ' + formData.model,
      [e.target.id]: boolean ?? value,
    }));
  };

  const onChangeVIN = e => {
    if (e.target.value !== '') {
      const regEx = /^[0-9a-zA-Z]+$/;
      if (!e.target.value.match(regEx)) return;
      if (e.target.value.includes(' ')) return;
      if (e.target.value.includes('I')) return;
      if (e.target.value.includes('i')) return;
      if (e.target.value.includes('O')) return;
      if (e.target.value.includes('o')) return;
      if (e.target.value.includes('Q')) return;
      if (e.target.value.includes('q')) return;
      if (e.target.value.length > 17) return;
    }
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value.toUpperCase(),
    }));
  };

  const insertRandomVIN = () => {
    const index = Math.floor(Math.random() * CARS.RANDOM_VINS.length);
    setFormData(prevState => ({
      ...prevState,
      vin: CARS.RANDOM_VINS[index],
    }));
  };

  const onClickDecodeVIN = async (vin, odometer) => {
    setDecoding(true);

    try {
      const decodeResponse = await decodeVIN(vin);
      // console.log('response from decode', decodeResponse);

      if (!decodeResponse.success) {
        toast({
          title:
            'VIN Decode fail, please verify and retry or enter information manually',
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        setViewEnterManual(true);
        return;
      }
      if (!decodeResponse.status === 'NOT_FOUND') {
        toast({
          title:
            'VIN Decode fail, please verify or enter information manually. ' +
            decodeResponse.message,
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        setViewEnterManual(true);
        return;
      }
      if (!decodeResponse.data.years) {
        toast({
          title:
            'VIN Decode fail, please verify and retry or enter information manually',
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        setViewEnterManual(true);
        return;
      }
      const title = `${decodeResponse.data.years[0]?.year} ${decodeResponse.data.make?.name} ${decodeResponse.data.model?.name}`;
      let vehicleStyle = '';
      if (decodeResponse.data.years[0]?.styles) {
        if (decodeResponse.data.years[0].styles.length === 1) {
          vehicleStyle = decodeResponse.data.years[0].styles[0].name;
        }
      }
      setFormData(prevState => ({
        ...prevState,
        fullVehicle: decodeResponse.data,
        year: decodeResponse.data.years[0]?.year,
        make: decodeResponse.data.make?.name,
        model: decodeResponse.data.model?.name,
        title,
        vin,
        odometer: odometer ? odometer : '',
        colorExterior: '',
        colorInterior: '',
        style: vehicleStyle,
      }));
      toast({
        title: 'VIN Decoded!',
        status: 'success',
        isClosable: true,
      });
      setDecoding(false);
    } catch (e) {
      //  console.log(e);
      toast({
        title: 'VIN Decode fail, please verify and retry',
        status: 'error',
        isClosable: true,
      });
      setDecoding(false);
      setViewEnterManual(true);
    }
  };

  const enterManualDetails = () => {
    // TODO: add vinaudit dropdowns
    setFormData(prevState => ({
      ...prevState,
      fullVehicle: 'manual',
      year: '',
      make: '',
      model: '',
      title: '',
      vin: formData.vin,
      odometer: '',
      colorExterior: '',
      colorInterior: '',
      style: '',
    }));
  };
  let decodeIsDisabled = true;
  if (formData?.vin?.length === 17) {
    decodeIsDisabled = false;
  }

  let descriptionIsComplete = true;
  if (!formData.year) {
    descriptionIsComplete = false;
  }
  if (!formData.make) {
    descriptionIsComplete = false;
  }
  if (!formData.model) {
    descriptionIsComplete = false;
  }
  if (!formData.style) {
    descriptionIsComplete = false;
  }
  if (!formData.odometer) {
    descriptionIsComplete = false;
  }
  if (!formData.title) {
    descriptionIsComplete = false;
  }
  if (!formData.province) {
    descriptionIsComplete = false;
  }

  let notAvailableWarning;

  if (
    formData?.province &&
    !eligibleWarrantyProvinces.includes(formData?.province.toLowerCase())
  ) {
    // neither eligible province
    notAvailableWarning = (
      <>
        <Alert
          status="warning"
          variant="left-accent"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          // textAlign="center"
          mt="2%"
        >
          <AlertIcon/>
          <AlertDescription maxWidth="lg">
            {t('forNowEEZEWarrantiesAreOnlyAvailableInONandQC')}
            <br />
          </AlertDescription>
        </Alert>
      </>
    );
  }

  const renderDescription = () => {
    if (decoding) {
      return (
        <Box minHeight="40vh">
          <Center m={10} p={10}>
            <Spinner size="xl" color="primary.500" />
          </Center>
        </Box>
      );
    }

    return (
      <Box minHeight="40vh">
        {!formData.fullVehicle && (
          <>
            <FormControl mt={5}>
              <Center textAlign="center" my={10}>
                Begin by entering the VIN
              </Center>

              <Center textAlign="center" my={5}>
                {t('theVINCanBeFoundInTheDoorFrame')}
              </Center>
              <Center>
                <Image
                  src={vinlocation}
                  alt="Logo"
                  // boxSize="40px"
                  width="70%"
                  maxWidth="400px"
                  objectFit="cover"
                />
              </Center>

              <Stack direction="row" align="center">
                <FormLabel m={0}>
                  VIN
                  {!formData.vin && <span style={{ color: 'red' }}> *</span>}
                </FormLabel>
              </Stack>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChangeVIN}
                disabled={formData.fullVehicle ? true : false}
              />
            </FormControl>

            {isDev && !formData.vin && (
              <Button
                onClick={insertRandomVIN}
                mt={10}
                size="sm"
                variant="link"
                color="lightgray"
              >
                <i>DEV Insert Random VIN</i>
              </Button>
            )}
          </>
        )}

        {formData.vin && !formData.fullVehicle && (
          <Button
            onClick={() => onClickDecodeVIN(formData.vin)}
            colorScheme="blue"
            w="full"
            mt={2}
            disabled={decodeIsDisabled}
          >
            DECODE
          </Button>
        )}

        {!formData.fullVehicle && viewEnterManual && (
          <Button
            colorScheme="primary"
            variant="link"
            mt={10}
            w="full"
            onClick={enterManualDetails}
          >
            Enter manual details
          </Button>
        )}
        {formData.fullVehicle && (
          <>
            <FormLabel m={0} w="50%">
              VIN
            </FormLabel>
            <InputGroup>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChange}
                // disabled
              />
              {!formData.vin && (
                <Text ml={2} color="red">
                  *
                </Text>
              )}
            </InputGroup>

            <FormControl mt="2%">
              <Stack direction="row" w="full">
                <FormLabel m={0} w="50%">
                  Year
                </FormLabel>
                <FormLabel m={0}>Make</FormLabel>
              </Stack>
              <InputGroup>
                <Input
                  id="year"
                  type="number"
                  value={formData.year}
                  onChange={onChange}
                  // disabled
                />
                {!formData.year && (
                  <Text mx={2} color="red">
                    *
                  </Text>
                )}
                <Input
                  id="make"
                  type="text"
                  value={formData.make}
                  onChange={onChange}
                  // disabled
                  // list="makes-list"
                />
                {!formData.make && (
                  <Text ml={2} color="red">
                    *
                  </Text>
                )}
              </InputGroup>
              <FormLabel m={0} w="50%">
                Model
              </FormLabel>
              <InputGroup>
                <Input
                  id="model"
                  type="text"
                  value={formData.model}
                  onChange={onChange}
                  // disabled
                />
                {!formData.model && (
                  <Text ml={2} color="red">
                    *
                  </Text>
                )}
              </InputGroup>
            </FormControl>

            <FormControl
              mt="2%"
              // color={!formData.style ? 'red' : ''}
            >
              <FormLabel m={0}>Trim</FormLabel>
              <InputGroup>
                {formData.trim && (
                  <Input
                    id="style"
                    placeholder="Or enter manually"
                    type="text"
                    value={formData.trim}
                    onChange={onChange}
                    disabled={listingUid}
                  />
                )}
                {formData.fullVehicle?.years ? (
                  <Select
                    id="style"
                    value={formData.style}
                    onChange={onChange}
                    disabled={listingUid}
                  >
                    <option value="">Select</option>
                    {formData.fullVehicle.years[0].styles.map((style, i) => (
                      <option key={i} value={style.name}>
                        {style.name}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    id="style"
                    // placeholder="Or enter manually"
                    type="text"
                    value={formData.trim}
                    onChange={onChange}
                    // disabled={listingUid}
                  />
                )}
                {!formData.style && (
                  <Text ml={2} color="red">
                    *
                  </Text>
                )}
              </InputGroup>
            </FormControl>

            <FormControl
              mt={'2%'}
              mb={2}
              // color={!formData.style ? 'red' : ''}
            >
              <FormLabel m={0}>Odometer</FormLabel>
              <InputGroup>
                <Input
                  id="odometer"
                  type="number"
                  w="50%"
                  value={formData.odometer}
                  onChange={onChange}
                  disabled={listingUid}
                  // onBlur={onBlurOdometer}
                />
                <Input
                  id="odometerType"
                  // type="number"
                  w="50%"
                  value={formData.odometerType}
                  onChange={onChange}
                  disabled
                  // onBlur={onBlurOdometer}
                />
                {!formData.odometer && (
                  <Text ml={2} color="red">
                    *
                  </Text>
                )}
              </InputGroup>
            </FormControl>
            <FormControl
              mt={'2%'}
              // mb={2}
              // color={!formData.style ? 'red' : ''}
            >
              <FormLabel m={0}>Home Province</FormLabel>

              <InputGroup>
                <Select
                  id="province"
                  mr={2}
                  value={formData?.province}
                  onChange={onChange}
                  // color={!formData.province ? 'red' : ''}
                  // disabled={formData.warrantyPending || formData.warrantyName}
                >
                  <option value="">
                    {t('select')}
                  </option>
                  {allProvinces.map((prov, i) => (
                    <option key={i} value={Object.values(prov)}>
                      {Object.values(prov).map(value => value.toUpperCase())} -{' '}
                      {Object.keys(prov)}
                    </option>
                  ))}
                </Select>
                {!formData.province && <span style={{ color: 'red' }}> *</span>}
              </InputGroup>
            </FormControl>
          </>
        )}
        {formData.odometer && formData.odometer > 200000 && (
          <Alert
            status="error"
            variant="left-accent"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            // textAlign="center"
            // mb={2}
            mt="2%"
          >
            <AlertIcon />
            <AlertDescription
            // maxWidth="lg"
            //  fontWeight='bold'
            >
              Warning: Vehicles with over 200,000 km are not eligible for most
              EEZE warranties
              <br />
            </AlertDescription>
          </Alert>
        )}
        {notAvailableWarning}
      </Box>
    );
  };

  if (loading) {
    return (
      <Box minHeight="40vh">
        <Center m={10} p={10}>
          <Spinner size="xl" color="primary.500" />
        </Center>
      </Box>
    );
  }
  return (
    <>
      <Heading size="sm" textAlign="center">
        Add Your Vehicle
      </Heading>
      <Heading size="md" textAlign="center">
        {formData?.title}
      </Heading>

      <Container maxW={'2xl'} p={0} mt={2}>
        {renderDescription()}

        {descriptionIsComplete && !listingUid && (
          <Button
            onClick={createListing}
            w="full"
            mt={5}
            variant="solid"
            colorScheme="primary"
          >
            Add Vehicle and Select Warranty
          </Button>
        )}

        {formData.fullVehicle && !descriptionIsComplete && (
          <>
          <br/>
          <Text color="red" as="i" textAlign="center">
            * Required
          </Text>
          </>
        )}
      </Container>
    </>
  );
}

export default CreateVehicle;
