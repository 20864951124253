import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React from "react";
import {
  ChakraProvider,
} from '@chakra-ui/react';
import PrivateRoute from './components/PrivateRoute'
import PublicOnlyRoute from './components/PublicOnlyRoute'
import { newVerifyFlow } from './constants'

import Listings from './pages/Listings'
import Homepage from './pages/Homepage'
import Profile from './pages/Profile'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import Start from './pages/Start'
import Welcome from './pages/Welcome'
import Wallet from './pages/Wallet'
import GetWarranty from './pages/GetWarranty'
import NotFound from './pages/NotFound'
import ForgotPassword from './pages/ForgotPassword'
import Connect from './pages/Connect'
import ViewBuyers from './pages/ViewBuyers'
import Verify from './pages/Verify'
import VerifyOld from './pages/Verify_OLD'
import VerifyFinal from './pages/VerifyFinal_OLD'
import Transaction from './pages/Transaction'
import EditListing from './pages/EditListing'
import VehicleAddons from './pages/VehicleAddons'
import PaymentSuccess from './pages/PaymentSuccess'
import Listing from './pages/Listing'
import Toolkit from './pages/Toolkit'
import User from './pages/User'
import theme from './theme'

import { DataProvider } from './context/DataContext';

function App() {

  return (
    <ChakraProvider theme={theme}>
      <DataProvider>
        <Router>
          <Routes>
            <Route path='/welcome' element={<PrivateRoute />}>
              <Route path='/welcome' element={<Welcome />} />
            </Route>
            <Route path='/profile' element={<PrivateRoute />}>
              <Route path='/profile' element={<Profile />} />
            </Route>
            <Route path='/wallet' element={<PrivateRoute />}>
              <Route path='/wallet' element={<Wallet />} />
            </Route>
            <Route path='/verify' element={<PrivateRoute />}>
              <Route path='/verify' element={newVerifyFlow ? <Verify /> : <VerifyOld/>} />
            </Route>
            <Route path='/verify-final' element={<PrivateRoute />}>
              <Route path='/verify-final' element={<VerifyFinal />} />
            </Route>

            <Route path='/' element={<PrivateRoute />}>
              <Route path='/' element={<Homepage />} />
            </Route>

            {/* landing page to view partner and chose warranty, transaction or promotion flow */}
            <Route path='/start' element={<PublicOnlyRoute />}>
              <Route path='/start' element={<Start showPartners={true} />} />
            </Route>

            {/* not often used, like app.eeze.co/start/midas */}
            <Route path='/start/:refSlug' element={<PublicOnlyRoute />}>
              <Route path='/start/:refSlug' element={<Start showPartners={true} />} />
            </Route>

            {/* splash intro page for warranties */}
            <Route path='/get-warranty' element={<PublicOnlyRoute />}>
              <Route path='/get-warranty' element={<GetWarranty />} />
            </Route>

            {/* regular login */}
            <Route path='/login' element={<PublicOnlyRoute />}>
              <Route path='/login' element={<Login />} />
            </Route>

            {/* add name and email to create account */}
            <Route path='/signup' element={<PublicOnlyRoute />}>
              <Route path='/signup' element={<SignUp />} />
            </Route>
            {/* signup page but adding partner info on top, if any, if not selector for garage partner */}
            <Route path='/sign-up' element={<PublicOnlyRoute />}>
              <Route path='/sign-up' element={<SignUp showPartners={true} backToStart={false}/>} />
            </Route>

            {/* not often used, like app.eeze.co/signup/midas */}
            <Route path='/signup/:refSlug' element={<PublicOnlyRoute />}>
              <Route path='/signup/:refSlug' element={<SignUp />} />
            </Route>

            {/* signup but show back button and warranty headings */}
            <Route path='/warranty-signup' element={<PublicOnlyRoute />}>
              <Route path='/warranty-signup' element={<SignUp showPartners={true} warrantyRedirect={true} backToStart={false}/>} />
            </Route>

            {/* signup but show back button and promotional headings */}
            <Route path='/safety-promotion-signup' element={<PublicOnlyRoute />}>
              <Route path='/safety-promotion-signup' element={<SignUp showPartners={true} safetyPromotionRedirect={true} backToStart={false}/>} />
            </Route>

            <Route path='/listings' element={<Listings />} />
            <Route path='*' element={<NotFound />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />

            <Route path='/edit/:listingUid' element={<PrivateRoute />}>
              <Route path='/edit/:listingUid' element={<EditListing />} />
            </Route>

            <Route path='/vehicle-addons/:listingUid' element={<PrivateRoute />}>
              <Route path='/vehicle-addons/:listingUid' element={<VehicleAddons />} />
            </Route>

            <Route path='/connect/:listingUid/:buyerUid' element={<PrivateRoute />}>
              <Route path='/connect/:listingUid/:buyerUid' element={<Connect />} />
            </Route>

            <Route path='/buyers/:listingUid' element={<PrivateRoute />}>
              <Route path='/buyers/:listingUid' element={<ViewBuyers />} />
            </Route>
            <Route path='/transaction/:listingUid' element={<PrivateRoute />}>
              <Route path='/transaction/:listingUid' element={<Transaction />} />
            </Route>
            <Route path='/toolkit/:listingUid' element={<PrivateRoute />}>
              <Route path='/toolkit/:listingUid' element={<Toolkit />} />
            </Route>

            <Route
              path='/listing/:listingUid'
              element={<Listing />}
            />
            <Route
              path='/user/:userUid'
              element={<User />}
            />
            <Route
              path='/payment-success'
              element={<PaymentSuccess />}
            />
          </Routes>
        </Router>

      </DataProvider>
    </ChakraProvider>
  )
}

export default App
