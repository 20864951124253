import { useState, useRef, useEffect, useContext } from 'react';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import moment from 'moment';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Box,
  Container,
  AlertIcon,
  Alert,
  AlertDescription,
  AlertTitle,
  Center,
  // Divider,
  Progress,
  Image,
  SimpleGrid,
  Flex,
  // List,
  // ListItem,
  Select,
  Spinner,
  FormControl,
  FormLabel,
  InputLeftElement,
  Heading,
  Textarea,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  InputGroup,
  Stack,
  Text,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import { ExternalLinkIcon, AddIcon } from '@chakra-ui/icons';
import { BsTools, BsEye } from 'react-icons/bs';
import { ReactComponent as Main } from '../assets/svg/main.svg';
import { ReactComponent as Odometer } from '../assets/svg/odometer.svg';
import { ReactComponent as License } from '../assets/svg/license.svg';
import { ReactComponent as VIN } from '../assets/svg/VIN.svg';
import { ReactComponent as Registration } from '../assets/svg/registration.svg';
import { ReactComponent as Insurance } from '../assets/svg/insurance.svg';
import { ReactComponent as Document } from '../assets/svg/document.svg';
import { ReactComponent as Car } from '../assets/svg/illustration-vehicle.svg';
import { useTranslation } from 'react-i18next';

import {
  db,
  isDev,
  allProvinces,
  allowManualVehicle,
  LISTING,
  eligibleWarrantyProvinces,
} from '../constants';
import CARS from '../services/carInfo';
import decodeVIN from '../services/decodeVIN';
import getCapture from '../services/getCapture';
import deleteListing from '../services/deleteListing';
import AppBody from '../components/AppBody';
import SpinnerPage from '../components/SpinnerPage';
import Header from '../components/Header';
import DataContext from '../context/DataContext';
import OrDivider from '../components/OrDivider';
import sendEmailToAdmin from '../services/sendEmailToAdmin';

function EditListingVehicle(props) {
  const { t } = useTranslation('global');
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingImages, setLoadingImages] = useState({});
  const [decoding, setDecoding] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const dataContext = useContext(DataContext);
  const { userData } = dataContext;

  const photo1Ref = useRef(null);
  const photo2Ref = useRef(null);
  const photo3Ref = useRef(null);
  const photo4Ref = useRef(null);
  const photo5Ref = useRef(null);
  const photo6Ref = useRef(null);
  const photo7Ref = useRef(null);
  const photo8Ref = useRef(null);
  const otherPhotoRef = useRef(null);
  const [queryParameters] = useSearchParams();
  const [listing, setListing] = useState(props.listing);
  const [formData, setFormData] = useState({
    ...props.listing,
  });
  // const auth = getAuth();
  // const params = useParams();

  useEffect(() => {
    // change tab
    if (queryParameters.get('t')) {
      const index = queryParameters.get('t');
      setTabIndex(Number(index));
    }
  }, [queryParameters]);

  useEffect(() => {
    // real time listener in EditListing that feeds props
    // update when changes
    setFormData(props.listing);
    setListing(props.listing);
    setLoading(false);
  }, [props.listing]);

  const navigate = useNavigate();

  const handlePhoto1InputClick = () => {
    // main
    photo1Ref.current.click();
  };
  const handlePhoto2InputClick = () => {
    // odometer
    photo2Ref.current.click();
  };
  const handlePhoto3InputClick = () => {
    // license
    photo3Ref.current.click();
  };
  const handlePhoto4InputClick = () => {
    // vin
    photo4Ref.current.click();
  };
  const handlePhoto5InputClick = () => {
    // registration
    photo5Ref.current.click();
  };
  const handlePhoto6InputClick = () => {
    // insurance
    photo6Ref.current.click();
  };
  const handlePhoto7InputClick = () => {
    // safety test
    photo7Ref.current.click();
  };
  const handlePhoto8InputClick = () => {
    // digital inspection
    photo8Ref.current.click();
  };
  const handleOtherPhotoInputClick = () => {
    // digital inspection
    otherPhotoRef.current.click();
  };

  const onClickSave = (newFormData, nextTab) => {
    setIsSavingForm(true); // to disable buttons
    setIsEditing(false); // to prevent new entries

    const formDataToSave = newFormData ? newFormData : formData;
    // console.log('formDataToSave', formDataToSave)
    // set form data to firestore

    const docRef = doc(db, 'listings', listing.uid);
    setDoc(docRef, formDataToSave, { merge: true })
      .then(() => {
        // console.log('Document Field has been updated successfully');
        setIsSavingForm(false); // to enable buttons
        setIsEditing(true);
        if (nextTab) setTabIndex(tabIndex + 1);
      })
      .catch(error => {
        // console.log(error);
        toast({
          description: t('listingUpdateError'),
          status: 'error',
        });
        setIsSavingForm(false); // to enable buttons
        setIsEditing(true); // back to editing
      });
  };

  const handleTabsChange = index => {
    onClickSave(formData);
    if (!index) {
      // if 0, change to 1
      // if 1, change to 0
      if (tabIndex === 0) {
        return setTabIndex(1);
      } else if (tabIndex === 1) {
        return setTabIndex(0);
      }
    }
    setTabIndex(index);
  };

  const onClickSaveAndSubmitForReview = () => {
    if (!window.confirm(t('saveAndPublishThisListingAreYouSure'))) {
      return;
    }

    setIsSavingForm(true); // to disable buttons
    setIsEditing(false); // to prevent new entries

    const formDataToSave = {
      ...formData,
      status: LISTING.STATUS.POSTED,
      postedAt: new Date().toString(),
    };
    // set form data to firestore

    const docRef = doc(db, 'listings', listing.uid);
    setDoc(docRef, formDataToSave, { merge: true })
      .then(() => {
        // console.log('Document Field has been updated successfully');
        setIsSavingForm(false); // to enable buttons
        toast({
          title: t('listingPosted'),
          description: t('youMayNowShareThisEEZEListing'),
          status: 'info',
          isClosable: true,
          duration: 11000,
        });
        setIsEditing(true); // back to editing
        // send notice to admin
        sendEmailToAdmin(
          'vehicleToVerify',
          `${listing.title} - ${listing.sellerName} - ${listing.uid}`
        );
        navigate('/');
      })
      .catch(error => {
        // console.log(error);
        toast({
          description: t('listingSaveError'),
          status: 'error',
        });
        setIsSavingForm(false); // to enable buttons
        setIsEditing(true); // back to editing
      });
  };

  const onClickDelete = async () => {
    if (listing.buyerUid) {
      toast({
        title: t('cannotDeleteAListingLinkedToAnotherUser'),
        description: t('considerCancellingInstead'),
        status: 'error',
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    const response = await deleteListing(listing);
    // console.log('delete response', response)
    if (response.success) {
      toast({
        title: t('listingDeleted'),
        isClosable: true,
      });
      navigate('/');
    } else {
      setLoading(false);
      toast({
        title: t('notDeleted'),
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onClickCancel = () => {
    if (listing !== formData) {
      // are you sure
      if (!window.confirm(t('discardChangesAreYouSure'))) {
        return;
      }
    }

    setFormData(listing);
  };

  const getCaptureUrl = async () => {
    setIsSavingForm(true); // to disable buttons
    setIsEditing(true); // to prevent new entries

    try {
      const [firstName, lastName] = listing.sellerFullName.split(' ');

      const captureResponse = await getCapture({
        firstName,
        lastName,
        cell: listing.sellerCell,
        listingUid: listing.uid,
      });

      if (!captureResponse.success) {
        toast({
          title: t('captureLinkFailPleaseWait'),
          status: 'warning',
          isClosable: true,
        });
        setIsSavingForm(false);
        setIsEditing(false);
        return;
      }

      const docRef = doc(db, 'listings', listing.uid);
      setDoc(
        docRef,
        { ...formData, captureUrl: captureResponse.url },
        { merge: true }
      );

      setIsSavingForm(false); // to disable buttons
      setIsEditing(false); // to prevent new entries
    } catch (e) {
      //  console.log(e);
      toast({
        title: t('captureLinkFailPleaseWait'),
        status: 'error',
        isClosable: true,
      });
      setIsSavingForm(false);
      setIsEditing(false);
    }
  };
  const onChange = e => {
    let boolean = null;
    let value = e.target.value;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }

    // if (e.target.id === 'askingPrice') {
    //   // value = value.toFixed(2); limit to 2 decimals TODO:
    // }
    // if (e.target.id === 'odometer') {
    //   onBlurOdometer(value);
    // }
    // if (e.target.id === 'odometerType') {
    //   onBlurOdometer(formData.odometer, value);
    // }

    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: boolean ?? value,
    }));
  };

  // const handleToPhotoTab = () => {
  //   photosTabRef.current.click();
  // };

  const onChangeVIN = e => {
    if (e.target.value !== '') {
      const regEx = /^[0-9a-zA-Z]+$/;
      if (!e.target.value.match(regEx)) return;
      if (e.target.value.includes(' ')) return;
      if (e.target.value.includes('I')) return;
      if (e.target.value.includes('i')) return;
      if (e.target.value.includes('O')) return;
      if (e.target.value.includes('o')) return;
      if (e.target.value.includes('Q')) return;
      if (e.target.value.includes('q')) return;
      if (e.target.value.length > 17) return;
    }
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value.toUpperCase(),
    }));
  };

  const simulateCapture = () => {
    const index = Math.floor(Math.random() * CARS.RANDOM_VINS.length);
    const vin = CARS.RANDOM_VINS[index];
    onClickDecodeVIN(vin, 120000);
    insertRandomPhotos();
  };

  const insertRandomVIN = () => {
    const index = Math.floor(Math.random() * CARS.RANDOM_VINS.length);
    setFormData(prevState => ({
      ...prevState,
      vin: CARS.RANDOM_VINS[index],
    }));
  };

  const insertRandomPhotos = () => {
    setFormData(prevState => ({
      ...prevState,
      fullVehicle: {
        photos: [
          {
            photo_label: 'VIN',
            created_at: '2024-02-11T15:15:59+00:00',
            rejection_code: null,
            approved_message: 'Accepted',
            photo_code: 1,
            url: 'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/1-9b4fa9ef-4af7-4b5d-98f6-48339d315322-1920x1080.jpg',
            updated_at: '2024-02-11T15:16:36+00:00',
            approved: true,
          },
          {
            approved_message: 'Accepted',
            updated_at: '2024-02-11T15:17:10+00:00',
            created_at: '2024-02-11T15:16:55+00:00',
            photo_label: 'Cluster',
            photo_code: 3,
            approved: true,
            url: 'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/3-9b4faa46-79b4-4c21-a4c8-8848fa6aa4ef-1920x1080.jpg',
            rejection_code: null,
          },
          {
            photo_code: 10,
            photo_label: 'Front_Left',
            url: 'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/10-9b4faaaf-1a30-4087-b5e0-02be63b0fd6e-1920x1080.jpg',
            approved: true,
            approved_message: 'Accepted',
            rejection_code: null,
            created_at: '2024-02-11T15:18:04+00:00',
            updated_at: '2024-02-11T15:18:16+00:00',
          },
          {
            photo_code: 5,
            approved_message: 'Accepted',
            photo_label: 'Front',
            url: 'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/5-9b4fab05-1edb-4463-a8ab-ccb83c8bb1eb-1920x1080.jpg',
            approved: true,
            updated_at: '2024-02-11T15:19:17+00:00',
            created_at: '2024-02-11T15:19:00+00:00',
            rejection_code: null,
          },
          {
            approved: true,
            approved_message: 'Accepted',
            updated_at: '2024-02-11T15:22:10+00:00',
            rejection_code: null,
            created_at: '2024-02-11T15:21:57+00:00',
            url: 'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/8-9b4fac11-5fa0-4cae-ad60-68b9be6fe2e0-1920x1080.jpg',
            photo_label: 'Rear',
            photo_code: 8,
          },
        ],
      },
      listingProfilePhoto:
        'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/10-9b4faaaf-1a30-4087-b5e0-02be63b0fd6e-1920x1080.jpg',
      // 'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Fdemo-vehicle%2Fcarprofile.jpeg?alt=media&token=d970bdde-bf6e-4538-8979-9c73522fc099',
      vehicleOdometerPhoto:
        'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/3-9b4faa46-79b4-4c21-a4c8-8848fa6aa4ef-1920x1080.jpg',
      // 'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Fdemo-vehicle%2Fcarodometer.jpeg?alt=media&token=6299f4ae-3e47-426c-91fe-64dc3f1a3a31',
      vehicleLicensePhoto:
        'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/8-9b4fac11-5fa0-4cae-ad60-68b9be6fe2e0-1920x1080.jpg',
      // 'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Fdemo-vehicle%2Fcarlicense.jpeg?alt=media&token=818792ed-d9ff-4e56-bbcb-d42b63ad98c7',
      vehicleVINPhoto:
        'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/capture/1-9b4fa9ef-4af7-4b5d-98f6-48339d315322-1920x1080.jpg',
      // 'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Fdemo-vehicle%2Fcarvin.jpeg?alt=media&token=0e27b5e4-7a5c-4aa8-942b-cdceb8c51519',
      vehicleRegistrationPhoto:
        'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/forms/user_form-9b4fad11-9d8d-4c07-b230-0d0e65a128b1.jpg',
      // 'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Fdemo-vehicle%2Fcarregistration.jpeg?alt=media&token=1edaed50-bf9f-489e-874d-fb0cd1b2287c',
      vehicleInsurancePhoto:
        'https://openapi-cdn.paveapi.com/sessions/sessions/2024-02/EY8V-FNHNZQVSTY/forms/user_form-9b4facd2-360c-45ae-ba8c-e9aa6ed89177.jpg',
      // 'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Fdemo-vehicle%2Fcarinsurance.jpeg?alt=media&token=d06414e9-bda7-4233-a1e1-280ecda45372',
      // vehicleConditionReport:
      //   'https://firebasestorage.googleapis.com/v0/b/eeze-dev-storage-db-auth.appspot.com/o/images%2Fdemo-vehicle%2Finspection.png?alt=media&token=9929396d-f3ef-46c3-b258-679e6e1c9f86',
    }));
  };

  // const onBlurOdometer = (odometer, odometerType) => {
  //   // set subtitle to formatted odometer
  //   setFormData(prevState => ({
  //     ...prevState,
  //     subtitle:
  //       odometerFormat(odometer || formData.odometer) +
  //       ' ' +
  //       (odometerType || formData.odometerType),
  //   }));
  // };

  const onClickDecodeVIN = async (vin, odometer) => {
    setDecoding(true);

    try {
      const decodeResponse = await decodeVIN(vin);
      // console.log('response from decode', decodeResponse);

      if (!decodeResponse.success) {
        toast({
          title: t('vinDecodeFailPleaseTryAgain'),
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.status === 'NOT_FOUND') {
        toast({
          title: t('vinDecodeFailPleaseTryAgain') + decodeResponse.message,
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.data.years) {
        toast({
          title: t('vinDecodeFailPleaseTryAgain'),
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      // let title = formData.title;
      // if (!title) {
      const title = `${decodeResponse.data.years[0]?.year} ${decodeResponse.data.make?.name} ${decodeResponse.data.model?.name}`;
      // }
      let vehicleStyle = '';
      if (decodeResponse.data.years[0]?.styles) {
        if (decodeResponse.data.years[0].styles.length === 1) {
          vehicleStyle = decodeResponse.data.years[0].styles[0].name;
        }
      }
      setFormData(prevState => ({
        ...prevState,
        fullVehicle: decodeResponse.data,
        year: decodeResponse.data.years[0]?.year,
        make: decodeResponse.data.make?.name,
        model: decodeResponse.data.model?.name,
        title,
        vin,
        odometer: odometer ? odometer : '',
        colorExterior: '',
        colorInterior: '',
        style: vehicleStyle,
      }));
      toast({
        title: t('success')+'!',
        status: 'success',
        isClosable: true,
      });
      setDecoding(false);
    } catch (e) {
      //  console.log(e);
      toast({
        title: t('vinDecodeFailPleaseTryAgain'),
        status: 'error',
        isClosable: true,
      });
      setDecoding(false);
    }
  };

  const storeImage = async image => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const fileName = `vehicle-${Date.now()}`;

      const storageRef = ref(
        storage,
        'images/listings/' + listing.uid + '/' + fileName
      );

      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        snapshot => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case 'paused':
          //     console.log('Upload is paused');
          //     break;
          //   case 'running':
          //     console.log('Upload is running');
          //     break;
          //   default:
          //     break;
          // }
        },
        error => {
          // console.log(error);
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const getVINFromPhoto = url => {
    // TODO: hook up
    // console.log('photo to OCR', url)
    // const ocr = sendImageToCloudVision(fileName)
    // console.log('ocr', ocr);

    if (formData.vin) return;

    toast({
      title: 'Unable to extract VIN',
      description: 'Please enter manually',
      status: 'warning',
    });
  };

  const onChangeFileInput = async e => {
    // console.log(e)
    setLoadingImages({
      [e.target.id]: true,
    });
    await Promise.resolve(storeImage(e.target.files[0]))
      .then(fileName => {
        // console.log('upload done...', fileName);

        if (e.target.id === 'vehicleVINPhoto') {
          getVINFromPhoto(fileName);
        }

        const newFormData = {
          ...formData,
          [e.target.id]: fileName,
        };
        // setFormData(newFormData);
        // setListing(newFormData);
        onClickSave(newFormData);
        setLoadingImages({
          [e.target.id]: false,
        });
      })
      .catch(err => {
        console.log('error', err);
        setLoadingImages({
          [e.target.id]: false,
        });
        toast({
          title: t('couldNotUploadImage'),
          status: 'error',
          isClosable: true,
        });
        // if fail, return what was there before
        // return listing[e.target.id];
      });
  };

  const onChangeFileInputOtherPhotos = async (e, arrayIndex) => {
    if (!e.target.files) return;
    // console.log('arrayIndex', arrayIndex);
    await Promise.resolve(storeImage(e.target.files[0]))
      .then(fileName => {
        const newArray = formData.listingOtherPhotos
          ? formData.listingOtherPhotos
          : [];
        if (arrayIndex) {
          newArray[arrayIndex] = fileName;
          // TODO: need to delete the image that this is replacing
        } else newArray.push(fileName);

        const newFormData = {
          ...formData,
          listingOtherPhotos: newArray,
        };

        // setFormData(newFormData);
        // setListing(newFormData);
        onClickSave(newFormData);
      })
      .catch(err => {
        console.log('error', err);
        toast({
          title: t('couldNotUploadImage'),
          status: 'error',
          isClosable: true,
        });
        // if fail, return what was there before
        // return listing[e.target.id];
      });
  };

  let decodeIsDisabled = true;
  if (formData?.vin.length === 17) {
    decodeIsDisabled = false;
  }

  let missingItems = [];
  let detailsMissingItems = [];
  let listingMissingItems = [];
  let descriptionIsComplete = true;
  let photosAreComplete = true;
  let listingIsComplete = true;
  if (!formData.year) {
    detailsMissingItems.push(t('missingVehicleYear'));
    descriptionIsComplete = false;
  }
  if (!formData.make) {
    detailsMissingItems.push(t('missingVehicleMake'));
    descriptionIsComplete = false;
  }
  if (!formData.model) {
    detailsMissingItems.push(t('missingVehicleModel'));
    descriptionIsComplete = false;
  }
  if (!formData.style) {
    detailsMissingItems.push(t('missingVehicleStyle'));
    descriptionIsComplete = false;
  }
  if (!formData.province) {
    detailsMissingItems.push(t('missingProvince'));
    descriptionIsComplete = false;
  }
  if (!formData.odometer) {
    detailsMissingItems.push(t('missingOdometerReading'));
    descriptionIsComplete = false;
  }
  if (!formData.title) {
    detailsMissingItems.push(t('missingListingTitle'));
    descriptionIsComplete = false;
  }

  if (!formData.listingProfilePhoto) {
    detailsMissingItems.push(t('missingProfilePhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleOdometerPhoto) {
    detailsMissingItems.push(t('missingDashboardOdometerPhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleLicensePhoto) {
    detailsMissingItems.push(t('missingLicensePhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleVINPhoto) {
    detailsMissingItems.push(t('missingVINPhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleRegistrationPhoto) {
    detailsMissingItems.push(t('missingRegistrationPhoto'));
    photosAreComplete = false;
  }
  if (!formData.vehicleInsurancePhoto) {
    detailsMissingItems.push(t('missingInsurancePhoto'));
    photosAreComplete = false;
  }
  if (!formData.askingPrice) {
    listingMissingItems.push(t('missingAskingPrice'));
    listingIsComplete = false;
  }
  if (!descriptionIsComplete) {
    listingIsComplete = false;
  }
  if (!photosAreComplete) {
    listingIsComplete = false;
  }

  // let canPost = true;
  // if (!listingIsComplete) canPost = false;
  // if (!formData.askingPrice) canPost = false;
  // if (!photosAreComplete) canPost = false;

  let editIsDisabled = false;
  if (listing.status === LISTING.STATUS.COMPLETED) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_SIGNED) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_SIGNING) editIsDisabled = true;
  if (listing.status === LISTING.STATUS.BUYER_FUNDING) editIsDisabled = true;

  const renderDescription = () => {
    if (decoding) {
      return (
        <Box minHeight="80vh">
          <Center m={10} p={10}>
            <Spinner size="xl" color="primary.500" />
          </Center>
        </Box>
      );
    }

    return (
      <Box minHeight="50vh">
        {editIsDisabled && (
          <Center>
            <Text color="primary.500">
              {t('atThisStageYouCannotEditThisListing')}
            </Text>
          </Center>
        )}
        {!formData.fullVehicle && (
          <>
            {!formData.captureUrl ? (
              <>
                <Center textAlign="center" my={4}>
                  <Text>{t('toGatherInformationOnYourVehicleYouWillUse')}</Text>
                </Center>
                <Center my={5}>
                  <Car width="40%" />
                </Center>
                <Text textAlign="center" fontSize="md">
                  {t('clickHereTo')}
                </Text>
                <Button
                  w="full"
                  mt={5}
                  colorScheme="primary"
                  onClick={getCaptureUrl}
                  isLoading={isSavingForm}
                >
                  {t('generateAndSendSMSlink')}
                </Button>
              </>
            ) : (
              <>
                <Text textAlign="center" mt={5}>
                  {t('anSMSLinkHasBeenSentToYouToCaptureYourVehicle')}
                </Text>
                <br />
                <Text textAlign="center">
                  {t('afterCompletingTheProcessPleaseStandbyOnThisPage')}
                </Text>
                <br />
                <br />
                <br />
                <Progress
                  size="md"
                  my={5}
                  isIndeterminate
                  colorScheme="primary"
                />
                <br />
                <br />
                <br />
                <Text textAlign="center">
                  {t('ifOnAMobileDeviceYouCanAlso')}
                </Text>
                <Button
                  variant="link"
                  colorScheme="primary"
                  my={2}
                  w="full"
                  onClick={() => window.open(formData?.captureUrl, '_blank')}
                  rightIcon={<ExternalLinkIcon />}
                >
                  {t('clickHereToOpenTheCaptureTool')}
                </Button>
              </>
            )}
          </>
        )}

        {!formData.fullVehicle &&
          // !formData.captureUrl &&
          allowManualVehicle && (
            <>
              <OrDivider my={5} />
              <FormControl>
                <Center textAlign="center">
                  {t('youCanAlsoEnterVehicleDetailsManually')}
                </Center>
                <Stack direction="row" align="center">
                  <FormLabel m={0}>
                    {t('vin')}
                    {!formData.vin && <span style={{ color: 'red' }}> *</span>}
                  </FormLabel>
                </Stack>
                <Input
                  id="vin"
                  type="text"
                  value={formData.vin}
                  onChange={onChangeVIN}
                  disabled={formData.fullVehicle ? true : false}
                />
              </FormControl>

              {isDev && !formData.vin && (
                <Button
                  onClick={insertRandomVIN}
                  mt={10}
                  size="sm"
                  variant="link"
                  color="lightgray"
                >
                  <i>DEV Insert Random VIN</i>
                </Button>
              )}
            </>
          )}

        {formData.vin && !formData.make && !formData.model && (
          <Button
            onClick={() => onClickDecodeVIN(formData.vin)}
            colorScheme="blue"
            w="full"
            mt={2}
            disabled={decodeIsDisabled}
          >
            {t('decodeCapital')}
          </Button>
        )}

        {isDev && !formData.fullVehicle && (
          <>
            <Button
              onClick={simulateCapture}
              mt={10}
              size="sm"
              variant="link"
              color="lightgray"
            >
              <i>DEV Simulate Vehicle capture</i>
            </Button>
          </>
        )}
        {formData.fullVehicle && (
          <>
            <FormControl mt="2%">
              <Stack direction="row" w="full">
                <FormLabel m={0} w="50%">
                  {t('year')}
                </FormLabel>
                <FormLabel m={0}>{t('make')}</FormLabel>
              </Stack>
              <InputGroup>
                <Input
                  id="year"
                  // type="text"
                  value={formData.year}
                  // onChange={onChange}
                  disabled
                />
                <Input
                  id="make"
                  type="text"
                  value={formData.make}
                  onChange={onChange}
                  disabled
                  list="makes-list"
                />
              </InputGroup>
              <FormLabel m={0} w="50%">
                {t('model')}
              </FormLabel>
              <InputGroup>
                <Input
                  id="model"
                  type="text"
                  value={formData.model}
                  onChange={onChange}
                  disabled
                />
              </InputGroup>
            </FormControl>

            <FormControl mt="2%">
              <FormLabel m={0}>
                {t('style')}
                {!formData.style && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                {formData.style && (
                  <Input
                    id="style"
                    placeholder="Or enter manually"
                    type="text"
                    value={formData.style}
                    onChange={onChange}
                    // disabled
                  />
                )}
                {formData.fullVehicle?.years && (
                  <Select
                    id="style"
                    value={formData.style}
                    onChange={onChange}
                    disabled={
                      // !isEditing ||
                      editIsDisabled || formData?.verified
                    }
                  >
                    <option value="">{t('select')}</option>
                    {formData.fullVehicle.years[0].styles.map((style, i) => (
                      <option key={i} value={style.name}>
                        {style.name}
                      </option>
                    ))}
                  </Select>
                )}
              </InputGroup>
            </FormControl>
          </>
        )}

        <br />
        {formData.fullVehicle && (
          <>
            <FormLabel m={0} w="50%">
              {t('vin')}
            </FormLabel>
            <InputGroup>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChange}
                disabled
              />
            </InputGroup>

            {/* <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton pl={0}>
                  <Box as="span" flex="1" textAlign="left">
                    {t('VINDetails')}
                  </Box>
                  <AccordionIcon color="blue" />
                </AccordionButton>

                <AccordionPanel pb={4} bgColor="blackAlpha.50">
                  <Box>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                      <List spacing={2}>
                        {formData.colorExterior && (
                          <ListItem>
                            <Text as={'span'}>{t('exteriorColor')}</Text>{' '}
                            {formData.colorExterior}
                          </ListItem>
                        )}
                        {formData.colorInterior && (
                          <ListItem>
                            <Text as={'span'}>{t('interiorColor')}</Text>{' '}
                            {formData.colorInterior}
                          </ListItem>
                        )}

                        {formData?.drivetrain && (
                          <ListItem>
                            {t('drivetrain')} {formData?.drivetrain}
                          </ListItem>
                        )}
                        {formData?.transmissionType && (
                          <ListItem>
                            {t('transmission')} {formData?.transmissionType}
                          </ListItem>
                        )}

                        {formData.fullVehicle?.transmission
                          ?.transmissionType && (
                          <ListItem>
                            {t('transmission')}{' '}
                            {
                              formData.fullVehicle?.transmission
                                ?.transmissionType
                            }
                          </ListItem>
                        )}

                        {formData.fullVehicle?.engine?.horsepower && (
                          <ListItem>
                            {t('horsepower')}{' '}
                            {formData.fullVehicle?.engine?.horsepower} hp
                          </ListItem>
                        )}

                        {formData.fullVehicle?.mpg?.highway && (
                          <ListItem>
                            MPG Highway: {formData.fullVehicle?.mpg?.highway}
                          </ListItem>
                        )}
                        {formData.fullVehicle?.mpg?.city && (
                          <ListItem>
                            MPG City: {formData.fullVehicle?.mpg?.city}
                          </ListItem>
                        )}
                      </List>
                    </SimpleGrid>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}

            <FormControl mt={'2%'} mb={4}>
              <FormLabel m={0}>
                {t('odometer')}
                {!formData.odometer && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                <Input
                  id="odometer"
                  type="number"
                  w="50%"
                  value={formData.odometer}
                  onChange={onChange}
                  disabled={formData?.verified}
                  color={!formData.odometer ? 'red' : ''}
                  // disabled //={!isEditing || editIsDisabled}
                  // onBlur={onBlurOdometer}
                />
                <Input
                  id="odometerType"
                  // type="number"
                  w="50%"
                  value={formData.odometerType}
                  onChange={onChange}
                  disabled
                  // onBlur={onBlurOdometer}
                />
              </InputGroup>
            </FormControl>

            {!formData.vehicleDigitalConditionReport &&
              formData.vehicleDigitalConditionReportPending && (
                <>
                  <Text>{t('EEZEDigitalConditionReport')}</Text>

                  <Alert
                    status="warning"
                    variant="left-accent"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <AlertIcon mr={0} />
                    <AlertDescription maxWidth="lg">
                      {t('aFulligitalInspectionHasBeenRequested')}
                    </AlertDescription>
                    <Center my={5}>
                      <Button
                        color="white"
                        bg="black"
                        rightIcon={<ExternalLinkIcon />}
                        disabled={
                          !listing.vehicleDigitalConditionReportCaptureUrl
                        }
                        onClick={() =>
                          window.open(
                            listing.vehicleDigitalConditionReportCaptureUrl,
                            '_blank'
                          )
                        }
                      >
                        {t('clickHereToPerformInspection')}
                      </Button>
                    </Center>
                  </Alert>
                </>
              )}

            {formData.vehicleDigitalConditionReport && (
              <>
                <Text>{t('EEZEDigitalConditionReport')}</Text>
                <Center my={5}>
                  <Button
                    colorScheme="primary"
                    rightIcon={<ExternalLinkIcon />}
                    onClick={() =>
                      window.open(listing.vehicleCarfaxVHR, '_blank')
                    }
                  >
                    {t('clickHereToViewReport')}
                  </Button>
                </Center>
              </>
            )}

            <InputGroup>
              {listing?.province.length === 2 ? (
                <Text as="i">
                  {/* {t('homeProvince')}: {listing?.province.toUpperCase()} */}
                </Text>
              ) : (
                <Select
                  id="province"
                  mr={2}
                  // value={listing?.address?.province || ''}
                  onChange={onChange}
                >
                  <option value="">
                    {t('select')} {t('homeProvince')}
                  </option>
                  {allProvinces.map((prov, i) => (
                    <option key={i} value={Object.values(prov)}>
                      {Object.values(prov).map(value => value.toUpperCase())} -{' '}
                      {Object.keys(prov)}
                    </option>
                  ))}
                </Select>
              )}
              {!formData.province && <span style={{ color: 'red' }}> *</span>}
            </InputGroup>
            <br />
          </>
        )}

        {formData.fullVehicle && (
          <>
            {!descriptionIsComplete && (
              <Text m={1} color="red" as="i" size="xs">
                * {t('requiredFields')}
              </Text>
            )}
            <Button
              onClick={() => {
                onClickSave(formData, true);
                // setTabIndex(tabIndex + 1);
              }}
              mb={5}
              w="full"
              colorScheme="primary"
              size="lg"
              disabled={!descriptionIsComplete}
            >
              {t('nextSection')}
            </Button>
          </>
        )}
        <br/>
        <br/>
        <br/>
        {/* <Button
              w='full'
              variant="link"
              colorScheme="primary"
              disabled={!descriptionIsComplete}
              onClick={() =>
                navigate(
                  '/' +
                  listing.type.toLowerCase() +
                  '-addons/' +
                  listing.uid
                  )
                }
                >
                   {t('puchaseWarrantyOrCarfax')}
            </Button>
        <br/> */}
      </Box>
    );
  };
  const renderPhotos = () => {
    return (
      <>
        <SimpleGrid columns={2} spacing={3} mt={4}>
          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
          >
            <FormLabel py={1} m={0} textAlign='center'>
              {t('vehicleProfile')}
              {!formData.listingProfilePhoto && (
                <span style={{ color: 'red' }}> *</span>
              )}
            </FormLabel>
            <input
              type="file"
              id="listingProfilePhoto"
              ref={photo1Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept="image/*"
              capture="environment"
            />

            {loadingImages.listingProfilePhoto ? (
              <Flex
                h="150"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.listingProfilePhoto &&
                typeof formData.listingProfilePhoto === 'string' ? (
                  <Image
                    alt="listingProfilePhoto"
                    src={formData.listingProfilePhoto}
                  />
                ) : (
                  <Main width="100px" height="150px" />
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  isDisabled={editIsDisabled}
                  leftIcon={<AddIcon />}
                  onClick={handlePhoto1InputClick}
                >
                  {t('addPictureCapital')}
                </Button>
              </>
            )}

            <FormLabel
              p="1"
              color="gray"
              as="i"
              fontSize="sm"
              textAlign="center"
            >
              {t('frontOrSideView')}
            </FormLabel>
          </Stack>

          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
            capture="environment"
          >
            <FormLabel
              py={1}
              m={0}
              // bg='red'
              justify="center"
              textAlign='center'
              // color={!formData.vehicleOdometerPhoto && 'red'} //
            >
              {t('odometer')}
              {!formData.vehicleOdometerPhoto && (
                <span style={{ color: 'red' }}> *</span>
              )}
            </FormLabel>
            <input
              type="file"
              id="vehicleOdometerPhoto"
              ref={photo2Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept="image/*"
              capture="environment"
            />

            {loadingImages.vehicleOdometerPhoto ? (
              <Flex
                h="150"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.vehicleOdometerPhoto &&
                typeof formData.vehicleOdometerPhoto === 'string' ? (
                  <Image
                    alt="vehicleOdometerPhoto"
                    src={formData.vehicleOdometerPhoto}
                  />
                ) : (
                  <Odometer width="100px" height="150px" />
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  isDisabled={editIsDisabled}
                  leftIcon={<AddIcon />}
                  onClick={handlePhoto2InputClick}
                >
                  {t('addPictureCapital')}
                </Button>
              </>
            )}

            <FormLabel
              p="1"
              color="gray"
              as="i"
              fontSize="sm"
              textAlign="center"
            >
              {t('withReadingsClearlyVisible')}
            </FormLabel>
          </Stack>
          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
          >
            <FormLabel py={1} m={0} textAlign='center'>
              {t('licensePlate')}
              {!formData.vehicleLicensePhoto && (
                <span style={{ color: 'red' }}> *</span>
              )}
            </FormLabel>
            <input
              type="file"
              id="vehicleLicensePhoto"
              ref={photo3Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept="image/*"
              capture="environment"
            />

            {loadingImages.vehicleLicensePhoto ? (
              <Flex
                h="150"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.vehicleLicensePhoto &&
                typeof formData.vehicleLicensePhoto === 'string' ? (
                  <Image
                    alt="vehicleLicensePhoto"
                    src={formData.vehicleLicensePhoto}
                  />
                ) : (
                  <License width="100px" height="150px" />
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  isDisabled={editIsDisabled}
                  leftIcon={<AddIcon />}
                  onClick={handlePhoto3InputClick}
                >
                  {t('addPictureCapital')}
                </Button>
              </>
            )}

            <FormLabel
              p="1"
              color="gray"
              as="i"
              fontSize="sm"
              textAlign="center"
            >
              {t('canBeEitherFrontOrRear')}
              <Text style={{ textDecoration: 'underline' }} align="center">
                {t('notsharedUntilAcceptedOffer')}
              </Text>
            </FormLabel>
          </Stack>

          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
          >
            <FormLabel
              py={1}
              m={0}
              textAlign='center'
              // color={!formData.vehicleVINPhoto && 'red'} //
            >
              {t('vin')}
              {!formData.vehicleVINPhoto && (
                <span style={{ color: 'red' }}> *</span>
              )}
            </FormLabel>
            <input
              type="file"
              id="vehicleVINPhoto"
              ref={photo4Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept="image/*"
              capture="environment"
            />

            {loadingImages.vehicleVINPhoto ? (
              <Flex
                h="150"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.vehicleVINPhoto &&
                typeof formData.vehicleVINPhoto === 'string' ? (
                  <Image alt="vehicleVINPhoto" src={formData.vehicleVINPhoto} />
                ) : (
                  <VIN width="100px" height="150px" />
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  isDisabled={editIsDisabled}
                  onClick={handlePhoto4InputClick}
                >
                  {t('addPictureCapital')}
                </Button>
              </>
            )}

            <FormLabel
              p="1"
              color="gray"
              as="i"
              fontSize="sm"
              textAlign="center"
            >
              {t('locatedAboveDashboardOrInsideDoorFrame')}
              <Text style={{ textDecoration: 'underline' }}>
                {t('notsharedUntilAcceptedOffer')}
              </Text>
            </FormLabel>
          </Stack>

          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
          >
            <FormLabel
              py={1}
              m={0}
              textAlign="center"
              // color={!formData.vehicleRegistrationPhoto && 'red'} //
            >
              {t('registration')}
              {!formData.vehicleRegistrationPhoto && (
                <span style={{ color: 'red' }}> *</span>
              )}
            </FormLabel>
            <Input
              type="file"
              id="vehicleRegistrationPhoto"
              ref={photo5Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept=".jpg,.png,.jpeg,.pdf"
            />
            {loadingImages.vehicleRegistrationPhoto ? (
              <Flex
                h="150"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.vehicleRegistrationPhoto &&
                typeof formData.vehicleRegistrationPhoto === 'string' ? (
                  <Image
                    alt="vehicleRegistrationPhoto"
                    fallback={
                      <Button
                        variant="link"
                        colorScheme="primary"
                        py={16}
                        onClick={() =>
                          window.open(
                            listing.vehicleRegistrationPhoto,
                            '_blank'
                          )
                        }
                        rightIcon={<ExternalLinkIcon />}
                      >
                        {t('view')}
                      </Button>
                    }
                    src={formData.vehicleRegistrationPhoto}
                  />
                ) : (
                  <Registration width="100px" height="150px" />
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  isDisabled={editIsDisabled}
                  onClick={handlePhoto5InputClick}
                >
                  {t('addPicOrPDF')}
                </Button>
              </>
            )}

            <FormLabel
              p="1"
              color="gray"
              as="i"
              fontSize="sm"
              textAlign="center"
            >
              {t('withYourNameAndVINVisoble')}{' '}
              <Text style={{ textDecoration: 'underline' }}>
                {t('notsharedUntilAcceptedOffer')}
              </Text>
            </FormLabel>
          </Stack>

          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
          >
            <FormLabel
              py={1}
              m={0}
              textAlign='center'
              // color={!formData.vehicleInsurancePhoto && 'red'} //
            >
              {t('insurance')}
              {!formData.vehicleInsurancePhoto && (
                <span style={{ color: 'red' }}> *</span>
              )}
            </FormLabel>
            <Input
              type="file"
              id="vehicleInsurancePhoto"
              ref={photo6Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept=".jpg,.png,.jpeg,.pdf"
            />

            {loadingImages.vehicleInsurancePhoto ? (
              <Flex
                h="150px"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.vehicleInsurancePhoto &&
                typeof formData.vehicleInsurancePhoto === 'string' ? (
                  <Image
                    alt="vehicleInsurancePhoto"
                    fallback={
                      <Button
                        variant="link"
                        colorScheme="primary"
                        py={16}
                        onClick={() =>
                          window.open(listing.vehicleInsurancePhoto, '_blank')
                        }
                        rightIcon={<ExternalLinkIcon />}
                      >
                        {t('view')}
                      </Button>
                    }
                    src={formData.vehicleInsurancePhoto}
                  />
                ) : (
                  <Box py={'25px'}>
                    <Insurance width="100px" height="100px" />
                  </Box>
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  isDisabled={editIsDisabled}
                  onClick={handlePhoto6InputClick}
                >
                  {t('addPicOrPDF')}
                </Button>
              </>
            )}

            <FormLabel
              p="1"
              color="gray"
              as="i"
              fontSize="sm"
              textAlign="center"
            >
              {t('proofOfCurrentPolicy')}{' '}
              <Text style={{ textDecoration: 'underline' }}>
                {t('notsharedUntilAcceptedOffer')}
              </Text>
            </FormLabel>
          </Stack>

          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
          >
            <FormLabel py={1} m={0} textAlign="center">
              {t('inspection')}
            </FormLabel>
            <Input
              type="file"
              id="vehicleSafetyTest"
              ref={photo7Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept=".jpg,.png,.jpeg,.pdf"
            />

            {loadingImages.vehicleSafetyTest ? (
              <Flex
                h="150px"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.vehicleSafetyTest &&
                typeof formData.vehicleSafetyTest === 'string' ? (
                  <Image
                    alt="vehicleSafetyTest"
                    fallback={
                      <Button
                        variant="link"
                        colorScheme="primary"
                        py={16}
                        onClick={() =>
                          window.open(listing.vehicleSafetyTest, '_blank')
                        }
                        rightIcon={<ExternalLinkIcon />}
                      >
                        {t('view')}
                      </Button>
                    }
                    src={formData.vehicleSafetyTest}
                  />
                ) : (
                  <Document width="100px" height="150px" />
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  isDisabled={editIsDisabled}
                  onClick={handlePhoto7InputClick}
                >
                  {t('addPicOrPDF')}
                </Button>
              </>
            )}
            <FormLabel
              p="1"
              color="gray"
              as="i"
              fontSize="sm"
              textAlign="center"
            ></FormLabel>
          </Stack>

          <Stack
            direction="column"
            borderWidth={1}
            borderColor={'blackAlpha.500'}
            shadow="md"
            align="center"
            spacing={0}
          >
            <FormLabel py={1} m={0} textAlign='center'>
              {t('conditionReport')}
            </FormLabel>
            <Input
              type="file"
              id="vehicleConditionReport"
              ref={photo8Ref}
              style={{ display: 'none' }}
              onChange={onChangeFileInput}
              accept=".jpg,.png,.jpeg,.pdf"
            />

            {loadingImages.vehicleConditionReport ? (
              <Flex
                h="150px"
                w="full"
                // bg="lightgrey"
                justify="center"
                align="center"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                {formData.vehicleConditionReport &&
                typeof formData.vehicleConditionReport === 'string' ? (
                  <Image
                    alt="vehicleConditionReport"
                    fallback={
                      <Button
                        variant="link"
                        colorScheme="primary"
                        py={16}
                        onClick={() =>
                          window.open(listing.vehicleConditionReport, '_blank')
                        }
                        rightIcon={<ExternalLinkIcon />}
                      >
                        {t('view')}
                      </Button>
                    }
                    src={formData.vehicleConditionReport}
                  />
                ) : (
                  <Document width="100px" height="150px" />
                )}

                <Button
                  colorScheme="primary"
                  borderRadius={0}
                  py={5}
                  w="full"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  isDisabled={editIsDisabled}
                  onClick={handlePhoto8InputClick}
                >
                  {t('addPicOrPDF')}
                </Button>
                <FormLabel
                  p="1"
                  color="gray"
                  as="i"
                  fontSize="sm"
                  textAlign="center"
                >
                  {/* <Text style={{ textDecoration: 'underline' }}>
                  Not shared until accepted offer
                </Text> */}
                </FormLabel>
              </>
            )}
          </Stack>

          {formData.listingOtherPhotos &&
            formData.listingOtherPhotos.map((photo, i) => (
              <Stack
                id={'listingOtherPhoto' + i}
                borderWidth={1}
                direction="column"
                key={i}
                shadow="md"
                align="center"
                borderColor={'blackAlpha.500'}
              >
                <FormLabel textAlign='center'>
                  {t('other')} [{i + 1}]
                </FormLabel>

                <Image
                  alt="listingOtherPhotos"
                  fallback={
                    <Button
                      variant="link"
                      colorScheme="primary"
                      py={16}
                      onClick={() => window.open(photo, '_blank')}
                      rightIcon={<ExternalLinkIcon />}
                    >
                      {t('view')}
                    </Button>
                  }
                  src={photo}
                />
              </Stack>
            ))}

          <Input
            type="file"
            id="otherPhoto"
            ref={otherPhotoRef}
            style={{ display: 'none' }}
            onChange={onChangeFileInputOtherPhotos}
            accept=".jpg,.png,.jpeg,.pdf"
          />
        </SimpleGrid>
        <Button
          colorScheme="primary"
          borderRadius={0}
          mt={10}
          py={5}
          mb={5}
          size="sm"
          variant="outline"
          leftIcon={<AddIcon />}
          isDisabled={editIsDisabled}
          onClick={handleOtherPhotoInputClick}
        >
          {t('addOtherPicOrPDF')}
        </Button>
        {/* <Stack mt={5}>
          {detailsMissingItems.map((item, i) => (
            <Text key={i} color="red" as="i">
              * {item}
            </Text>
          ))}
        </Stack> */}
        {isDev && isEditing && !photosAreComplete && (
          <Box my={3} justifyContent="flex-end">
            <Button
              onClick={insertRandomPhotos}
              // w="full"
              size="sm"
              // pl={10}
              // flex={1}

              variant="link"
              color="lightgray"
            >
              <i>DEV Insert Random Photos</i>
            </Button>
          </Box>
        )}

        {!photosAreComplete && (
          <Text m={1} color="red" as="i" size="xs" mt={5}>
            * {t('requiredPhotos')}
          </Text>
        )}
        <Button
          onClick={() => {
            onClickSave(formData, true);
            // setTabIndex(tabIndex + 1);
          }}
          mb={5}
          w="full"
          colorScheme="primary"
          size="lg"
          disabled={!photosAreComplete}
        >
          {t('nextSection')}
        </Button>

        {/* {detailsMissingItems.length > 0 && (
          <Text mt={5} color="red">
            * {t('requiredToPublishEEZEListing')}
          </Text>
        )} */}
      </>
    );
  };
  let notAvailableWarning;
  if (!eligibleWarrantyProvinces.includes(listing?.province.toLowerCase())) {
    // neither eligible province
    notAvailableWarning = (
      <Alert
        status="warning"
        variant="left-accent"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon mr={0} />
        <AlertTitle mt={1} mb={1} fontSize="lg">
          {t('pleaseNote')}
        </AlertTitle>
        <AlertDescription maxWidth="lg">
          {t('forNowPremiumEEZEFeaturesAreOnlyAvailableInONandQC')}
          <br />
          <br />
          {t('thisProvinceListingIsOnlyEligibleForTheFreePlan', {
            province:
              listing.province.charAt(0).toUpperCase() +
              listing.province.slice(1),
          })}
        </AlertDescription>
      </Alert>
    );
  }

  const renderListing = () => {
    // get last 20 years for yearAcquired options
    const currentYear = new Date().getFullYear();
    const last20Years = [];

    for (let i = currentYear; i >= currentYear - 19; i--) {
      last20Years.push(i);
    }

    return (
      <>
        <FormControl>
          {editIsDisabled && (
            <Center>
              <Text color="primary.500">
                {t('atThisStageYouCannotEditThisListing')}
              </Text>
            </Center>
          )}
          <Stack
            pl={4}
            direction="row"
            alignItems="center"
            color={listing.status === LISTING.STATUS.DRAFT ? 'red' : ''}
          >
            <Text w="35%">{t('status')}:</Text>
            <Text>{listing.status}</Text>
            {listing.status === LISTING.STATUS.DRAFT && (
              <Box>
                {listingIsComplete && (
                  <Text color="blue.500" as="b">
                    {' '}
                    - {t('readyToPublish')}
                  </Text>
                )}
              </Box>
            )}
          </Stack>
        </FormControl>
        {listing.shortUrl && (
          <FormControl>
            <Stack pl={4} direction="row" alignItems="center">
              <Text w="35%">{t('EEZELink')}:</Text>
              <Text>{listing.shortUrl || ''}</Text>
            </Stack>
          </FormControl>
        )}

        {listing.eezeID && (
          <FormControl>
            <Stack pl={4} direction="row" alignItems="center">
              <Text w="35%">{t('EEZEID')}:</Text>
              <Text>{listing.eezeID ? listing.eezeID.toUpperCase() : ''}</Text>
            </Stack>
          </FormControl>
        )}

        {listing.postedAt && (
          <FormControl>
            <Stack pl={4} direction="row" alignItems="center">
              <Text w="35%">{t('posted')}:</Text>
              <Text>
                {moment(
                  listing.postedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY - h:mma')}
              </Text>
            </Stack>
          </FormControl>
        )}
        {listing.completedAt && (
          <FormControl>
            <Stack pl={4} direction="row" alignItems="center">
              <Text w="35%">{t('completed')}:</Text>
              <Text>
                {moment(
                  listing.completedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY - h:mma')}
              </Text>
            </Stack>
          </FormControl>
        )}

        {notAvailableWarning}
        
        <FormControl mt="2%">
          <FormLabel m={0} fontWeight="bold">
            {t('askingPrice')}
            {!formData.askingPrice && <span style={{ color: 'red' }}> *</span>}
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color={isEditing ? 'gray.800' : 'gray.400'}
              fontWeight="bold"
              fontSize="1.1em"
              children="$"
            />
            <Input
              id="askingPrice"
              type="number"
              w="60%"
              fontWeight="bold"
              value={formData.askingPrice}
              onChange={onChange}
              disabled={!isEditing || editIsDisabled}
            />
            <Select
              w="40%"
              id="currency"
              value={formData.currency}
              onChange={onChange}
              disabled
            >
              <option value="CAD">CAD</option>
              <option value="USD">USD</option>
            </Select>
          </InputGroup>
        </FormControl>
        {formData.finalPrice && (
          <FormControl mt="2%">
            <FormLabel m={0}>{t('finalPrice')}</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color={isEditing ? 'gray.800' : 'gray.400'}
                fontSize="1.1em"
                children="$"
              />
              <Input
                id="finalPrice"
                type="number"
                w="60%"
                value={formData.finalPrice}
                onChange={onChange}
                disabled
              />
              <Select
                w="40%"
                id="currency"
                value={formData.currency}
                onChange={onChange}
                disabled
              >
                <option value="CAD">CAD</option>
                <option value="USD">USD</option>
              </Select>
            </InputGroup>
          </FormControl>
        )}

        <Accordion allowToggle mt={2}>
          <AccordionItem>
            <AccordionButton pl={0}>
              <Box as="span" flex="1" textAlign="left">
              {t('otherInfo')}
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel
              pb={4}
              // bgColor="blackAlpha.50"
            >
              <FormControl mt="2%">
                <FormLabel m={0}>{t('description')}</FormLabel>
                <Textarea
                  placeholder="..."
                  rows={2}
                  id="description"
                  value={formData.description}
                  onChange={onChange}
                  // disabled={!isEditing}
                  disabled={editIsDisabled}
                />
              </FormControl>

              <FormControl mt="2%">
                <FormLabel m={0}>
                  {t('marketplaceLink')}
                  <Text as="span" fontSize="sm" ml={3}>
                    {t('includeHTTPS')}
                  </Text>
                </FormLabel>
                <Input
                  id="otherMarketplaceURL"
                  type="text"
                  placeholder="Facebook, Autotrader..."
                  value={formData.otherMarketplaceURL}
                  onChange={onChange}
                  disabled={editIsDisabled}
                />
              </FormControl>

              <FormControl mt="2%">
                <FormLabel m={0}>
                  {t('CARFAXVehicleHistoryReportLink')}
                </FormLabel>
                <Input
                  id="vehicleCarfaxVHRManual"
                  type="text"
                  placeholder={t('enterLink')}
                  value={formData.vehicleCarfaxVHRManual}
                  onChange={onChange}
                  disabled={editIsDisabled}
                />
              </FormControl>

              {/* <FormControl mt="2%">
                <FormLabel m={0}>{t('condition')}</FormLabel>
                <InputGroup alignItems="center">
                  <Select
                    w="100%"
                    id="condition"
                    value={formData.condition}
                    onChange={onChange}
                    disabled={editIsDisabled}
                  >
                    <option value=""></option>
                    <option value="Rough">{t('rough')}</option>
                    <option value="Average">{t('average')}</option>
                    <option value="Clean">{t('clean')}</option>
                  </Select>
                </InputGroup>
              </FormControl> */}

              {/* <FormControl mt="2%">
                <FormLabel m={0}>{t('ownershipIsThisVehicle')}</FormLabel>
                <InputGroup alignItems="center">
                  <Select
                    w="100%"
                    id="ownership"
                    value={formData.ownership}
                    onChange={onChange}
                    disabled={editIsDisabled}
                  >
                    <option value=""></option>
                    <option value="owned">{t('owned')}</option>
                    <option value="leased">{t('leased')}</option>
                    <option value="company">{t('companyCar')}</option>
                  </Select>
                </InputGroup>
              </FormControl> */}

              {/* <FormControl mt="2%">
                <FormLabel m={0}>
                  {t('whatYearDidYouAquireThisVehicle')}
                </FormLabel>
                <InputGroup alignItems="center">
                  <Select
                    w="100%"
                    id="yearAcquired"
                    value={formData.yearAcquired}
                    onChange={onChange}
                    disabled={editIsDisabled}
                  >
                    <option value=""></option>
                    {last20Years.map((year, i) => (
                      <option key={i} value={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </FormControl> */}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {!listing.vehicleCarfaxVHR ? (
          <>
            {listing.vehicleCarfaxVHRPending ? (
              <Text mt={5} textAlign="center" color="blue.500">
                {t('reportIsBeingGeneratedPleaseStandby')}
              </Text>
            ) : (
              <Text as="i">{/* {t('seeCarfaxTabToOrder')} ($) */}</Text>
            )}
          </>
        ) : (
          <Center mt={5}>
            <Button
              colorScheme="primary"
              variant="link"
              rightIcon={<ExternalLinkIcon />}
              onClick={() => window.open(listing.vehicleCarfaxVHR, '_blank')}
            >
              {t('clickHereToViewReport')}
            </Button>
          </Center>
        )}

        <Stack mt={5}>
          {detailsMissingItems.map((item, i) => (
            <Text key={i} color="red" as="i">
              * {item}
            </Text>
          ))}
          {listingMissingItems.map((item, i) => (
            <Text key={i} color="red" as="i">
              * {item}
            </Text>
          ))}
        </Stack>

        {listing.status === LISTING.STATUS.DRAFT && (
          <>
            {!userData.stageTwoVerified ? (
              <>
                <Text
                  mt={5}
                  // color="red"
                  textAlign="center"
                >
                  {t('saveForNowOnlyPublishOnceVerified')}
                </Text>
                <Button m={0} w="full" colorScheme="blue" size="lg" disabled>
                  {t('saveAndPublishCapital')}
                </Button>
              </>
            ) : (
              <>
                  <Button
                    onClick={onClickSaveAndSubmitForReview}
                    m={0}
                    w="full"
                    disabled={!listingIsComplete}
                    colorScheme="blue"
                    size="lg"
                  >
                    {t('saveAndPublishCapital')}
                  </Button>
              </>
            )}
          </>
        )}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </>
    );
  };

  if (loading) {
    return <SpinnerPage />;
  }
  // console.log(formData)
  return (
    <>
      <Header back />
      <AppBody>
      {formData.title ? (
          <Heading size="lg" textAlign="center">
            {formData.title}
          </Heading>
        ) : (
          <Heading size="sm" textAlign="center">
            {t('vehicle')}
          </Heading>
        )}

        {listing.postedAt && (
          <ButtonGroup w={'100%'} my={5}>
            <Button
              colorScheme="primary"
              w="50%"
              leftIcon={<BsTools />}
              onClick={() => {
                onClickSave(formData);
                navigate(`/toolkit/${listing.uid}`);
              }}
            >
              {t('salesToolkit')}
            </Button>

            <Button
              leftIcon={<BsEye />}
              w="50%"
              colorScheme="primary"
              onClick={() => {
                onClickSave(formData);
                navigate(`/listing/${listing.uid}`);
              }}
            >
              {t('listingPage')}
            </Button>
          </ButtonGroup>
        )}

        <Container maxW={'2xl'} p={0} mt={2}>
          <Tabs
            isFitted
            variant="enclosed-colored"
            onChange={handleTabsChange}
            index={tabIndex}
          >
            <TabList mb="1em">
              <Tab>
                {t('details')}
                {!descriptionIsComplete && (
                  <Text ml={1} color="red">
                    *
                  </Text>
                )}
              </Tab>
              <Tab isDisabled={formData?.fullVehicle ? false : true}>
                {t('photos')}
                {!photosAreComplete && (
                  <Text ml={1} color="red">
                    *
                  </Text>
                )}
              </Tab>

              <Tab isDisabled={formData?.fullVehicle ? false : true}>
                {t('listing')}
                {!listingIsComplete && (
                  <Text ml={1} color="red">
                    *
                  </Text>
                )}
              </Tab>

              {/* <Tab isDisabled={descriptionIsComplete ? false : true}>
                {t('warranty')}
              </Tab> */}
              {/* <Tab isDisabled={descriptionIsComplete ? false : true}>
                {t('carfax')}
              </Tab> */}
            </TabList>

            <TabPanels p={0} m={0}>
              <TabPanel p={0} m={0}>
                {renderDescription()}
              </TabPanel>
              <TabPanel p={0} m={0}>
                {renderPhotos()}
              </TabPanel>

              <TabPanel p={0} m={0}>
                {renderListing()}
              </TabPanel>

              {/* <TabPanel p={0} m={0}>
                <Box p={0} m={0} minHeight="70vh">
                  {notAvailableWarning ? notAvailableWarning : <WarrantyPage />}
                </Box>
              </TabPanel> */}
              {/* <TabPanel p={0} m={0}>
                <Box p={0} m={0} minHeight="70vh">
                  {notAvailableWarning ? notAvailableWarning : <CarfaxPage />}
                </Box>
              </TabPanel> */}
            </TabPanels>
          </Tabs>

          <br />
          <br />
          <br />
          <br />
          {formData?.fullVehicle && (
            <>
              <ButtonGroup my={4} alignItems="center" w="100%">
                <Button
                  variant="outline"
                  size={'sm'}
                  onClick={onClickCancel}
                  isLoading={isSavingForm}
                  flex={1}
                >
                  {t('cancel')}
                </Button>
                <Button
                  colorScheme="primary"
                  size={'sm'}
                  variant="outline"
                  flex={1}
                  onClick={() => {
                    onClickSave(formData);
                  }}
                >
                  {t('saveCapital')}
                </Button>
              </ButtonGroup>
              <br />
            </>
          )}
          <Flex justify="space-between" direction="row" my="10px">
            {!listing.warrantyActive &&
            !listing.warrantyPending &&
            !listing.vehicleCarfaxVHR &&
            !listing.vehicleCarfaxVHRPending ? (
              <Button
                color="grey"
                variant="link"
                // px={10}
                w="50%"
                size={'sm'}
                onClick={onClickDelete}
                isLoading={isSavingForm}
              >
                {t('delete')}
              </Button>
            ) : (
              <Text></Text>
            )}
            <Text color="grey" w='50%'>
              {t('created')}:{' '}
              {moment(
                listing.createdAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY')}
            </Text>
          </Flex>
        </Container>
      </AppBody>
    </>
  );
}

export default EditListingVehicle;
