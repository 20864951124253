import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Badge,
  Button,
  ButtonGroup,
  Box,
  Container,
  Center,
  Divider,
  SimpleGrid,
  Flex,
  Image,
  List,
  ListItem,
  Heading,
  HStack,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';
import {
  BsCheckCircle,
  BsHeart,
  BsHeartFill,
  BsChevronLeft,
  BsHouse,
} from 'react-icons/bs';
import { ExternalLinkIcon, EditIcon } from '@chakra-ui/icons';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FaRegShareSquare } from 'react-icons/fa';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import ListingPageCarousel from '../components/ListingPageCarousel';
import { db, LISTING, eligibleWarrantyProvinces } from '../constants';
import logoImage from '../assets/shield256.png';
import SpinnerEEZEPage from '../components/SpinnerEEZEPage';
import Header from '../components/Header';
import AppBody from '../components/AppBody';
import ShareModal from '../components/ShareModal';
import ListingWarrantyOptions from '../components/ListingWarrantyOptions';
import IDCard from '../components/IDCard';
import currencyFormat from '../services/currencyFormat';
import DataContext from '../context/DataContext';
import odometerFormat from '../services/odometerFormat';

function Listing() {
  const [listing, setListing] = useState(null);
  const [listingOwner, setListingOwner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inFavorites, setInFavorites] = useState(false);
  const [queryParameters] = useSearchParams();

  const navigate = useNavigate();
  const params = useParams();
  const auth = getAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFavs,
    onOpen: onOpenFavs,
    onClose: onCloseFavs,
  } = useDisclosure();
  const { userData } = useContext(DataContext);

  const ref = queryParameters.get('ref');
  const refSlug = ref ? '&ref='+ref : '';

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params?.listingUid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const listing = docSnap.data();
        // check if this listing is already in favorites
        let favoriteListings = [];
        if (userData && userData?.favoriteListings) {
          favoriteListings = userData.favoriteListings;
        }
        const added = favoriteListings.indexOf(listing.uid) > -1;
        setInFavorites(added);
        setListing(docSnap.data());
        // also get listing owner info for id card
        const userRef = doc(db, 'users', docSnap.data().sellerUid);
        const userSnap = await getDoc(userRef);

        setListingOwner(userSnap.data());
        setLoading(false);
      } else {
        window.alert('Listing does not exist');
        navigate('/');
      }
    };

    fetchListing();
  }, [navigate, params.listingUid, userData]);

  if (loading) {
    return <SpinnerEEZEPage />;
  }

  const contactSeller = () => {
    if (!userData.stageOneVerified) {
      window.alert('You must update your Profile before contacting the Seller');
      return;
    }
    navigate('/connect/' + listing.uid + '/' + userData.uid);
  };

  const addToFavorites = () => {
    if (!auth.currentUser || !userData) {
      window.alert('You must login or signup to add to your favorites');
      return;
    }
    // do not add to favs if the user is the seller
    if (auth.currentUser.uid === listing.sellerUid) {
      window.alert('No need to add your own listing to your favorites');
      return;
    }

    // get array fo favorites from user
    let favoriteListings = [];
    if (userData.favoriteListings) {
      favoriteListings = userData.favoriteListings;
    }
    // // check if this listing is already in favorites
    // const alreadyAdded = favoriteListings.indexOf(listing.uid) > -1;
    // // console.log('arraycontains', arraycontains)

    if (inFavorites) {
      onOpenFavs();
      // window.alert('Already added to favorites. Go to homepage to view');
    } else {
      // add this listing to that array
      favoriteListings.push(listing.uid);

      // save the array to the db
      const userRef = doc(db, 'users', auth.currentUser.uid);

      updateDoc(userRef, {
        favoriteListings,
      });
      onOpenFavs();
      // window.alert('Added to favorites. Go to homepage to view');
    }
  };

  const carouselPhotos = [];
  if (listing.listingProfilePhoto) {
    carouselPhotos.push(listing.listingProfilePhoto);
  }
  if (listing.vehicleOdometerPhoto) {
    carouselPhotos.push(listing.vehicleOdometerPhoto);
  }
  // if (listing.vehicleLicensePhoto) {
  //   carouselPhotos.push(listing.vehicleLicensePhoto);
  // }
  // if (listing.vehicleVINPhoto) {
  //   carouselPhotos.push(listing.vehicleVINPhoto);
  // }
  // if (listing.listingOtherPhotos?.length !== 0) {
  if (listing.listingOtherPhotos) {
    listing.listingOtherPhotos.map(photo => carouselPhotos.push(photo));
  }

  let listingIsActive = true;

  if (listing.status === LISTING.STATUS.DRAFT) {
    listingIsActive = false;
  }
  if (listing.status === LISTING.STATUS.PRIVATE) {
    listingIsActive = false;
  }
  if (listing.status === LISTING.STATUS.COMPLETED) {
    listingIsActive = false;
  }
  let eligibleProvince = true;
  if (!eligibleWarrantyProvinces.includes(listing?.province.toLowerCase())) {
    eligibleProvince = false;
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ShareModal url={listing.shortUrl} />
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenFavs} onClose={onCloseFavs}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Heading mt={5}>Added to Favorites</Heading>
            <Text mt={10} mb={5}>
              Go to Dashboard to Transact
            </Text>
            <Button
              onClick={() => navigate('/')}
              width="full"
              colorScheme="primary"
            >
              To Dashboard
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onCloseFavs}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Header />
      <AppBody>
        {listing.status === LISTING.STATUS.COMPLETED && (
          <>
            <Divider borderColor="primary.500" borderWidth={1} />
            <Center align="center">
              <Heading color={'primary.500'}>- SOLD -</Heading>
            </Center>
            <Divider borderColor="primary.500" borderWidth={1} />
          </>
        )}
        {!listingIsActive && (
          <Center>
            <Heading color="primary.500" as="h2" size="2xl" textAlign="center">
              THIS LISTINGS IS NOT ACTIVE
            </Heading>
          </Center>
        )}
        <HStack justify="space-between">
          <Box>
            {listing.eezeID && (
              <Text as="b">ID: {listing.eezeID.toUpperCase()}</Text>
            )}
            <Text fontSize="sm">{listing?.shortUrl}</Text>
          </Box>

          <ButtonGroup>
            <Button
              variant="link"
              onClick={addToFavorites}
              disabled={!listingIsActive}
            >
              {inFavorites ? (
                <BsHeartFill fontSize={'25px'} color="red" />
              ) : (
                <BsHeart fontSize={'25px'} color="black" />
              )}
            </Button>

            <Button variant="link" onClick={onOpen} disabled={!listingIsActive}>
              {/* <IoMdShare fontSize={'25px'} /> */}
              <FaRegShareSquare fontSize={'30px'} color="black" />
            </Button>
          </ButtonGroup>
        </HStack>

        <Flex m={0} p={0}>
          <ListingPageCarousel photos={carouselPhotos} />
        </Flex>

        <Stack>
          <Box>
            <Text fontSize={'xl'} fontWeight={'300'}>
              {listing.title} {listing.style}
            </Text>
            <HStack justify="space-between">
              <Text fontWeight={'200'}>
                {currencyFormat(listing.askingPrice, 0)}
              </Text>
              <Text>
                {odometerFormat(listing.odometer) + ' '}
                {listing.odometerType}
              </Text>
            </HStack>

            {listing.otherMarketplaceURL && (
              <Center>
                <a
                  style={{ color: 'blue' }}
                  href={
                    listing.otherMarketplaceURL.includes('//')
                      ? listing.otherMarketplaceURL
                      : '//' + listing.otherMarketplaceURL
                  }
                  target="_blank"
                  // rel="noreferrer"
                  rel="noopener noreferrer"
                >
                  Click here for marketplace listing
                  <ExternalLinkIcon ml="5px" />
                </a>
              </Center>
            )}
          </Box>
          <Divider borderColor="primary.500" borderWidth={1} />
          <Stack spacing={{ base: 1, sm: 4 }} direction={'column'}>
            {eligibleProvince ? (
              <Stack direction="row" justify="space-between">
                <Image
                  src={logoImage}
                  alt="Logo"
                  boxSize="40px"
                  objectFit="cover"
                />
                <Text align="center">
                  This vehicle is sold with a 90-day 3,000 km EEZE Powertrain
                  Warranty
                </Text>
              </Stack>
            ) : (
              <Center>
                <Image
                  src={logoImage}
                  alt="Logo"
                  boxSize="40px"
                  objectFit="cover"
                />
              </Center>
            )}
            <Divider borderColor="primary.500" borderWidth={1} />

            <Text
              fontSize={{ base: '16px', lg: '18px' }}
              color={'primary.800'}
              fontWeight={'500'}
              mb={'4'}
            >
              OWNER
            </Text>

            <IDCard
              listing={listing}
              userData={listingOwner}
              flat
            />

            {/* <Box> */}
            {/* <List spacing={1}> */}
            {listing.fullVehicle && (
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton pl={0}>
                    <Box as="span" flex="1" textAlign="left">
                      VIN Details
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel
                    pb={4}
                    // bgColor='gray.100'
                    bgColor="blackAlpha.50"
                  >
                    <Box>
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                        <List spacing={2}>
                          {listing.colorExterior && (
                            <ListItem>
                              <Text as={'span'}>Exterior Color:</Text>{' '}
                              {listing.colorExterior}
                            </ListItem>
                          )}
                          {listing.colorInterior && (
                            <ListItem>
                              <Text as={'span'}>Interior Color:</Text>{' '}
                              {listing.colorInterior}
                            </ListItem>
                          )}
                          {/* these are the fields from digital inspect */}
                          {listing?.drivetrain && (
                            <ListItem>
                              Drivetrain: {listing?.drivetrain}
                            </ListItem>
                          )}
                          {listing?.transmissionType && (
                            <ListItem>
                              Transmission: {listing?.transmissionType}
                            </ListItem>
                          )}
                          {listing?.bodyType && (
                            <ListItem>Body Type: {listing?.bodyType}</ListItem>
                          )}
                          {listing?.engineType && (
                            <ListItem>Engine: {listing?.engineType}</ListItem>
                          )}

                          {/* these are the fields from decode */}
                          {listing.fullVehicle?.drivenWheels && (
                            <ListItem>
                              Traction: {listing.fullVehicle?.drivenWheels}
                            </ListItem>
                          )}
                          {listing.fullVehicle?.transmission
                            ?.transmissionType && (
                            <ListItem>
                              Transmission:{' '}
                              {
                                listing.fullVehicle?.transmission
                                  ?.transmissionType
                              }
                            </ListItem>
                          )}

                          {listing.fullVehicle?.categories?.epaClass && (
                            <ListItem>
                              EPA Class:{' '}
                              {listing.fullVehicle?.categories?.epaClass}
                            </ListItem>
                          )}

                          {listing.fullVehicle?.engine?.horsepower && (
                            <ListItem>
                              Horsepower:{' '}
                              {listing.fullVehicle?.engine?.horsepower} hp
                            </ListItem>
                          )}

                          {listing.fullVehicle?.engine?.torque && (
                            <ListItem>
                              Torque: {listing.fullVehicle?.engine?.torque}{' '}
                              lb-ft
                            </ListItem>
                          )}
                          {listing.fullVehicle?.engine?.totalValves && (
                            <ListItem>
                              Valves: {listing.fullVehicle?.engine?.totalValves}
                            </ListItem>
                          )}
                          {listing.fullVehicle?.mpg?.highway && (
                            <ListItem>
                              MPG Highway: {listing.fullVehicle?.mpg?.highway}
                            </ListItem>
                          )}
                          {listing.fullVehicle?.mpg?.city && (
                            <ListItem>
                              MPG City: {listing.fullVehicle?.mpg?.city}
                            </ListItem>
                          )}
                          {listing.fullVehicle?.price && (
                            <>
                              <ListItem>
                                MSRP: ${listing.fullVehicle?.price?.baseMsrp}
                              </ListItem>
                              {/* <ListItem>
                                Retail: $
                                {listing.fullVehicle?.price?.usedTmvRetail}
                              </ListItem>
                              <ListItem>
                                Used: $
                                {listing.fullVehicle?.price?.usedPrivateParty}
                              </ListItem>
                              <ListItem>
                                TradeIn: $
                                {listing.fullVehicle?.price?.usedTradeIn}
                              </ListItem> */}
                            </>
                          )}
                        </List>
                      </SimpleGrid>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}

            {(listing.description ||
              listing.condition ||
              listing.ownership ||
              listing.yearAcquired) && (
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton pl={0}>
                    <Box as="span" flex="1" textAlign="left">
                      Description
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel
                    // pb={4}
                    // bgColor='gray.100'
                    bgColor="blackAlpha.50"
                  >
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                      <List spacing={0}>
                        {listing.condition && (
                          <ListItem>
                            <Text as={'span'}>Condition:</Text>{' '}
                            {listing.condition}
                          </ListItem>
                        )}

                        {listing.ownership && (
                          <ListItem>
                            <Text as={'span'}>Leased or Owned:</Text>{' '}
                            {listing.ownership}
                          </ListItem>
                        )}

                        {listing.yearAcquired && (
                          <ListItem>
                            <Text as={'span'}>Year Aquired:</Text>{' '}
                            {listing.yearAcquired}
                          </ListItem>
                        )}
                        <ListItem>
                          {listing.description && (
                            <Textarea
                              mt={2}
                              isReadOnly
                              variant="outline"
                              defaultValue={listing.description}
                            />
                          )}
                        </ListItem>
                      </List>
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}

            <Stack direction="row" justify="space-between" align="center">
              <Text>EEZE Verification</Text>
              {listing.verifiedBy ? (
                <Badge variant="outline" colorScheme="blue" bg="white">
                  VERIFIED
                </Badge>
              ) : (
                <Text as="i">Under Review</Text>
              )}
            </Stack>
            <>
              <Stack direction="row" pl={5}>
                {listing.verifiedBy ? (
                  <BsCheckCircle fontSize={'20px'} />
                ) : (
                  <IoIosCloseCircleOutline fontSize={'20px'} />
                )}
                <Text fontSize="sm">Ownership and Insurance Validation</Text>
              </Stack>
              <Stack direction="row" pl={5}>
                {listing.verifiedBy ? (
                  <BsCheckCircle fontSize={'20px'} />
                ) : (
                  <IoIosCloseCircleOutline fontSize={'20px'} />
                )}
                <Text fontSize="sm">Stolen Motor Vehicle Database Check</Text>
              </Stack>
              <Stack direction="row" pl={5}>
                {listing.verifiedBy ? (
                  <BsCheckCircle fontSize={'20px'} />
                ) : (
                  <IoIosCloseCircleOutline fontSize={'20px'} />
                )}
                <Text fontSize="sm">Location Verification</Text>
              </Stack>
            </>

            <HStack justify="space-between">
              <Text pt={2}>Inspection</Text>
              {listing.vehicleSafetyTest ? (
                <Button
                  variant="link"
                  as="u"
                  colorScheme="primary"
                  onClick={() =>
                    window.open(listing.vehicleSafetyTest, '_blank')
                  }
                >
                  Click to view
                </Button>
              ) : (
                <Text fontSize="sm" as="i">
                  -
                </Text>
              )}
            </HStack>

            {listing.vehicleConditionReport && (
              <HStack justify="space-between">
                <Text pt={2}>Condition Report</Text>
                <Button
                  variant="link"
                  as="u"
                  colorScheme="primary"
                  onClick={() =>
                    window.open(listing.vehicleConditionReport, '_blank')
                  }
                >
                  Click to view
                </Button>
              </HStack>
            )}

            <HStack justify="space-between">
              <Text>EEZE Digital Condition Report</Text>

              {!auth.currentUser ? (
                <Text as="i">Login Required</Text>
              ) : (
                <>
                  {listing.vehicleDigitalConditionReport ? (
                    <Button
                      variant="link"
                      colorScheme="primary"
                      as="u"
                      onClick={() =>
                        window.open(
                          listing.vehicleDigitalConditionReport,
                          '_blank'
                        )
                      }
                    >
                      Click to view
                    </Button>
                  ) : (
                    <>
                      <Text fontSize="sm" as="i">
                        Available ($)
                      </Text>
                    </>
                  )}
                </>
              )}
            </HStack>

            <HStack justify="space-between">
              <Text>CARFAX Vehicle History Report</Text>

              {!auth.currentUser ? (
                <Text as="i">Login Required</Text>
              ) : (
                <>
                  {listing.vehicleCarfaxVHR ||
                  listing.vehicleCarfaxVHRManual ? (
                    <Button
                      variant="link"
                      colorScheme="primary"
                      as="u"
                      onClick={() => {
                        if (listing.vehicleCarfaxVHR) {
                          return window.open(
                            listing.vehicleCarfaxVHR,
                            '_blank'
                          );
                        } else {
                          return window.open(
                            listing.vehicleCarfaxVHRManual,
                            '_blank'
                          );
                        }
                      }}
                    >
                      Click to view
                    </Button>
                  ) : (
                    <>
                      <Text fontSize="sm" as="i">
                        Available ($)
                      </Text>
                    </>
                  )}
                </>
              )}
            </HStack>
          </Stack>
          <Divider borderColor="primary.500" borderWidth={1} />
          <Text align="center">Why use EEZE to Transact?</Text>
          <Text align="center" fontSize="sm">
            EEZE protects private auto sales from fraud by verifying people and
            vehicles, as well as securing the money transfer
          </Text>
          {eligibleProvince && <ListingWarrantyOptions />}
        </Stack>
        <br />
        <br />
        <br />
        <br />
        <br />
      </AppBody>

      <Container
        position="fixed"
        bottom="0"
        bgColor="white"
        py="5"
        borderWidth="1px"
        maxW="full"
        centerContent
      >
        {!auth.currentUser ? (
          <Box px={5}>
            <Center mb={2}>
              <Text color={'primary.800'} textAlign="center">
                Login / Sign up to contact the Seller
              </Text>
            </Center>
            <Stack direction="row" w="full">
              <Button
                flex="1"
                onClick={() => navigate('/login?uid=' + listing.uid + refSlug)}
                colorScheme="primary"
                variant="outline"
              >
                Login
              </Button>
              <Button
                flex="1"
                onClick={() => navigate('/signup?uid=' + listing.uid + refSlug)}
                colorScheme="primary"
              >
                Sign Up
              </Button>
            </Stack>
          </Box>
        ) : (
          <>
            {auth.currentUser.uid !== listing.sellerUid ? (
              <Box px={5}>
                <HStack>
                  <Button
                    size={'lg'}
                    colorScheme="primary"
                    onClick={contactSeller}
                    disabled={!listingIsActive}
                  >
                    BUY / OFFER
                  </Button>
                  <Box flex={1}>
                    <Text align="center">Accepting credit card payments!</Text>
                  </Box>
                </HStack>
              </Box>
            ) : (
              <>
                <ButtonGroup px={2}>
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    leftIcon={<BsChevronLeft />}
                    onClick={() => navigate('/')}
                  >
                    <BsHouse />
                  </Button>
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={() => navigate(`/toolkit/${listing.uid}`)}
                  >
                    Sales Toolkit
                  </Button>
                  <Button
                    colorScheme="primary"
                    rightIcon={<EditIcon />}
                    onClick={() => navigate(`/edit/${listing.uid}`)}
                  >
                    Edit
                  </Button>
                </ButtonGroup>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default Listing;
