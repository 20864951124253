import { useContext } from 'react';

import {
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import WarrantyPage from './WarrantyPage';
import CarfaxPage from './CarfaxPage';
import DataContext from '../context/DataContext';

function VehicleAddonsTabs() {
  const dataContext = useContext(DataContext);
  const {userData} = dataContext;
  return (
    <>
      <Tabs isFitted 
      // variant="enclosed-colored"
      variant='unstyled'
      >
        <TabList mb="1em">
          <Tab>
            {/* Warranty */}
            </Tab>
          {/* <Tab>CARFAX</Tab> */}
        </TabList>
        <TabPanels p={0} m={0}>
          <TabPanel p={0} m={0}>
            <WarrantyPage hideBank={!userData?.stageOneVerified}/>
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CarfaxPage hideBank={!userData?.stageOneVerified}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default VehicleAddonsTabs;
