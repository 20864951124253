import { useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  Box,
  RadioGroup,
  Radio,
  Text,
  Badge,
  Center,
} from '@chakra-ui/react';
import { updateDoc } from 'firebase/firestore';

import equifaxGetKba from '../services/equifaxGetKba';
import equifaxSubmitKba from '../services/equifaxSubmitKba';
import SpinnerPage from '../components/SpinnerPage';
import stageTwoVerifyUser from '../services/stageTwoVerifyUser';
import {
  isDev,
} from '../constants';

function CreditQuestionnaire({userData, userRef}) {
  const [questions, setQuestions] = useState(userData?.kba?.questions || null);
  const [error, setError] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [loading, setLoading] = useState(false);

  const getKBA = async () => {
    setLoading(true);
    const kba = await equifaxGetKba();
    // console.log(kba);
    if (kba.success) {
      setQuestions(kba.data);
      setError(null)
    } else {
      setError('Could not retrieve questions, please try again');
    }
    setLoading(false);
  };

  const manualApproveKba = async () => {
    // send answers
    // if not passed, add to kbaError
    // where user can resubmit one time
    // await updateDoc(userRef, {
    //   kbaError: ['did not pass checks'],
    //   kbaPassed: false,
    //   kbaPassedAt: '',
    // });
    // if passed, update sb
    await updateDoc(userRef, {
      kbaError: '',
      kbaPassed: true,
      kbaPassedAt: new Date().toString(),
      stageOneVerified: true,
      stageOneVerifiedAt: new Date().toString(),
    });
    // have server perform final stage2 verifications
    stageTwoVerifyUser(userData?.uid, userData?.address?.postal);
  };

  const handleNext = () => {
    if (currentAnswer !== null) {
      setQuestionAnswers(prevAnswers => {
        const existingAnswerIndex = prevAnswers.findIndex(
          a => a.questionId === questions[currentQuestion].questionId
        );
        if (existingAnswerIndex !== -1) {
          const newAnswers = [...prevAnswers];
          newAnswers[existingAnswerIndex] = {
            questionId: questions[currentQuestion].questionId,
            answer: [currentAnswer],
          };
          return newAnswers;
        } else {
          return [
            ...prevAnswers,
            {
              questionId: questions[currentQuestion].questionId,
              answer: [currentAnswer],
            },
          ];
        }
      });
      setCurrentAnswer(null);
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      const previousAnswer = questionAnswers.find(
        a => a.questionId === questions[currentQuestion - 1].questionId
      );
      setCurrentAnswer(previousAnswer ? previousAnswer.answer[0] : null);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (currentAnswer !== null) {
      setQuestionAnswers(prevAnswers => {
        const existingAnswerIndex = prevAnswers.findIndex(
          a => a.questionId === questions[currentQuestion].questionId
        );
        if (existingAnswerIndex !== -1) {
          const newAnswers = [...prevAnswers];
          newAnswers[existingAnswerIndex] = {
            questionId: questions[currentQuestion].questionId,
            answer: [currentAnswer],
          };
          return newAnswers;
        } else {
          return [
            ...prevAnswers,
            {
              questionId: questions[currentQuestion].questionId,
              answer: [currentAnswer],
            },
          ];
        }
      });
      const kbaQuestionAnswers = [
          ...questionAnswers,
          {
            questionId: questions[currentQuestion].questionId,
            answer: [currentAnswer],
          },
        ]

      // console.log('Final answers:', kbaQuestionAnswers);
      // answers saved to db, server will verify
      await updateDoc(userRef, {
        kbaQuestionAnswers
      });
      equifaxSubmitKba();
    }
  };

  const renderQuestion = () => {
    const question = questions[currentQuestion];

    return (
      <Box mb={6}>
        <Center mb={2}>
          <Badge variant="outline">Question {question.questionId}</Badge>
        </Center>
        <Text fontSize="lg" fontWeight="semibold" mb={4}>
          {question.questionText}
        </Text>

        <RadioGroup
          value={currentAnswer}
          onChange={value => setCurrentAnswer(parseInt(value))}
        >
          {question.choices.map(choice => (
            <Box
              key={choice.choiceId}
              w="100%"
              onClick={() => setCurrentAnswer(choice.choiceId)}
              _hover={{ bg: 'gray.100' }}
              p={2}
            >
              <Radio value={choice.choiceId}>
                <Text flex="1">{choice.choiceText} 

                  {isDev && choice.isCorrect && 
                  <span style={{ color: 'blue', fontSize: '1.1em'}}>
                  *
                  </span>
                  }
                  </Text>
              </Radio>
            </Box>
          ))}
        </RadioGroup>
      </Box>
    );
  };

  // const renderError = () => {
  //   return (
  //     <>
  //       {/* {!kbaError ? (
  //                   <>
  //                   // show questions
  //                   </>
  //                 ) : (
  //                   <>
  //                     <Alert
  //                       status="error"
  //                       variant="subtle"
  //                       flexDirection="column"
  //                       alignItems="center"
  //                       justifyContent="center"
  //                       textAlign="center"
  //                       height="200px"
  //                     >
  //                       <AlertIcon boxSize="40px" />
  //                       <AlertTitle mt={4} mb={1} fontSize="lg">
  //                         {t('couldNotProcess')}
  //                       </AlertTitle>
  //                       <AlertDescription maxWidth="sm">
  //                         {kbaError}
  //                       </AlertDescription>
  //                     </Alert>
  //                     <FormControl mt="2%">
  //                       <FormLabel m={0}>
  //                         {t('sendUsYourCommentsAndWellContactYou')}
  //                       </FormLabel>
  //                       <Textarea
  //                         placeholder={t('addComments')}
  //                         rows={3}
  //                         id="kbaErrorComments"
  //                         value={formData.kbaErrorComments}
  //                         onChange={onChange}
  //                         disabled={kbaErrorComments}
  //                       />
  //                     </FormControl>
  //                     {!kbaErrorComments ? (
  //                       <Button
  //                         mt={10}
  //                         w="full"
  //                         colorScheme="primary"
  //                         id="kbaErrorComments"
  //                         onClick={sendErrorComments}
  //                       >
  //                         {t('sendComments')}
  //                       </Button>
  //                     ) : (
  //                       <Center mt={5}>
  //                         <Text as="i" color="red">
  //                           {t('thankYouCommentsReceivedWeAreInvestigating')}
  //                         </Text>
  //                       </Center>
  //                     )}
  //                   </>
  //                 )} */}
  //     </>
  //   );
  // };

  if (loading) {
    return <SpinnerPage />;
  }

  if (userData?.kbaError) {
    return (
      <>
      <Center textAlign="center">
      <Text color='red'>{userData.kbaError}</Text>
      </Center>
      </>
    );
  }
  if (!questions) {
    return (
      <>
        <Center>
          <Text textAlign="center">
            EEZE has partnered with Equifax to verify your identity using
            questions unique to your credit history. This will not impact your
            credit score
          </Text>
        </Center>
        {error && <Text textAlign='center' color='red' fontSize='lg'>{error}</Text>}
        <br />
        <Button
          onClick={getKBA}
          colorScheme="primary"
          // variant="outline"
        >
          Generate Questions
        </Button>
      </>
    );
  }

  return (
    <>
      <Center>
        <Text>Please answer the following {questions.length} questions</Text>
      </Center>

      <Card
        shadow="xl"
        variant="outline"
        // bg='gray.100'
      >
        <CardBody>
          {renderQuestion()}

          <Center>
            {currentQuestion < questions.length - 1 ? (
              <Button
                onClick={handleNext}
                isDisabled={currentAnswer === null}
                colorScheme="primary"
                w="full"
              >
                Next
              </Button>
            ) : currentQuestion === questions.length - 1 ? (
              <Button
                onClick={handleSubmit}
                w="full"
                colorScheme="primary"
                isDisabled={currentAnswer === null}
              >
                Submit
              </Button>
            ) : null}
          </Center>
          <br />

          {currentQuestion > 0 && (
            <Button
              onClick={handlePrevious}
              colorScheme="secondary"
              variant="link"
              w="full"
              fontWeight="normal"
            >
              Previous
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default CreditQuestionnaire;
